import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const StyledPageWrapper = styled.div`
  padding-bottom: 4rem;

  .spinner {
    position: absolute;
    margin-left: 19rem;
    margin-top: -2rem;
  }

  .spinning-form {
    opacity: 0.5;
    pointer-events: none;
  }

  .credit_card {
    max-width: 23.8125rem;
  }

  .apply-blur {
    filter: blur(0.2rem);
  }

  .blue {
    color: ${(p) => p.theme.colors.pacificBlue};
  }

  .error .StripeElement--empty {
    border-color: ${(p) => p.theme.colors.red} !important;
  }
  @media only screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    .spinner {
      margin-left: 8.5rem;
    }
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-bottom: 6rem;

    .credit_card {
      max-width: 34.875rem;
    }
  }
`
export const FormWrapper = styled.div`
  form {
    .ccv-info {
      color: rgba(99, 106, 117, 1);
      font-size: 0.9375rem;
      line-height: 1.5rem;
    }
    .input-feedback {
      padding: 0;
      margin: 0;
    }
    > button {
      margin: 0;
      min-width: 12.5rem;
    }
  }
  @media only screen and (max-width: 62.5rem) {
    .credit_card {
      .input-feedback {
        padding-left: 0.5rem;
      }
    }
  }

  .pacs-link {
    display: block;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.carrot};

    font-size: 1rem;
    line-height: 1.5rem;

    margin: 0.75rem 0 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 0.625rem 0 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin: 0.625rem 0 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      margin: 1.25rem 0 2.5rem;
    }
  }
`

export const StyledLabel = styled.p``

export const StyledFormRow = styled.div`
  margin: 0 0 1.5rem 0;
  .caption {
    margin-bottom: 0.5rem;
  }
  .info-icon {
    color: ${(p) => p.theme.colors.pacificBlue} !important;
    margin-left: 0.5rem;
  }
  .tooltip {
    width: 15.625rem;
  }
  &.free-donation {
    margin-top: 1.5rem;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-auto-columns: 1fr 1.1fr;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    align-items: center;
    p {
      margin: 0;
      align-self: center;
    }
    p.caption {
      align-self: start;
    }
    input {
      width: 10rem;
    }

    .currency-icon input {
      padding-left: 1.5rem;
    }
    .pac-earn {
      color: ${(p) => p.theme.colors.carrot};
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      grid-column-gap: 1rem;
    }

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      grid-auto-columns: 20rem auto;
      margin-top: 2.5rem;
      input {
        width: 18rem;
      }
    }
  }

  &.credit_card {
    & > div {
      box-sizing: border-box;
      height: 3rem;
      border: 0.0625rem solid
        ${(p) => p.theme.colors[p.cardError ? "red" : "disabled"]};
      border-radius: 0.25rem;
      background-image: none !important;
      background-color: ${(p) => p.theme.colors.white};
      padding: 1rem;
      margin-bottom: 0.5rem;
      &.active {
        border: 0.0625rem solid ${(p) => p.theme.colors.blue};
      }
    }
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    .title {
      font-size: 1.5rem;
    }
  }

  .comment,
  textarea {
    width: 100%;
    height: 7.5rem;
  }
  .textarea-counter {
    background: ${(p) => p.theme.colors.white};
    padding: 0 0 0 0.5rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .comment,
    textarea {
      height: 7.5rem;
      max-width: initial;
    }
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.5rem;
    .comment,
    textarea {
      height: 9.625rem;
      margin-bottom: 2.875rem;
    }
  }
`
export const StyledPACWrapper = styled.div`
  color: ${(p) => p.theme.colors.carrot};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.625rem;
    color: ${(p) => p.theme.colors.carrot};
  }
  span {
    margin-right: 0.3rem;
  }
`

export const StyledMoney = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  img {
    margin-right: 0.3rem;
  }
`

export const StyledErrorMessage = styled.div`
  padding: 0;
`

export const StyledAgreeTerms = styled.div`
  span,
  a {
    font-size: 1rem !important;
  }
  margin-bottom: 1rem;
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p,
    span,
    a {
      font-size: 1.5rem !important;
    }
  }
`
export const StyledButtonContainer = styled.div`
  > button {
    width: 100%;
    margin: 0;
    border-radius: 0.25rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-bottom: 5rem;
    > button {
      width: 17.5rem;
    }
  }
`
export const StyledUseAnotherCardButton = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.blue};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  text-align: left;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const StyledSignUp = styled.div`
  span {
    color: ${(p) => p.theme?.colors?.blue};
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }

  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`

export const StyledSingleDonation = styled.div``

export const StyledEmailLabel = styled.div`
  all: inherit;
  display: flex;
  align-items: center;

  .info-icon {
    color: ${(p) => p.theme.colors.pacificBlue} !important;
    margin-left: 0.5rem;
    display: none;
  }
  .tooltip {
    width: 15.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .info-icon {
      height: 1rem;
      width: 1rem;
      display: block;
    }

    .tooltip {
      width: max-content;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-transform: none !important;
      letter-spacing: revert !important;
      padding: 0.5rem;
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .info-icon {
      height: 1.25rem;
      width: 1.25rem;
    }
    .tooltip {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 1rem;
    }
  }
`

export const StyledDonationWithMultiplierTextBox = styled.div`
  padding: 1rem;
  background: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 0.25rem;
  font-size: 1rem;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};

  img {
    height: 0.9rem;
    width: 0.9rem;
    margin-right: 0.2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;

    img {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
`

export const Styled3DInfoText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;

  span {
    display: block;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;

    span {
      margin-bottom: 0.75rem;
    }
  }
`
export const StyledYourDiscountTextTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.5rem;
  line-height: 2.125rem;
`

export const StyledYourDiscountTextDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1.125rem;
  line-height: 1.75rem;
  span {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  img {
    margin-right: 0.2rem;
  }
`

export const StyledYourDiscountText = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`
export const StyledCardErrorWrapper = styled.p`
  margin-top: -0.5rem;
`
export const StyledCheckboxLine = styled.div`
  display: flex;
  align-items: center;
`

export const StyledFlexFormRow = styled.div`
  fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 25.8125rem;
  }
  font-size: 1rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    fieldset {
      max-width: 37.875rem;
    }
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .radio-select-item label {
      width: max-content;
    }

    .radio-select-label {
      margin-left: 1rem;
    }

    .radio-select-input {
      span {
        width: 2rem;
        height: 2rem;
        ::after {
          top: 0.2875rem;
          left: 0.2875rem;
          width: 1.35rem;
          height: 1.35rem;
        }
      }
    }
  }
`

export const StyledTitle = styled.p`
  color: ${(p) =>
    p.disabled ? p.theme.colors.pitchBlack : p.theme.colors.blue};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.125rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  line-height: 1.625rem;
  cursor: pointer;
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
  }
`

export const StyledFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 25.8125rem;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  p {
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.colors.white};
    margin: 0;
    padding: 0;
    p {
      margin: 1.2rem 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 37.875rem;
    max-width: 37.875rem;
    p {
      margin: 3rem 0;
    }
  }
`

export const StyledBlueText = styled(Link)`
  color: ${(p) => p.theme.colors.blue};
  font-size: 1rem;
  margin-top: 1rem;
  display: block;
  text-align: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
  }
`

export const StyledMobileGrayBox = styled.div`
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  margin: -1rem -1rem 1rem;
  padding: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.colors.white};
    margin: 0;
    padding: 0;
  }
`
export const StyledFlexBoxRow = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 1.5rem 0;
  &.title {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    p {
      margin: 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2rem 0 1.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 2.5rem 0;
  }
`

export const StyledRegisterBox = styled.div`
  cursor: pointer;
  width: 162px;
  height: 118px;
  border-radius: 4px;
  padding: 0.7rem;
  background: ${(p) =>
    p.active ? p.theme.colors.white : p.theme.colors.whiteSmoke};
  border: ${(p) => (p.active ? "2px solid #e25f4a" : "none")};
  border-radius: 4px;
  .head {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: ${(p) => p.theme.colors.pitchBlack};
    margin-bottom: 0.75rem;
  }
  .caption {
    margin-bottom: 0.25rem;
    font-size: 0.65rem;
  }
  .pac {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.carrot};
    font-size: 1.2rem;
    line-height: 2.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 203px;
    height: 144px;
    padding: 1rem;
    .head {
      margin-bottom: 1rem;
    }
    .caption {
      font-size: 0.75rem;
    }
    .pac {
      font-size: 1.5rem;
      line-height: 2.75rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 298px;
    height: 210px;
    padding: 1.5rem;
    .head {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
    .caption {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    .pac {
      font-size: 2.5rem;
      line-height: 4rem;
    }
  }
`

export const StyledSectionBox = styled.div`
  max-width: 25.8125rem;

  .center {
    text-align: center;
  }

  .rb24 {
    margin: 1.5rem 0 1rem;
  }
  .mt24 {
    margin-top: 1.5rem;
  }
  .mb24 {
    margin-bottom: 1.5rem;
  }
  .my32 {
    margin: 2rem 0 0.75rem;
  }
  .mb16 {
    margin-bottom: 1rem;
  }
  &.mb32 {
    margin-bottom: 2rem;
  }
  &.pb0 {
    padding-bottom: 0rem;
  }
  .pacs-image {
    margin-right: 0.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 0.25rem;
    border: 0.0625rem solid ${(p) => p.theme.colors.concreteGrey};
    padding: 1.5rem 1rem 1.5rem;
    margin-top: 0.75rem;
    .mb32 {
      padding-bottom: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 1.5rem 1.5rem 2rem;
    max-width: 37.875rem;
    font-size: 1.5rem;
  }
`

export const StyledWarningTitle = styled.div`
  color: ${(p) =>
    p.disabled ? p.theme.colors.pitchBlack : p.theme.colors.blue};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1rem;
  line-height: 1.625rem;
  text-align: center;
  margin-bottom: 2rem;

  p:first-of-type {
    font-size: 1.125rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.red};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    p:first-of-type {
      font-size: 2.2rem;
    }
  }
`

export const StyledLoginActions = styled.div`
  button {
    width: 100%;
    margin-top: 2.25rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    button {
      margin: 2rem 0 0.5rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      margin-bottom: 1rem;
      margin-top: 3rem;
    }
  }
`
