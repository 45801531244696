import React, { useEffect, useMemo, useState } from "react"
import { useIntl, navigate, FormattedMessage } from "gatsby-plugin-intl"
import { Money, Tabs, TabList, Tab, TextInput } from "@tmu/components/common"

import {
  ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION,
  DEFAULT_CURRENCY_SYMBOL,
} from "@tmu/apollo/constants"

import {
  StyledMainContainer,
  StyledDonationContainer2,
  StyledDonationDescription,
  StyledDonationAmount,
} from "./index.styles"

const RecurringDonationTerminal = ({
  amountOne,
  descriptionOne,
  amountTwo,
  descriptionTwo,
  amountThree,
  descriptionThree,
  campaign,
  selectedDonationAmount,
  setSelectedDonationAmount,
  error,
  setError,
  otherAmount,
  setOtherAmount,
  tabIndex,
  setTabIndex,
}) => {
  const { formatMessage } = useIntl()

  const tabs = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: "ui::single",
          defaultMessage: "Single",
        }),
        key: "single",
        dataTestId: "single",
      },
      {
        label: formatMessage({
          id: "ui::monthly",
          defaultMessage: "Monthly",
        }),
        key: "monthly",
        dataTestId: "monthly",
      },
      {
        label: formatMessage({
          id: "ui::annually",
          defaultMessage: "Annually",
        }),
        key: "annually",
        dataTestId: "annually",
      },
    ]
  }, [])

  const getMinAmount = () => {
    let min = Math.min(amountOne, amountTwo, amountThree)
    if (min < 5) min = 5
    return tabIndex !== 2
      ? min
      : min * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
  }

  const getDescription = () =>
    selectedDonationAmount === amountOne
      ? descriptionOne
      : selectedDonationAmount === amountTwo
      ? descriptionTwo
      : selectedDonationAmount === amountThree
      ? descriptionThree
      : null

  useEffect(() => {
    setSelectedDonationAmount(
      tabIndex === 2
        ? amountTwo * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
        : amountTwo
    )
  }, [tabIndex, amountTwo])

  useEffect(() => {
    setOtherAmount()
  }, [tabIndex])

  const checkDonateAmount = (floatValue) => {
    const getMinAmount = () => {
      let min = Math.min(amountOne, amountTwo, amountThree)
      if (min < 5) min = 5
      return tabIndex !== 2
        ? min
        : min * ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
    }
    return floatValue > 0 && floatValue < getMinAmount()
      ? setError(
          formatMessage(
            {
              id: "forms::donationMinAmountError",
              defaultMessage:
                "The amount must be at least {selectedDonationAmount}.",
            },
            {
              selectedDonationAmount: getMinAmount(),
            }
          )
        )
      : floatValue > 9999.99
      ? setError(
          formatMessage(
            {
              id: "forms::donationMaxAmountError",
              defaultMessage:
                "The amount must be lower than {selectedDonationAmount}.",
            },
            {
              selectedDonationAmount: 9999.99,
            }
          )
        )
      : error
      ? setError("")
      : null
  }

  return (
    <StyledMainContainer>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index)
          setSelectedDonationAmount(amountTwo)
        }}>
        <TabList>
          {tabs.map(({ key, label, dataTestId }) => (
            <Tab key={key} data-testid={"test-id-" + dataTestId}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <StyledDonationContainer2>
        {Array.of(amountOne, amountTwo, amountThree).map((amount) => {
          return amount ? (
            <div
              key={`amount-${amount}`}
              onClick={() => {
                setSelectedDonationAmount(
                  tabIndex !== 2
                    ? parseFloat(amount)
                    : parseFloat(amount) *
                        ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
                )
                setOtherAmount()
              }}
              className={
                tabIndex !== 2
                  ? parseFloat(selectedDonationAmount) === parseFloat(amount)
                    ? "selected"
                    : null
                  : parseFloat(selectedDonationAmount) ===
                    parseFloat(amount) *
                      ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION
                  ? "selected"
                  : null
              }>
              <Money
                value={
                  tabIndex !== 2
                    ? parseFloat(amount)
                    : parseFloat(amount) *
                        ANNUALLY_MULTIPLIER_FOR_RECURRING_DONATION || ""
                }
              />
            </div>
          ) : null
        })}
      </StyledDonationContainer2>
      {getDescription() && (
        <StyledDonationDescription>
          {getDescription()}
        </StyledDonationDescription>
      )}
      <StyledDonationAmount hasAmount={!!selectedDonationAmount}>
        <TextInput
          newDesign
          className="currency-icon"
          type="currency"
          id="paymentAmount"
          name="paymentAmount"
          defaultValue={otherAmount}
          value={otherAmount}
          placeholder={formatMessage(
            {
              id: "recurring::donation::paymentAmountPlaceHolder",
              defaultMessage: "your amount, min {currencySymbol} {minAmount}",
            },
            {
              currencySymbol: DEFAULT_CURRENCY_SYMBOL,
              minAmount: getMinAmount(),
            }
          )}
          error={error}
          touched={true}
          onChange={(e) => {
            setOtherAmount(e.target.value)
            setSelectedDonationAmount(e.target.value)
          }}
          onValueChange={({ floatValue }) => {
            checkDonateAmount(floatValue)
            setOtherAmount(floatValue)
            setSelectedDonationAmount(floatValue)
          }}
          maxLength={8}
        />
      </StyledDonationAmount>
    </StyledMainContainer>
  )
}

export default RecurringDonationTerminal
