import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledCampaignCreationWrapper = styled.div`
  margin: 4rem auto;
  padding: 0 1rem;

  .top-align {
    vertical-align: top;
    margin-top: 0;
    align-self: flex-start;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0 !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: auto;
    max-width: ${(p) => p.theme.breakpoints.desktop};
  }

  .terms-label {
    padding-left: 2.5rem;
    font-size: 1rem;
    a {
      font-size: 1rem;
    }
  }
  .radio-select-item label {
    margin-bottom: 0.5rem;
    width: auto;
    padding-left: 2.5rem;
  }
  .type-radio {
    display: flex;
    align-items: center;
    > div:first-of-type {
      margin-right: 6.5rem;
    }
    label {
      margin: 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
      > div {
        label {
          margin: 0;
        }
        &:last-of-type {
          label {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .campaign-form > fieldset {
    display: grid;
    grid-template-columns: 15.0625rem auto;
    grid-gap: 2.5rem 1.9375rem;
    margin-right: 0 !important;

    .hide-on-desktop {
      display: none;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
      margin-right: 1rem;
      .hide-on-mobile {
        display: none;
      }
      .hide-on-desktop {
        display: block;
      }
      .subheader {
        margin-top: 2rem;
        margin-bottom: 0.75rem;
      }
      .full-width {
        width: 100%;
      }
    }

    > div {
      @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin-bottom: 1.5rem;
      }
    }

    .react-datepicker-wrapper {
      width: 9rem;
    }

    hr,
    .second-grid {
      grid-column: 1 / span 2;
      @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
    hr {
      margin: 2rem 0;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin: 0;
      }
    }
    .mt-none {
      margin-top: 2rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin-top: 0;
      }
    }

    .mt-small {
      margin-top: 0.5rem;
    }

    .mt-large {
      margin-bottom: 0;
      margin-top: 1.25rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        margin-top: 2rem;
      }
    }
    .input-feedback {
      margin: 0.5rem 0 0;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .field-error {
      height: 0.9rem;
    }

    .react-select__value-container {
      > div {
        width: auto !important;
      }
    }
  }
  .back > svg {
    cursor: pointer;
    grid-area: back;
    jusrify-self: start;
    font-size: 1.5rem !important;
    height: 1.5rem;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold} !important;
    color: ${(p) => p.theme.colors.pacificBlue} !important;
  }
`

export const StyledTitle = styled.h1`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  text-align: center;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledLabel = styled.p`
  width: auto;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  padding: 0;
  align-self: center;

  &.center {
    text-align: center;
  }

  &.top-align {
    align-self: flex-start;
    margin-top: 0.6875rem;
  }

  &.subtext {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
  }

  .confirmation-code-mobile::first-letter {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    color: ${(p) => p.theme.colors.black};
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: capitalize;
  }

  .confirmation-code-mobile {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    color: ${(p) => p.theme.colors.black};
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: lowercase;
  }

  &.inline-header {
    margin: 1rem 0 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .info {
    margin-top: 0;
    font-size: 0.5rem;

    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    line-height: 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .info {
      margin-top: 0.75rem;
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    :not(.subheader):not(.subtext) {
      font-weight: ${(p) => p.theme.fontWeights.black};
      font-size: 0.625rem;
      line-height: 1rem;
      letter-spacing: 0.04rem;
      text-transform: uppercase;
      color: ${(p) => p.theme.colors.disabled};
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
`
export const StyledSupportCharityContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-height: 4rem;
  }
`
export const StyledSupportCharityName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.375rem;
  align-self: center;
  margin-left: 0.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 18rem;
`
export const StyledSupportCharitySelectButton = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pacificBlue};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 0;
  margin-left: auto;
  align-self: center;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`
export const StyledSupportCharitySelectButtonMobile = styled.div`
  &,
  button {
    width: 100%;
  }
`

export const StyledSupportCharityLogo = styled.div``
export const StyledFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
`
export const StyledFlexRowMod = styled(StyledFlexRow)`
  input {
    width: 6.5rem;
  }
  p {
    text-align: right;
    margin-right: 1rem;
    text-align: left;
  }
`

export const StyledInlineRow = styled.div`
  display: grid;
  grid-template-columns: 8rem auto;
  grid-gap: 1.6875rem 0;

  .input-wrapper {
    display: block;
  }

  > div:nth-child(2) {
    justify-self: right;
    grid-template-columns: auto 9rem;
    .input-wrapper {
      margin: 0;
    }

    input {
      font-size: 0.9rem;
      padding: 0 0.3125rem 0 0.75rem;
    }
  }

  > div:nth-child(3) {
    grid-column: 1 / 3;
  }

  .btn-delete-entry {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: right;
    color: ${(p) => p.theme.colors.softGreen};
    width: fit-content;
    margin-top: 0.5rem;
    grid-column: 1 / span 2;
    justify-self: right;
    padding: 0;

    svg {
      width: 0.875rem;
      height: 1.125rem;
    }
  }
  .currency-icon input {
    padding-left: 1.5rem;
  }
  .free {
    margin-top: 1.2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .free {
      margin-top: 2.2rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .btn-delete-entry {
      grid-column: 2;
      margin-top: 0;
    }
  }
`

export const StyledActionButton = styled(Button)`
  min-width: auto;

  height: 2.5rem;
  margin-bottom: 1rem;
  padding: 0.3125rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};

  align-self: self-start;
  justify-self: right;
  .spinner-condensed div {
    box-shadow: 0 0.125rem 0 0 ${(p) => p.theme.colors.white};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-width: 12.5rem;
    padding: unset;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0.625rem;
  }
`
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.75rem;
`

export const StyledDonationAmountField = styled.div`
  .currency-icon:before {
    content: ${(p) => (p.hasAmount ? '"€"' : '""')};
  }
`

export const StyledAddressLinkRow = styled.div`
  position: relative;
  input {
    padding-right: 2rem;
    text-overflow: ellipsis;
    font-size: 0.875rem;
  }
  svg {
    position: absolute;
    top: 0.9rem;
    right: 0.5rem;
    color: ${(p) => p.theme.colors[p.error ? "red" : "blue"]};
    font-size: 1.25rem;
    height: 1.25rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    cursor: pointer;
  }
  &:focus-within svg,
  &:hover svg {
    color: ${(p) => p.theme.colors.blue};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    input {
      font-size: 1rem;
    }
  }
`

export const StyledExplanationText = styled.p`
  position: absolute;
  display: ${(p) => (p.isUnlisted ? "block" : "none")};
  top: 3rem;
  right: 0;
  width: 100%;
  max-width: 23.5rem;
  z-index: 2;
  margin: 0;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 0.25rem 0.75rem ${(p) => p.theme.colors.primaryTextColorLight};
  border-radius: 0.25rem;
  padding: 1rem;
`
export const StyledSelectCharityWarning = styled.div`
  align-self: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.red};
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  text-align: left;
  width: auto;
  &.mobile {
    text-align: center;
    width: 100%;
  }
`
export const StyledDesiredAmountInputWrapper = styled.div`
  width: 100%;
  min-width: 17.9rem;
  max-width: 21.5rem;
  position: relative;
  top: -0.875rem;
  margin-top: 1rem;
  input {
    font-size: 0.9rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    input {
      font-size: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 30rem;
  }
`
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    cursor: pointer;
    grid-area: back;
    jusrify-self: start;
    font-size: 1.5rem !important;
    height: 1.5rem;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
    font-weight: ${(p) => p.theme.fontWeights.bold} !important;
    color: ${(p) => p.theme.colors.pacificBlue} !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.6669rem;
      height: 1.6669rem;
    }
  }
`

export const StyledModalContainer = styled.div`
  line-height: 2;

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.375rem;

      margin: 1rem 0 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      margin: 1.5rem 0 3rem;
    }
  }

  .modal-leave {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.pacificBlue} !important;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    margin: 0 auto;
    width: 50%;
    height: 2.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
      height: 2.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
      height: 3.375rem;
    }
  }
`

export const StyledModalHeader = styled.div`
  position: relative;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }

  button {
    margin: 0;
    padding: 0;
    position: absolute;
    right: -1rem;
    top: -1.5rem;
    svg {
      height: 1.25rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        height: 2rem;
      }
    }
  }
`
export const StyledCharitySpinnerContainer = styled.div`
  & > div {
    padding: 0;
  }
`

export const StyledCampaignEndDateWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 3;
  }
`

export const StyledCenterCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.6875rem;
`

export const StyledRecurringOptionsWrapper = styled.div`
  .caption {
    margin-bottom: 0.5rem;
  }
  textarea {
    height: auto;
  }
  .input-wrapper {
    margin-bottom: 1.5rem;
  }
`
export const StyledToggleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

export const StyledDonationOptionsWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: auto;
  .input-wrapper {
    margin-bottom: 0;
  }
  > div {
    max-width: 15.625rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(3, 10rem);
  }
`
