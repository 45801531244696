import React, { Fragment, useEffect, useState } from "react"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import { useQuery, useLazyQuery } from "@apollo/client"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Checkbox, CustomModal, Spinner } from "@tmu/components/common"
import { HOMEPAGE_ALL_PARTNERS_QUERY } from "@tmu/apollo/storefront/queries/home"
import {
  PACWalletTable,
  WalletBox,
  EmptyTable,
} from "@tmu/components/dashboard/dashboardCommon"
import { useAuth, useDefaultPartner, useDefaultMerchant } from "@tmu/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledPage,
  StyledPageContent,
  StyledPageActions,
  StyledPageTitle,
  StyledPACWalletTableWrapper,
  StyledPACWalletTableExploreHeader,
  StyledPACWalletTableDonateHeader,
  StyledPACWalletTableFilters,
  StyledViewAll,
  StyledSliceHeader,
  StyledTransactionsShow,
  StyledTransactions,
  StyledTransactionsHide,
  StyledFilters,
  StyledMobileFilterMenu,
  StyledMobileHr,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  DONOR_PAC_TRANSACTIONS_QUERY,
  DONOR_WALLET_ALL_OFFERS,
} from "@tmu/apollo/dashboard/queries/user"
import { PARTNER_PAC_TRANSACTIONS_QUERY } from "@tmu/apollo/dashboard/queries/partner"
import { MERCHANT_PAC_TRANSACTIONS_QUERY } from "@tmu/apollo/dashboard/queries/merchant"
import { MERCHANT_OFFER_LISTING_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import { FEATURED_CAMPAIGN_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { useApolloApiClients } from "@tmu/apollo/client"
import { truncateHash } from "@tmu/utils/string"
import { PER_PAGE, TRANSACTION_TYPE_REASON } from "@tmu/apollo/constants"
import { getValueForLocale } from "@tmu/utils/string"
import { SlicePartners, SliceOffers } from "@tmu/components/home"

export const getItemDescription = (
  node,
  truncateLimit,
  locale,
  defaultLocale
) => {
  const [, idStr] =
    node?.donation?.id && node?.donation?.id !== "-"
      ? window?.atob(node?.donation?.id).split(":")
      : [, "-"]

  const [, orderIdStr] =
    node?.order?.id && node?.order?.id !== "-"
      ? window?.atob(node?.order?.id).split(":")
      : [, "-"]

  switch (node.transactionDirection) {
    case "SYSTEM_TO_USER":
      return node?.campaign?.slug ? (
        <Link
          to={`/campaigns/${node?.campaign?.slug}`}
          aria-label={"Campaigns " + node?.campaign?.slug}>
          <strong>
            {truncateHash(
              getValueForLocale(node?.campaign, "name", locale, defaultLocale),
              truncateLimit
            )}
          </strong>
        </Link>
      ) : (
        <FormattedMessage
          id="dashboard::wallet::giftPACs"
          defaultMessage="Gift PACs"
        />
      )
    case "SYSTEM_TO_PARTNER":
      return node?.campaign?.slug ? (
        <Link
          to={`/dashboard/donors/donation-search?code=${idStr}`}
          aria-label={"Donations  " + node?.donation?.title}>
          <strong>
            <FormattedMessage
              id="dashboard::wallet::donation"
              defaultMessage="Donation"
            />
          </strong>
        </Link>
      ) : (
        <FormattedMessage
          id="dashboard::wallet::giftPACs"
          defaultMessage="Gift PACs"
        />
      )
    case "SYSTEM_TO_MERCHANT":
      return (
        <div
          data-testid="text-pac-store-purchase"
          style={{ display: "inline" }}>
          <span>
            <FormattedMessage
              id="dashboard::wallet::madePurchase"
              defaultMessage="PAC Store purchase"
            />
          </span>
          {/*The code has not been deleted with reference to TMU01-8849,
            but has been commented in case it is reused in the future.
            <a href={`/${locale}/dashboard/donors/orders/${orderIdStr}`}
            aria-label="PAC Store purchase">
            <FormattedMessage
              id="dashboard::wallet::madePurchase"
              defaultMessage="PAC Store purchase"
            />
          </a>*/}
        </div>
      )
    case "USER_TO_MERCHANT":
      return (
        <div
          data-testid="text-pac-store-purchase"
          style={{ display: "inline" }}>
          <span>
            <FormattedMessage
              id="dashboard::wallet::madePurchase"
              defaultMessage="PAC Store purchase"
            />
          </span>
          {/*The code has not been deleted with reference to TMU01-8849,
            but has been commented in case it is reused in the future.
            <a href={`/${locale}/dashboard/donors/orders/${orderIdStr}`}
            aria-label="PAC Store purchase">
            <FormattedMessage
              id="dashboard::wallet::madePurchase"
              defaultMessage="PAC Store purchase"
            />
          </a>*/}
        </div>
      )

    case "USER_TO_SYSTEM":
      return (
        <FormattedMessage
          id="dashboard::wallet::balanceRevision"
          defaultMessage="Balance revision"
        />
      )

    default:
      return (
        <FormattedMessage
          id="dashboard::wallet::other"
          defaultMessage="Other"
        />
      )
  }
}

const getPacTransactionsQuery = (dashboardType) => {
  switch (dashboardType) {
    case "donors":
      return DONOR_PAC_TRANSACTIONS_QUERY
    case "partners":
      return PARTNER_PAC_TRANSACTIONS_QUERY
    case "merchants":
      return MERCHANT_PAC_TRANSACTIONS_QUERY
    default:
      throw new Error("Unknown dashboard type")
  }
}

export default ({ dashboardType }) => {
  const { isLoading, isAuthenticated } = useAuth()
  const { storefrontClient, getDashboardClient } = useApolloApiClients()
  const { user } = useAuth()
  const { defaultPartner } = useDefaultPartner({
    skip: isLoading || !isAuthenticated || !user?.isPartner,
  })
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { defaultMerchant } = useDefaultMerchant({
    skip: isLoading || !isAuthenticated || !user?.isMerchant,
  })

  const [first, setFirst] = useState(3)
  const [pageInfo, setPageInfo] = useState()
  const [walletListItems, setWalletListItems] = useState([])
  const [isShortList, setIsShortList] = useState(true)
  const [isDonationsFilter, setDonationsFilter] = useState(true)
  const [isPurchasesFilter, setPurchasesFilter] = useState(true)
  const [isRewardsFilter, setRewardsFilter] = useState(false)
  const [isTransactionsShow, setTransactionsShow] = useState(false)
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)

  const { loading: charityLoading, data: charityData } = useQuery(
    HOMEPAGE_ALL_PARTNERS_QUERY({ locale }),
    {
      variables: {
        first: PER_PAGE,
        isPublic: true,
      },
    }
  )

  const { loading: offersLoading, data: offersData } = useQuery(
    DONOR_WALLET_ALL_OFFERS
  )

  const { isTablet } = getAllScreenTypes()

  const getVariables = () => {
    return dashboardType === "donors"
      ? {
          first: 4,
          reason_In: [
            isDonationsFilter ? TRANSACTION_TYPE_REASON.DONATION : null,
            isPurchasesFilter ? TRANSACTION_TYPE_REASON.PURCHASE : null,
            isPurchasesFilter ? TRANSACTION_TYPE_REASON.OFFLINE_PURCHASE : null,
            isRewardsFilter ? TRANSACTION_TYPE_REASON.SIGNUP_GIFT : null,
            isRewardsFilter ? TRANSACTION_TYPE_REASON.REFERRAL_GIFT : null,
            isRewardsFilter ? TRANSACTION_TYPE_REASON.CAMPAIGN_CONDITION : null,
            isRewardsFilter ? TRANSACTION_TYPE_REASON.PARTNER_CONDITION : null,
          ].filter((item) => item),
        }
      : {
          first: 3,
        }
  }

  const [callTransactions, { data, loading, fetchMore }] = useLazyQuery(
    getPacTransactionsQuery(dashboardType),
    {
      variables: getVariables(),
      client: getDashboardClient(dashboardType),
      fetchPolicy: "network-only",
    }
  )

  useEffect(() => {
    if (!isFiltersModalOpen) {
      callTransactions()
    }
  }, [
    isDonationsFilter,
    isPurchasesFilter,
    isRewardsFilter,
    isFiltersModalOpen,
  ])

  const [callFeaturedCampaigns, featuredCampaigns] = useLazyQuery(
    FEATURED_CAMPAIGN_QUERY,
    {
      variables: {
        first,
      },
      client: storefrontClient,
    }
  )

  useEffect(() => {
    if (!data) return

    setPageInfo(data?.allPacTransactions?.pageInfo)
    setWalletListItems(
      data?.allPacTransactions?.edges.map(({ node }) => ({
        ...node,
        description: getItemDescription(node, 42, locale, defaultLocale),
      })) ?? []
    )
  }, [data])

  useEffect(() => {
    if (isAuthenticated && !featuredCampaigns?.called) {
      callFeaturedCampaigns()
    }
  }, [isLoading, isAuthenticated, featuredCampaigns])

  const productList = useQuery(MERCHANT_OFFER_LISTING_QUERY, {
    variables: {
      first: 4,
    },
    client: storefrontClient,
    skip:
      featuredCampaigns?.loading ||
      isLoading ||
      !isAuthenticated ||
      dashboardType !== "merchants",
  })

  const getPACBalance = (type) => {
    switch (type) {
      case "donors":
        return user?.pacBalance ?? 0
      case "partners":
        return defaultPartner?.pacBalance ?? 0
      case "merchants":
        return defaultMerchant?.pacBalance ?? 0
      default:
        throw new Error("Unknown type")
    }
  }

  const getEmptyMessage = (type) => {
    switch (type) {
      case "donors":
        return (
          <FormattedMessage
            id="dashboard::wallet::donorsEmpty"
            defaultMessage="It seems you haven't earned any PACs, why not make a donation to get some?"
          />
        )
      case "partners":
        return (
          <FormattedMessage
            id="dashboard::wallet::partnersEmpty"
            defaultMessage="There are no transactions"
          />
        )
      case "merchants":
        return (
          <FormattedMessage
            id="dashboard::wallet::merchantsEmpty"
            defaultMessage="There are no transactions"
          />
        )
      default:
        throw new Error("Unknown type")
    }
  }

  const handleLoadMore = () => {
    setIsShortList(false)
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allPacTransactions?.edges
        const pageInfo = fetchMoreResult?.allPacTransactions?.pageInfo
        return newEdges.length
          ? {
              // Put the new allPacTransactions at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              allPacTransactions: {
                __typename: prev.allPacTransactions.__typename,
                edges: [...prev.allPacTransactions?.edges, ...newEdges],
                pageInfo,
              },
              transactionDirections: prev.transactionDirections,
            }
          : prev
      },
    })
  }

  useEffect(() => {
    if (!isTablet) {
      setFirst(1)
    } else if (isTablet) {
      setFirst(2)
      setTransactionsShow(false)
    } else {
      setFirst(3)
    }
  }, [isTablet])

  const mobileFiltersContent = (
    <StyledMobileFilterMenu>
      <div className="caption">
        <FormattedMessage
          id="dashboard::wallet::transactionType"
          defaultMessage="Transaction Type"
        />
      </div>
      <StyledMobileHr />
      <div className="mobile-filters">
        <Checkbox
          className="filter-checkbox"
          label={
            <FormattedMessage
              id="dashboard::wallet::donations"
              defaultMessage="Donations"
            />
          }
          isChecked={isDonationsFilter}
          onChange={() => {
            setDonationsFilter(!isDonationsFilter)
          }}
        />
        <StyledMobileHr />
        <Checkbox
          className="filter-checkbox"
          label={
            <FormattedMessage
              id="dashboard::wallet::purchases"
              defaultMessage="Purchases"
            />
          }
          isChecked={isPurchasesFilter}
          onChange={() => {
            setPurchasesFilter(!isPurchasesFilter)
          }}
        />
        <StyledMobileHr />
        {/* <Checkbox
          className="filter-checkbox"
          label={
            <FormattedMessage
              id="dashboard::wallet::rewards"
              defaultMessage="Rewards"
            />
          }
          isChecked={isRewardsFilter}
          onChange={() => {
            setRewardsFilter(!isRewardsFilter)
          }}
        /> */}
      </div>
    </StyledMobileFilterMenu>
  )

  return (
    <StyledPage>
      {!isTransactionsShow ? (
        <>
          <StyledPageTitle>
            <FormattedMessage
              id="dashboard::nav::wallet"
              defaultMessage="PAC Wallet"
              tagName="h1"
            />
            <WalletBox amount={getPACBalance(dashboardType)} />
          </StyledPageTitle>
          <StyledPACWalletTableExploreHeader>
            <FormattedMessage
              id="dashboard::wallet::title1"
              defaultMessage="<span>Explore shops</span>, to spend your PACs."
              values={{
                span: (...chunks) => (
                  <a href="/offers/?offer=true&store=true">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </a>
                ),
              }}>
              {(...chunks) => (
                <p>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </p>
              )}
            </FormattedMessage>
          </StyledPACWalletTableExploreHeader>
          <StyledPACWalletTableDonateHeader>
            <FormattedMessage
              id="dashboard::wallet::title2"
              defaultMessage="<span>Donate</span>, to get more PACs."
              values={{
                span: (...chunks) => (
                  <a href="/campaigns/?campaign=true&charity=true&event=true">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </a>
                ),
              }}>
              {(...chunks) => (
                <p>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </p>
              )}
            </FormattedMessage>
          </StyledPACWalletTableDonateHeader>
        </>
      ) : (
        <StyledTransactionsShow>
          <StyledTransactions>
            <StyledTransactionsHide
              onClick={() => {
                setTransactionsShow(false)
                setWalletListItems(walletListItems.slice(0, 3))
              }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </StyledTransactionsHide>
            <FormattedMessage
              id="dashboard::ngo::tableHeader::transactions"
              defaultMessage="Transactions"
              tagName="h4"
            />
          </StyledTransactions>
          {!isTablet && (
            <StyledFilters>
              <Button
                className="filter-button"
                variant="link"
                text={
                  <FormattedMessage
                    id="filter::filters"
                    defaultMessage="Filters"
                  />
                }
                onClick={() => setIsFiltersModalOpen(true)}
              />
            </StyledFilters>
          )}
        </StyledTransactionsShow>
      )}
      {isTablet && (
        <StyledPACWalletTableFilters>
          <div>
            <Checkbox
              className="filter-checkbox"
              label={
                <FormattedMessage
                  id="dashboard::wallet::donations"
                  defaultMessage="Donations"
                />
              }
              isChecked={isDonationsFilter}
              onChange={() => {
                setIsShortList(true)
                setDonationsFilter(!isDonationsFilter)
              }}
            />
            <Checkbox
              className="filter-checkbox"
              label={
                <FormattedMessage
                  id="dashboard::wallet::purchases"
                  defaultMessage="Purchases"
                />
              }
              isChecked={isPurchasesFilter}
              onChange={() => {
                setIsShortList(true)
                setPurchasesFilter(!isPurchasesFilter)
              }}
            />
            {/* <Checkbox
              className="filter-checkbox"
              label={
                <FormattedMessage
                  id="dashboard::wallet::rewards"
                  defaultMessage="Rewards"
                />
              }
              isChecked={isRewardsFilter}
              onChange={() => {
                setIsShortList(true)
                setRewardsFilter(!isRewardsFilter)
              }}
            /> */}
          </div>
          {isShortList && data?.allPacTransactions?.edges?.length > 3 && (
            <Button
              className="filter-button"
              variant="link"
              text={
                <FormattedMessage id="ui::viewAll" defaultMessage="View all" />
              }
              onClick={handleLoadMore}
            />
          )}
        </StyledPACWalletTableFilters>
      )}
      <StyledPageContent data-testid="pac-wallet-page-content">
        {!data || loading ? (
          <Spinner condensed />
        ) : walletListItems.length > 0 ? (
          <StyledPACWalletTableWrapper>
            <PACWalletTable
              items={
                isShortList ? walletListItems.slice(0, 3) : walletListItems
              }
            />
            {!isShortList &&
              (!isTablet ? isTransactionsShow : !isTransactionsShow) &&
              pageInfo?.hasNextPage && (
                <StyledPageActions align="center">
                  <Button
                    data-testid="button-view-more"
                    variant="text"
                    label="View More"
                    onClick={handleLoadMore}>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage
                      id="ui::viewMore"
                      defaultMessage="View More"
                    />
                  </Button>
                </StyledPageActions>
              )}
          </StyledPACWalletTableWrapper>
        ) : (
          <EmptyTable emptyMessage={getEmptyMessage(dashboardType)} />
        )}
        {!isTablet && !isTransactionsShow && (
          <StyledViewAll>
            <Button
              className="filter-button"
              variant="link"
              text={
                <FormattedMessage id="ui::viewAll" defaultMessage="View all" />
              }
              onClick={() => {
                setTransactionsShow(true)
                handleLoadMore()
              }}
            />
          </StyledViewAll>
        )}
      </StyledPageContent>
      {!isTransactionsShow && charityData ? (
        <SlicePartners
          header={
            <StyledSliceHeader>
              <h3>
                <FormattedMessage
                  id="dashboard::wallet::earnPacs"
                  defaultMessage="Earn PACs"
                />
              </h3>

              <Button
                className="filter-button"
                variant="link"
                text={
                  <FormattedMessage
                    id="ui::viewAll"
                    defaultMessage="View all"
                  />
                }
                onClick={() => navigate("/campaigns?charity=true")}
              />
            </StyledSliceHeader>
          }
          charities={charityData?.allPartners?.edges ?? []}
        />
      ) : null}
      {!isTransactionsShow && offersData ? (
        <SliceOffers
          offers={offersData?.allOffers?.edges}
          headline={
            <StyledSliceHeader carrot>
              <h3>
                <FormattedMessage
                  id="dashboard::wallet::offersForYou"
                  defaultMessage="PAC Offers for you"
                />
              </h3>
              <Button
                variant="link"
                className="filter-button"
                text={
                  <FormattedMessage
                    id="ui::viewAll"
                    defaultMessage="View all"
                  />
                }
                onClick={() => navigate(`/offers?offer=true`)}
              />
            </StyledSliceHeader>
          }
        />
      ) : null}
      {isFiltersModalOpen ? (
        <CustomModal
          isModalOpen={isFiltersModalOpen}
          style={{ textAlign: "left" }}
          modalActionsStyle={{
            position: "absolute",
            bottom: "1rem",
            width: "calc(100vw - 3rem)",
          }}
          children={mobileFiltersContent}
          header={
            !isTablet && (
              <FormattedMessage id="filter::filters" defaultMessage="Filters" />
            )
          }
          isCloseIcon={true}
          cancelButtonAction={() => {
            setIsFiltersModalOpen(false)
          }}
          confirmButtonText={formatMessage({
            id: "dashboard::wallet::applyFilters",
            defaultMessage: "Apply Filters",
          })}
          confirmButtonAction={() => {
            callTransactions()
            setIsFiltersModalOpen(false)
          }}
          isMobile={true}
          isFull={true}
        />
      ) : null}
    </StyledPage>
  )
}
