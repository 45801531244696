export const getEmailFromToken = (token) => {
  try {
    // Split the token into parts
    const parts = token.split(":")
    if (parts.length !== 3) {
      return null
    }

    // Decode the payload (second part)
    const payload = JSON.parse(atob(parts[0]))

    // Return the email from the payload
    return payload.email
  } catch (error) {
    console.error("Error decoding token:", error)
    return null
  }
}
