import styled from "@emotion/styled"

export const StyledVoucherCodeButton = styled.div`
  text-align: right;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.carrot};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  cursor: pointer;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
  }

  &.blue-text {
    color: ${(p) => p.theme.colors.pacificBlue};
  }
`

export const StyledWrapper = styled.div`
  .tooltip {
    max-width: 15rem;
  }
`
