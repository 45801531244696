import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  CustomModal,
  Date,
  Logo,
  Money,
  SaleDetail,
  Spinner,
  Table,
} from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { StyledPageActions } from "@tmu/src/global/page-addons/dashboard.styles"
import theme from "@tmu/src/global/theme"
import { useDefaultMerchant, useMerchants } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"
import {
  StyledPacContainer,
  StyledPageWrapper,
  StyledTable,
} from "./index.styles"

const SalesTable = ({ searchText, filters }) => {
  const { formatMessage } = useIntl()
  const [detailStatus, setDetailStatus] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const {
    callMerchantOrders,
    callMerchantOrdersRefetch,
    sales,
    pageInfo,
    loading,
    fetchMore,
  } = useMerchants()

  const { isMerchantInternal } = useDefaultMerchant()

  const { isTablet, isWide } = getAllScreenTypes()
  var orderCallInterval = null

  useEffect(() => {
    callMerchantOrders({ variables: { search: searchText, ...filters } }).then(
      (r) => {
        setFirstLoad(true)
      }
    )
  }, [])

  useEffect(() => {
    clearTimeout(orderCallInterval)
    orderCallInterval = setTimeout(
      () => callMerchantOrdersRefetch({ search: searchText, ...filters }),
      1000
    )
  }, [searchText])

  useEffect(() => {
    if (Object.keys(filters)?.length) {
      callMerchantOrdersRefetch({ search: searchText, ...filters })
    }
  }, [filters])

  const data = !sales
    ? []
    : sales?.map(({ node }) => {
        const firstItem = isMerchantInternal
          ? node?.cartItems?.edges?.[0]?.node
          : null
        return {
          id: node?.id,
          date: node?.created,
          offerName: firstItem?.offer?.name || node?.store?.name,
          donorName:
            node?.user?.displayName === "Non Registered Donor"
              ? node?.intendedUser?.firstName
                ? `${node?.intendedUser?.firstName} ${node?.intendedUser?.lastName}`
                : node?.intendedUser?.email
              : node?.user?.displayName,
          discountAmount: node?.internalOrderValues?.discountAmount ?? 0,
          discountPrice: node?.internalOrderValues?.discountPrice ?? 0,
          pacAmount: node?.pacAmount,
          total: node?.internalOrderValues?.subTotal ?? 0,
          sale: node,
          price:
            node?.internalOrderValues?.subTotal ||
            firstItem?.frozenPrice ||
            node?.frozenSubtotal,
          quantity:
            firstItem?.quantity ||
            node?.cartItems?.edges?.reduce(
              (total, item) => total + (item?.node?.quantity || 0),
              0
            ),
          subRows: isMerchantInternal
            ? null
            : node?.cartItems?.edges?.map((cartItem) => {
                return {
                  offerName: cartItem?.node?.name,
                  quantity: cartItem?.node?.quantity || 0,
                  price: cartItem?.node?.frozenPrice || 0,
                  total: cartItem?.node?.frozenTotal,
                  pacAmount: "-" + (cartItem?.node?.frozenPacAmount || 0),
                }
              }),
        }
      })

  const openDonationDetail = (row) => {
    setSelectedRow(row)
    setDetailStatus(true)
  }

  const closeDonationDetail = () => {
    setSelectedRow(null)
    setDetailStatus(false)
  }

  const columns = [
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          {isTablet ? (
            <FormattedMessage
              id="dashboard::tableHeader::date"
              defaultMessage="Date"
            />
          ) : (
            <FormattedMessage
              id="dashboard::tableHeader::offerAndDate"
              defaultMessage="Offer and Date"
            />
          )}
        </div>
      ),
      Cell: ({ value, row }) => {
        return (
          <div>
            {isTablet ? (
              <p>
                <Date value={value} showYear={true} second="numeric" />
              </p>
            ) : (
              <>
                <p>{row?.values?.offerName}</p>
                <p className="mobile-date">
                  <Date value={value} showYear={true} second="numeric" />
                </p>
              </>
            )}
          </div>
        )
      },
      accessor: "date",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          {isMerchantInternal ? (
            <FormattedMessage
              id="dashboard::tableHeader::offerName"
              defaultMessage="Offer Name"
            />
          ) : (
            <FormattedMessage
              id="dashboard::tableHeader::storeProductName"
              defaultMessage="Store/Product Name"
            />
          )}
        </div>
      ),
      Cell: ({ row, value }) => {
        return (
          <div>
            {value}
            {isMerchantInternal && row?.original?.donorName?.length
              ? " - " + row?.original?.donorName
              : null}
          </div>
        )
      },
      accessor: "offerName",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::quantity"
            defaultMessage="Quantity"
          />
        </div>
      ),
      Cell: ({ row, value }) => {
        return <div className="align-right">{value}</div>
      },
      accessor: "quantity",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::price"
            defaultMessage="Price"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <div className="align-right">{value && <Money value={value || 0} />}</div>
        )
      },
      accessor: "price",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacDonation"
            defaultMessage="Pac/Donation"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <StyledPacContainer className="align-right">
            <div>
              - <Money value={value || 0} currency={false} />
            </div>
            <Logo
              className="pac-icon"
              width={isWide ? 25 : 19}
              height={isWide ? 32 : 24}
              fill={theme?.colors?.carrot}
            />
          </StyledPacContainer>
        )
      },
      accessor: "discountAmount",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::amount"
            defaultMessage="Amount"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <div className="align-right">
            <Money value={value || 0} />
          </div>
        )
      },
      accessor: "discountPrice",
      disableSort: true,
    },
    {
      Header: ({ getToggleAllRowsExpandedProps }) => (
        <span {...getToggleAllRowsExpandedProps?.()}></span>
      ),
      id: "icon",
      Cell: ({ row }) => {
        const icon = row?.isExpanded ? faChevronUp : faChevronDown

        return row?.canExpand ? (
          <div className="align-center chevron">
            <span {...row.getToggleRowExpandedProps({})}>
              <FontAwesomeIcon icon={icon} />
            </span>
          </div>
        ) : (
          <></>
        )
      },
    },
  ]

  if (isMerchantInternal) columns.pop()

  const hiddenColumns = isTablet
    ? []
    : ["offerName", "quantity", "price", "icon"]

  const handleRowClick = (row, index) => {
    openDonationDetail(row)
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)

    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      case "amount":
        sortField = "amount"
        break
      case "pacs":
        sortField = "earnedPac"
        break

      default:
        sortField = null
        break
    }
    return sortField ? direction + sortField : null
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
        orderBy: getSortValue(sortInfo),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allOrders?.edges
        const pageInfo = fetchMoreResult?.allOrders?.pageInfo

        const newResult = newEdges?.length
          ? {
              ...prev,
              allOrders: {
                ...prev.allOrders,
                edges: [...prev.allOrders?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev

        return newResult
      },
    })
  }

  return !firstLoad || loading ? (
    <Spinner condensed />
  ) : sales?.length > 0 ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={data}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          initialSort={sortInfo}
          handleSort={handleSort}
          expandClassName="expanded-row"
          expandingClassName="expanding-row"
          options={{ expand: true, sort: true }}
        />
      </StyledTable>
      <CustomModal
        isModalOpen={!isTablet && selectedRow && detailStatus}
        cancelButtonAction={closeDonationDetail}
        children={
          <SaleDetail
            order={selectedRow?.original?.sale}
            rowData={selectedRow}
          />
        }
        isMobile={!isTablet}
        isCloseIcon={true}
        style={{
          overflow: "hidden",
        }}
      />
      {pageInfo?.hasNextPage && (
        <StyledPageActions align="center" data-testid="view-more">
          <Button
            data-testid="button-view-more"
            label="View More"
            variant="text"
            onClick={handleLoadMore}>
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="ui::viewMore" defaultMessage="View More" />
          </Button>
        </StyledPageActions>
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::sales::empty"
          defaultMessage="It seems there are no sales yet"
        />
      }
    />
  )
}

export default SalesTable
