import styled from "@emotion/styled"
export const StyledTopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-title {
    margin: 0;
  }
`

export const StyledMergedReceiptsButton = styled.div`
  && {
    // margin-top: 1rem !important;
  }
  button {
    width: 100%;
    font-size: o.75rem !important;
    padding: 0 !important;
    border: 1px solid #000000;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    button {
      font-size: 1rem !important;
    }
  }
`
