import {
  API_PERMISSIONS,
  CAMPAIGNS_TABS_MENU_SHORT,
} from "@tmu/apollo/constants"
import {
  Date as CustomDate,
  CustomModal,
  Spinner,
  Table,
  TooltipSelectMenu,
  Tabs,
  TabList,
  Tab,
  NewCard,
} from "@tmu/components/common"
import { VISIBILITY_STATUS, REVIEW_STATUS } from "@tmu/apollo/constants"

import Money from "@tmu/components/common/Money"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useAuth, useDefaultMerchant, useToast, useOffers } from "@tmu/hooks"
import { HomePageContext } from "@tmu/src/context/homePageContext"
import { format } from "date-fns"
import { differenceInHours } from "@tmu/utils/date"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { CARDS_PER_PAGE, LIST_TYPES } from "@tmu/apollo/constants"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  StyledSimpleTextContent,
  StyledUsedCardTitle,
} from "../../dashboardPages/Profile/index.styles"
import {
  StyledLeftStockItem,
  StyledPageWrapper,
  StyledEventNameWrapper,
  StyledTable,
  StyledTabs,
  StyledStatusWrapper,
} from "./index.styles"
import { useApolloApiClients } from "@tmu/apollo/client"

const getSortValue = (sortBy) => {
  const sortData = sortBy?.at(0)
  const direction = sortData?.desc === true ? "-" : ""
  let sortField = null

  switch (sortData?.id) {
    case "date":
      sortField = "created"
      break
    default:
      sortField = sortData?.id
      break
  }
  return sortField ? direction + sortField : null
}
const stableDate = new Date().toISOString().split("T")[0] + "T00:00:00.000Z"

const ServiceEventsTable = ({ onRowSelect, dashboardType, searchText }) => {
  const { storefrontClient, getDashboardClient } = useApolloApiClients()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isLoading, user, apiPermissions } = useAuth()
  const [tabIndex, setTabIndex] = useState(0)
  const { defaultMerchant, isMerchantCorporate, isMerchantOffline } =
    useDefaultMerchant()
  const [firstLoad, setFirstLoad] = useState(false)
  const {
    callMerchantOffers,
    merchantService,
    partialUpdateOffer,
    deleteOffer,
  } = useOffers()
  const { data, refetch, loading, fetchMore } = merchantService
  const pageInfo = data?.pageInfo
  const offers = data?.edges?.map((item) => item?.node)
  const { isTablet, isWide, isDesktop } = getAllScreenTypes()
  const [pageSize, setPageSize] = useState(10)
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)
  const [offset, setOffset] = useState(0)
  const tabMenu = CAMPAIGNS_TABS_MENU_SHORT(formatMessage)

  const { success, error, info } = useToast()
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])

  const selectMenuRef = useRef()
  const [menuStatus, setMenuStatus] = useState({})
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEndModalOpen, setEndModalOpen] = useState(false)

  const variables = {
    first: pageSize,
    offerType_In: ["INTERNAL"],
    orderBy: getSortValue(sortInfo),
    isEventOffer: true,
    search: searchText?.trim(),
    offset: offset * pageSize,
    status_In:
      tabIndex === 0
        ? ["APPROVED", "WAITING_FOR_APPROVAL", "IN_PROGRESS"]
        : ["ENDED", "APPROVED", "WAITING_FOR_APPROVAL", "IN_PROGRESS"],
  }
  const handleSort = (sortBy) => {
    setSortInfo(sortBy)
    callMerchantOffers({
      variables: {
        ...variables,
        orderBy: getSortValue(sortBy),
      },
      onCompleted: () => setFirstLoad(true),
      fetchPolicy: "network-only",
    })
  }

  useEffect(() => {
    callMerchantOffers({
      variables,
      onCompleted: () => setFirstLoad(true),
      fetchPolicy: "network-only",
    })
  }, [sortInfo?.id, sortInfo?.desc, tabIndex, searchText, pageSize, offset])

  const updateOffer = (variables) => {
    setGlobalSpinnerStatus(true)
    partialUpdateOffer({
      variables: {
        input: {
          ...variables,
        },
      },
    })
      .then((result) => {
        if (result?.errors?.length) {
          result?.errors?.forEach((err) => error(err?.message))
          return
        }

        refetch({ search: searchText })
      })
      .catch((err) => {
        error(err?.message)
      })
      .finally(() => setGlobalSpinnerStatus(false))
  }

  const tabs = tabMenu

  const merchantUsers = user?.merchantUsers?.edges

  const activeMerchantTemp =
    merchantUsers?.length === 1
      ? merchantUsers?.[0]
      : merchantUsers?.find(
          (merchant) =>
            merchant?.node?.store?.id === user?.activeStore?.id ||
            merchant?.node?.store?.id === defaultMerchant?.id
        )

  const permission = (type) =>
    apiPermissions?.includes(type) ||
    activeMerchantTemp?.node?.finalPermissions?.includes(type)

  const tableData = !offers
    ? []
    : offers
        ?.filter((item) => !item?.isDefaultOffer)
        ?.map((item) => {
          const variations = item?.productVariation
          const priceArr = variations
            ?.filter((item) => !isNaN(item?.price))
            ?.map((item) => Number(item?.price))

          const priceText = Math.min(...priceArr)

          return {
            id: item?.id,
            name: item?.name,
            eventStartsAt: item?.eventStartsAt,
            startDate: item?.startDate,
            endDate: item?.endDate,
            visibilityStatus: item?.visibilityStatus,
            maxPacDiscount: item?.maxPacDiscount,
            minPrice: priceText ?? item?.minPrice,
            slug: item.slug,
            productVariation: item?.productVariation,
            service: item,
            revenue: item?.revenue,
            status:
              item.visibilityStatus === VISIBILITY_STATUS.ARCHIVED ||
              (item.endDate &&
                differenceInHours(new Date(item.endDate), new Date()) <= 0)
                ? formatMessage({
                    id: `visibility::type::ENDED`,
                    defaultMessage: "ENDED",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.LISTED
                ? formatMessage({
                    id: `visibility::type::PUBLIC`,
                    defaultMessage: "PUBLIC",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.UNLISTED
                ? formatMessage({
                    id: `visibility::type::PRIVATE`,
                    defaultMessage: "PRIVATE",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.UPCOMING
                ? formatMessage({
                    id: `visibility::type::UPCOMING`,
                    defaultMessage: "UPCOMING",
                  })
                : "-",
          }
        })

  const handleNavigateToEventDetail = (id) => {
    navigate(`/dashboard/merchants/events/event-offers/detail/${id}`)
  }

  const columns = [
    {
      Header: () => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::event"
            defaultMessage="Event"
          />
        </div>
      ),
      Cell: (props) => (
        <StyledEventNameWrapper
          carrot={dashboardType === "merchants"}
          onClick={() => handleNavigateToEventDetail(props?.row?.original?.id)}>
          {props?.value}
        </StyledEventNameWrapper>
      ),
      accessor: "name",
      disableSort: true,
      headerStyle: {
        maxWidth: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        minWidth: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        width: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        pointerEvents: "none",
      },
    },
    {
      Header: () => (
        <div className="header-text header-icon">
          <FormattedMessage
            id="dashboard::tableHeader::status"
            defaultMessage="Status"
          />
        </div>
      ),
      Cell: ({ row }) => {
        return (
          <StyledStatusWrapper>
            <FontAwesomeIcon
              icon={
                row.original.visibilityStatus === VISIBILITY_STATUS.UNLISTED
                  ? faEyeSlash
                  : faEye
              }
              className="input-icon"
            />
            {row.original.status}
          </StyledStatusWrapper>
        )
      },
      accessor: "visibilityStatus",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
        minWidth: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
        width: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
      },
    },
    {
      Header: () => (
        <div className="header-text align-left">
          <FormattedMessage
            id="dashboard::tableHeader::eventDate"
            defaultMessage="Event date"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value && new Date(value) instanceof Date
          ? format(new Date(value), "MMM dd, yyyy, HH:mm:ss")
          : null,
      accessor: "eventStartsAt",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        minWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        width: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
      },
    },
    {
      Header: (props) => (
        <div
          className="header-text align-right"
          style={{ width: "max-content" }}>
          <FormattedMessage
            id="dashboard::tableHeader::soldLimit"
            defaultMessage="Sold / limit"
          />
        </div>
      ),
      Cell: (props) => {
        const radioOptions = props?.row?.values?.productVariation
          .map((item) => {
            return {
              id: item?.id,
              pacDiscount: item?.pacDiscount,
              isUnlimitedTickets: item?.isUnlimitedTickets,
              isSoldOut: item?.isSoldOut,
              maxTickets: item?.maxTickets,
              price: Number(item?.price),
              isActive: item?.isActive,
              stockLeft: item?.stockLeft,
              booked: item?.booked,
              used: item?.used,
              revenue: item?.revenue,
            }
          })
          .filter((item) => item?.isActive)

        radioOptions.sort((a, b) => a?.price - b?.price)

        const formattedData = radioOptions?.map((item, index) => {
          if (item?.isUnlimitedTickets) {
            return <span>{item?.booked ?? 0}/∞</span>
          } else {
            return (
              <span
                key={index}
                className={`${!item?.stockLeft ? "red-text" : ""}`}>
                {item?.booked ?? 0}/{item?.maxTickets ?? 0}
              </span>
            )
          }
        })

        const result = formattedData.map((element, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span> | </span>}
            {element}
          </React.Fragment>
        ))

        return <StyledLeftStockItem>{result}</StyledLeftStockItem>
      },
      accessor: "productVariation",
      headerClassName: "align-header-right",
      disableSort: true,
    },
    {
      Header: () => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::publishingDate"
            defaultMessage="Publishing date"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value && new Date(value) instanceof Date ? (
          format(new Date(value), "MMM dd, yyyy, HH:mm:ss")
        ) : (
          <span className="symbol">∞</span>
        ),
      accessor: "startDate",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        minWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        width: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
      },
    },
    {
      Header: () => (
        <div className="header-text header-icon align-center">
          <FormattedMessage
            id="dashboard::tableHeader::revenue"
            defaultMessage="Revenue"
          />
        </div>
      ),
      Cell: ({ value, row }) => {
        return (
          <div className="align-center price-cell">
            <Money value={row?.values?.revenue || 0} />
          </div>
        )
      },
      accessor: "revenue",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
        minWidth: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
        width: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
      },
    },
    {
      Cell: ({ row }) => {
        const { index, original } = row
        const event = original
        const visibilityStatus = event?.visibilityStatus
        const statusCode = event?.statusCode
        return (
          <TooltipSelectMenu
            ref={selectMenuRef}
            menuIsOpen={menuStatus[index]}
            row={row}
            menuWidth={menuWidth}
            tooltipHeight={tooltipHeight}
            options={getMenuOptions(visibilityStatus, statusCode)}
            onMouseLeave={() => {
              const temp = {}
              temp[index] = 0
              setMenuStatus({ ...menuStatus, ...temp })
            }}
            onMouseOver={() => {
              if (menuStatus[index] === 1) {
                return
              }
              const temp = {}
              temp[index] = 1
              Object.keys(menuStatus)?.forEach((ind) => {
                if (ind != index) {
                  temp[ind] = 0
                }
              })
              setMenuStatus({ ...menuStatus, ...temp })
            }}
          />
        )
      },
      id: "edit",
      disableSort: true,
    },
  ]

  const hiddenColumns = isDesktop
    ? []
    : ["eventStartsAt, startsAt", "startDate", "revenue"]

  const handleRowClick = (row, index) => {
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const menuItemCancel = {
    value: "cancel",
    label: formatMessage({
      id: "eventTable::rowMenu::cancelEvent",
      defaultMessage: "Cancel event",
    }),
    action: (row) => {
      setEndModalOpen(true)
      setSelectedRow(row)
      setMenuStatus({})
    },
  }

  const menuItemDelete = {
    value: "delete",
    label: formatMessage({
      id: "offerTable::rowMenu::delete",
      defaultMessage: "Delete",
    }),
    action: (row) => {
      setDeleteModalOpen(true)
      setSelectedRow(row)
      setMenuStatus({})
    },
  }

  const menuItemArchive = {
    value: "archive",
    label: formatMessage({
      id: "offerTable::rowMenu::archive",
      defaultMessage: "Archive",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.ARCHIVED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemUnlist = {
    value: "unlist",
    label: formatMessage({
      id: "offerTable::rowMenu::unlist",
      defaultMessage: "Unlist",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.UNLISTED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemList = {
    value: "list",
    label: formatMessage({
      id: "offerTable::rowMenu::list",
      defaultMessage: "List",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.LISTED,
        id: row?.original?.id,
      })
    },
  }

  const getMenuOptions = (visibilityStatus) => {
    const otherOptions = []

    if (visibilityStatus !== VISIBILITY_STATUS.ARCHIVED) {
      otherOptions.push(menuItemArchive)
    }

    if (visibilityStatus === VISIBILITY_STATUS.LISTED) {
      otherOptions.push(menuItemUnlist)
    } else if (visibilityStatus === VISIBILITY_STATUS.UNLISTED) {
      otherOptions.push(menuItemList)
    }

    otherOptions.push(menuItemDelete)

    const options = [
      {
        value: "edit",
        label: formatMessage({
          id: "serviceTable::rowMenu::edit",
          defaultMessage: "Edit",
        }),
        action: (row) => {
          navigate(
            `/dashboard/merchants/services/edit?id=${row?.original?.id}&event=true`
          )
        },
      },
      ...otherOptions,
      // {
      //   value: "copyWithNewPrice",
      //   label: formatMessage({
      //     id: "serviceTable::rowMenu::copyWithNewPrice",
      //     defaultMessage: "Copy with new price",
      //   }),
      // },
      {
        value: "copyLinkToTheService",
        label: formatMessage({
          id: "serviceTable::rowMenu::copyLinkToTheService",
          defaultMessage: "Copy link to the service",
        }),
        action: (row) => {
          const port = window.location.port
          // const portStr = port?.length > 0 ? `:${port}` : port
          const serviceUrl = `${window.location.origin}/${locale}/services/${row?.original?.slug}`
          navigator.clipboard.writeText(serviceUrl)
          const copiedMessage = formatMessage({
            id: "ui::copiedToClipboard",
            defaultMessage: "Copied to clipboard!",
          })
          success(copiedMessage)
        },
      },
      {
        value: "viewServicePage",
        label: formatMessage({
          id: "serviceTable::rowMenu::viewServicePage",
          defaultMessage: "View service page",
        }),
        action: (row) => {
          navigate(`/services/${row?.original?.slug}`)
        },
      },
    ]

    return options
  }

  const menuWidth = isWide ? 220 : 160
  const tooltipHeight = 190

  const handleConfirmEnd = () => {
    endCampaign({
      variables: { input: { id: selectedRow?.original?.id } },
    })
      .then(({ data, errors }) => {
        if (errors?.length) errors.map((err) => error(err.message))
        if (data.endCampaign?.errors?.length)
          data.endCampaign?.errors.map((err) => error(err.messages?.[0]))
        if (data.endCampaign?.success) {
          success(
            formatMessage({
              id: "dashboard::campaignList::eventEnded",
              defaultMessage: "The event is ended successfully",
            })
          )
        }
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        setGlobalSpinnerStatus(false)
        refetch()
      })
  }

  const handleConfirmDelete = () => {
    deleteOffer({ variables: { id: selectedRow?.original?.id } })
      .then(({ data, errors }) => {
        if (data?.deleteOffer?.success) {
          info(
            formatMessage({
              id: "dashboard::campaignList::eventDeleted",
              defaultMessage: "The event is deleted successfully",
            })
          )
        }
        error(errors?.message ?? errors?.[0]?.message)
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        setGlobalSpinnerStatus(false)
        refetch()
      })
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
    setEndModalOpen(false)
    setSelectedRow(null)
  }

  return (
    <>
      <StyledTabs>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {tabs.map(({ key, label, dataTestId }) => (
              <Tab key={key} data-testid={"test-id-" + dataTestId}>
                {label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </StyledTabs>

      {false ? (
        <Spinner condensed />
      ) : isTablet && tableData ? (
        <StyledPageWrapper>
          <StyledTable>
            <Table
              carrot={dashboardType === "merchants"}
              columns={columns}
              data={tableData}
              hiddenColumns={hiddenColumns}
              headerLineSeperator={true}
              onRowClick={!isTablet ? handleRowClick : null}
              initialSort={sortInfo}
              handleSort={handleSort}
              options={{ sort: true }}
              paginationOptions={{
                pageCount: Math.ceil((data?.totalCount || 0) / pageSize),
                handlePageClick: ({ selected }) => {
                  setOffset(selected)
                },
                handlePageSizeChange: (size) => {
                  setPageSize(size)
                },
                initialPage: offset,
                initialPageSize: pageSize,
                pageSizesLabel:
                  isTablet &&
                  formatMessage({
                    id: "eventTable::pagination::eventsOnPage",
                    defaultMessage: "Events on page",
                  }),
                isTransparent: !isTablet,
                customStyles: { bottom: !isTablet ? "5rem" : "0" },
              }}
              hasPagination={true}
            />
          </StyledTable>

          {isDeleteModalOpen && (
            <CustomModal
              isModalOpen={isDeleteModalOpen}
              style={isTablet && { textAlign: "unset" }}
              children={
                <>
                  <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
                  <Spacer bottom={isWide ? 3.5 : 1.5} />
                </>
              }
              header={
                <StyledUsedCardTitle>
                  <FormattedMessage
                    id="dashboard::events::confirmDeleteEvent"
                    defaultMessage="Are you sure to delete your event?"
                  />
                </StyledUsedCardTitle>
              }
              isCloseIcon={true}
              cancelButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalNo"
                  defaultMessage="No"
                />
              }
              cancelButtonAction={() => {
                handleCancelDelete()
              }}
              confirmButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalYes"
                  defaultMessage="Yes"
                />
              }
              confirmButtonAction={() => {
                handleConfirmDelete()
                handleCancelDelete()
              }}
              modalActionsStyle={{ display: "flex" }}
              isMobile={!isTablet}
              isFull={false}
            />
          )}
          {isEndModalOpen && (
            <CustomModal
              isModalOpen={isEndModalOpen}
              style={isTablet && { textAlign: "unset" }}
              children={
                <>
                  <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
                  <Spacer bottom={isWide ? 3.5 : 1.5} />
                </>
              }
              header={
                <StyledUsedCardTitle>
                  <FormattedMessage
                    id="dashboard::events::confirmCancelEvent"
                    defaultMessage="Are you sure to cancel your event?"
                  />
                </StyledUsedCardTitle>
              }
              isCloseIcon={true}
              cancelButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalNo"
                  defaultMessage="No"
                />
              }
              cancelButtonAction={() => {
                handleCancelDelete()
              }}
              confirmButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalYes"
                  defaultMessage="Yes"
                />
              }
              confirmButtonAction={() => {
                handleConfirmEnd()
                handleCancelDelete()
              }}
              modalActionsStyle={{ display: "flex" }}
              isMobile={!isTablet}
              isFull={false}
            />
          )}
        </StyledPageWrapper>
      ) : offers?.length ? (
        offers?.map((offer) => {
          return (
            <NewCard
              bigTablet
              variant={
                offer?.isDefaultOffer
                  ? "store"
                  : offer?.offerType === OFFER_TYPE.INTERNAL
                  ? "service"
                  : "offer"
              }
              id={offer?.id}
              key={offer?.id}
              imageSrc={offer?.image}
              title={getValueForLocale(offer, "name", locale, defaultLocale)}
              description={getValueForLocale(
                offer,
                "description",
                locale,
                defaultLocale
              )}
              maxPacDiscount={offer?.maxPacDiscount}
              size={!isTablet ? LIST_TYPES.LIST : LIST_TYPES.TILE}
              storeLogo={getValueForLocale(
                defaultMerchant,
                "logo",
                locale,
                defaultLocale
              )}
              storeName={defaultMerchant?.displayName || defaultMerchant?.name}
              isComingSoon={offer?.store?.badge === "COMING_SOON"}
              minPrice={offer?.minPrice}
              maxPrice={offer?.maxPrice}
              cardLink={`/dashboard/merchants/events/event-offers/detail/${offer?.id}`}
            />
          )
        })
      ) : (
        <EmptyTable
          emptyMessage={
            <FormattedMessage
              id="dashboard::partner::events::empty"
              defaultMessage="It seems no event yet"
            />
          }
        />
      )}
    </>
  )
}

export default ServiceEventsTable
