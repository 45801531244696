import React, { useState, useEffect, Fragment } from "react"
import { Formik } from "formik"

import * as Yup from "yup"
import queryString from "query-string"
import { useDebouncedFn } from "beautiful-react-hooks"
import { useToast, useAuth, usePACBalance } from "@tmu/hooks"
import { LazyImage } from "@tmu/components/common"
import { getNavigatorLanguage } from "@tmu/utils/navigator"
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import {
  firstName,
  displayName,
  email,
  password,
  agreeTerms,
  contactCountry,
  accountTypeRequired,
  communicationLanguage,
  campanyName,
  fiscalCode,
  vat,
  phone,
} from "@tmu/utils/validation"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { setCredentials } from "@tmu/utils/auth"
import SEO from "@tmu/components/seo"
import {
  StyledSignUpWrapper,
  SignUpTitle,
  ReferralText,
  StyledSignupImage,
  StyledSubtitle1,
  StyledSubtitle2,
  StyledSignUpSubText,
} from "./index.styles"
import SubForm from "./SubForm"
import { ACCOUNT_TYPE } from "@tmu/src/apollo/constants"

const { CLOUDFLARE_IMAGE_URL } = process.env

const signupImage = CLOUDFLARE_IMAGE_URL + "/static/assets/images/signup.png"

const SignUpForm = ({ location, isSocialRegister }) => {
  const {
    signUp,
    socialRegister,
    callAddReferralMutation,
    callUserProfile,
    user,
    setIsAuthenticated,
  } = useAuth()
  const { callPacBalance } = usePACBalance()
  const { error: errorToast, success: successToast } = useToast()
  const [registerError, setRegisterError] = useState(null)
  const [isCompany, setIsCompany] = useState(false)
  const { formatMessage, locale } = useIntl()
  const [token, setToken] = useState()
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const referralCode = params?.referral
  const userEmail = params?.email || ""
  const next = params?.next?.replace(/\/(en|it|es)/g, "") ?? "/user-space"
  const nextUrl = queryString.stringifyUrl({
    url: next,
    query: params,
  })
  const isRecurringSignup = params.recurring

  const initialValues = {
    email: userEmail,
    confirmEmail: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    isSubscribed: false,
    accountType: "PERSONAL",
    communicationLanguage:
      getNavigatorLanguage()?.toLocaleUpperCase() || locale.toLocaleUpperCase(),
    isTermsAndPolicyAccepted: false,
    taxId: "",
    phoneNumber: "",
  }

  const complementaryInitialValues = {
    accountType: "PERSONAL",
    communicationLanguage: "",
    isTermsAndPolicyAccepted: false,
  }

  const validationSchema = Yup.object().shape({
    firstName: isCompany
      ? campanyName({ formatMessage })
      : firstName({ formatMessage }),
    lastName: isCompany ? null : displayName({ formatMessage }),
    taxId: !isCompany ? fiscalCode({ formatMessage }) : vat({ formatMessage }),
    email: email({ formatMessage }),
    confirmEmail: Yup.string()
      .required(
        formatMessage({
          id: "forms::emailRequiredError",
          defaultMessage: "Enter your email",
        })
      )
      .email(
        formatMessage({
          id: "forms::emailInvalidError",
          defaultMessage: "Enter a correct email",
        })
      )
      .test(
        "email-match",
        formatMessage({
          id: "signup::email::notMatch",
          defaultMessage: "Both emails need to be the same",
        }),
        function (value) {
          return value === this.resolve(Yup.ref("email"))
        }
      ),
    password: password({
      formatMessage,
    }),
    confirmPassword: Yup.string()
      .required(
        formatMessage({
          id: "forms::passwordRequiredError",
          defaultMessage: "A password is required.",
        })
      )
      .test(
        "password-match",
        formatMessage({
          id: "signup::password::notMatch",
          defaultMessage: "Both passwords need to be the same",
        }),
        function (value) {
          return value === this.resolve(Yup.ref("password"))
        }
      ),
    communicationLanguage: communicationLanguage({ formatMessage }),
    accountType: accountTypeRequired({ formatMessage }),
    isTermsAndPolicyAccepted: agreeTerms({ formatMessage }),
    phoneNumber: phone({ formatMessage }),
  })

  const resetErrors = useDebouncedFn(() => {
    setRegisterError(null)
  }, 2000)

  const handleRegisterResponse = async (response, email, referralCode) => {
    if (response?.data?.register?.success) {
      if (referralCode) {
        successToast(
          formatMessage({
            id: "signUp::addReferralSuccess",
            defaultMessage: "Your referral code has been added successfully.",
          })
        )
      }
      setCredentials({
        token: response?.data?.register?.token,
        refreshToken: response?.data?.register?.refreshToken,
      })

      setIsAuthenticated(true)

      await callUserProfile()
      callPacBalance()
    }

    if (response?.data?.register?.errors) {
      Object.entries(response?.data?.register?.errors).map((err) =>
        errorToast(err[1][0].message)
      )
    }

    if (response?.errors) {
      const errorTextId = response?.errors?.[0]?.message?.includes(
        "does not exist"
      )
        ? "signup::referral::doesNotExist"
        : response?.errors?.[0]?.message?.includes("does not match")
        ? "signup::referral::emailNotMatch"
        : response?.errors?.[0]?.message?.includes("already used")
        ? "signup::referral::alreadyUsed"
        : "signup::auth::errorOccured"
      response?.errors[0]?.message
        ? errorToast(
            formatMessage({
              id: errorTextId,
              defaultMessage: "An error occurred",
            })
          )
        : Object.entries(response?.errors).map((err) =>
            errorToast(err[1][0].message)
          )
    }
  }
  const handleSubmit = (values, form) => {
    const referralCode = params?.referral || ""
    const country = values?.country?.value || ""

    signUp({
      ...values,
      firstName: values.firstName,
      lastName: isCompany ? "" : values.lastName,
      displayName: values.firstName,
      phoneNumber: values.phoneNumber,
      country,
      password1: values.password,
      password2: values.confirmPassword,
      code: referralCode,
      captcha: "token",
      redirectUrl: "https://trustmeup.com/signin?next=" + nextUrl,
      accountType: isCompany ? ACCOUNT_TYPE.BUSINESS : ACCOUNT_TYPE.PERSONAL,
    })
      .then((response) => {
        handleRegisterResponse(response, values?.email, referralCode)
      })
      .catch((err) => {
        setRegisterError(err?.description)
      })
      .finally(() => {
        form.setSubmitting(false)
        setToken("")
      })
  }

  useEffect(() => {
    return () => resetErrors.flush()
  })

  /* TODO: will add captcha when needed
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
  */

  return (
    <StyledSignUpWrapper>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "signIn::signUp",
          defaultMessage: "Join Us Now",
        })}
        description={formatMessage({
          id: "seo::signIn-signUp::description",
          defaultMessage: "Sign up, start doing good and get rewarded 100%.",
        })}
      />
      <div>
        {isRecurringSignup ? (
          <>
            <SignUpTitle>
              <FormattedMessage
                id="signUp::recurringSignupTitle"
                defaultMessage="Sign up to create a recurring donation"
              />
            </SignUpTitle>
            <StyledSignUpSubText>
              <FormattedMessage
                id="signUp::recurringSignupSubTitle1"
                defaultMessage="You can manage your recurring donation from your profile"
                tagName="p"
              />
              <FormattedMessage
                id="signUp::recurringSignupSubTitle2"
                defaultMessage="For each EUR you donate, you get rewarded with PACs."
              />{" "}
              <a href="/pacs" className="carrot">
                <FormattedMessage
                  id="pacs::hero::title"
                  defaultMessage="What are PACs?"
                />
              </a>
            </StyledSignUpSubText>
          </>
        ) : (
          <>
            <StyledSignupImage>
              <LazyImage
                altName={"signup"}
                src={signupImage}
                width="325"
                height="325"
                fit="cover"
              />
            </StyledSignupImage>
            <StyledSubtitle1>
              <FormattedMessage
                id="signUp::subtitle1"
                defaultMessage="
                  Log in with <strong>Google</strong> or <strong>Facebook</strong> or fill out <strong>the form</strong> to <strong>create</strong> your <strong>account</strong>"
                values={{
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </span>
                  ),
                }}
              />
            </StyledSubtitle1>
            {/* <StyledSubtitle2>
              <FormattedMessage
                id="signUp::subtitle2"
                defaultMessage="You will immediately receive 30 Euro/PAC as a gift to be used as a discount on your purchases"
              />
            </StyledSubtitle2> */}
          </>
        )}
        <>
          {params?.referral && (
            <ReferralText>
              <FormattedMessage
                id="signUp::referral::description"
                defaultMessage="Referral Code: {referralCode}"
                values={{ referralCode }}
              />
            </ReferralText>
          )}
        </>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}>
          {() => {
            return (
              <SubForm
                user={user}
                registerError={registerError}
                token={token}
                setToken={setToken}
                isRecurringSignup={isRecurringSignup}
                isRegister
                setIsCompany={setIsCompany}
                isCompany={isCompany}
              />
            )
          }}
        </Formik>
      </div>
    </StyledSignUpWrapper>
  )
}

export default SignUpForm
