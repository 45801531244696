import React, { Fragment, useEffect } from "react"
import queryString from "query-string"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, FieldArray, useFormikContext } from "formik"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ImageGallery,
  CampaignChooseCause,
} from "@tmu/components/dashboard/dashboardCommon"
import { addDate } from "@tmu/utils/date"
import { capitalize, getValueForLocale } from "@tmu/utils/string"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  TextEditor,
  TextInput,
  Button,
  FieldError,
  RadioSelect,
  DatePicker,
  SeatComponent,
  TermsAndConditions,
  Checkbox,
  Dropdown,
  Spinner,
  LazyImage,
  CharityImageSelector,
  VoucherCodeSelector,
  Money,
  CommonToggle,
} from "@tmu/components/common"
import { useAuth } from "@tmu/hooks"

import {
  REVIEW_STATUS,
  MAX_DAYS_END_EVENT,
  MERCHANT_MAX_DAYS_END_EVENT,
  DEFAULT_MIN_TIER_AMOUNT,
  REQUIRED_FIELD_SYMBOL,
} from "@tmu/apollo/constants"
import {
  StyledLabel,
  StyledFlexRow,
  StyledFlexRowMod,
  StyledInlineRow,
  StyledActionButton,
  StyledButtonContainer,
  StyledDonationAmountField,
  StyledExplanationText,
  StyledAddressLinkRow,
  StyledSupportCharityContainer,
  StyledSupportCharityName,
  StyledSupportCharityLogo,
  StyledSupportCharitySelectButton,
  StyledSelectCharityWarning,
  StyledSupportCharitySelectButtonMobile,
  StyledDesiredAmountInputWrapper,
  StyledCharitySpinnerContainer,
  StyledCampaignEndDateWrapper,
  StyledCenterCheckbox,
  StyledDonationOptionsWrapper,
  StyledRecurringOptionsWrapper,
  StyledToggleWrapper,
} from "./index.styles"
import { compareAsc } from "date-fns"
import { isBrowser } from "@tmu/src/utils/auth"
import {
  API_PERMISSIONS,
  MAX_TIERS,
  ENTRY_DONATION_MAX_TEXT_LENGTH,
} from "@tmu/apollo/constants"

const { CLOUDFLARE_IMAGE_URL } = process.env

export const CampaignCreationForm = ({
  campaign,
  isEventCampaign,
  charityLoading,
  supperterCauseLoading,
  selectedCharity,
  selectedCampaign,
  charityData,
  selectCharityErrorText,
  isUpdate,
  setCharitySelectorModalStatus,
  handleStartDateChange,
  handleDeadlineDateChange,
  eventType,
  setExplanationVisible,
  isExplanationVisible,
  processingImages,
  deleteCampaignImage,
  allCampaignCategories,
  isEnglishSectionOpen,
  handleEnglishSectionChange,
  isItalianSectionOpen,
  handleItalianSectionChange,
  isSpanishSectionOpen,
  handleSpanishSectionChange,
  items,
  setLeaveModalOpen,
  goToCampaignList,
  setStopModalOpen,
  isCampaignBeingCreated,
  supperterCauseData,
  setMinValue1,
  setMinValue2,
  isRecurring,
  setIsRecurring,
}) => {
  const { formatMessage, locale, defaultLocale } = useIntl()
  const searchStr = isBrowser ? window?.location?.search : ""
  const params = queryString.parse(searchStr, { arrayFormat: "comma" })
  const isSupporter = params?.supporter === "true"
  const isMerchantOffline = params?.offline === "true"
  const isMerchantCorporate = params?.corporate === "true"
  const defaultCause = params?.defaultSupporterCause
  const { apiPermissions } = useAuth()
  const isPartner = !isMerchantOffline && !isMerchantCorporate && !isSupporter // TODO: refactor to get active page

  const localeSuffix = capitalize(locale)

  const MAX_DAYS = isMerchantOffline
    ? MERCHANT_MAX_DAYS_END_EVENT
    : MAX_DAYS_END_EVENT

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    setFieldTouched,
    setTouched,
  } = useFormikContext()

  useEffect(() => {
    const supporterCauseIdFromParams =
      supperterCauseData?.allSupporterCampaignCauses?.edges?.find(
        ({ node }) =>
          node?.name?.toLowerCase() === defaultCause?.toLowerCase() ||
          node?.nameEn?.toLowerCase() === defaultCause?.toLowerCase() ||
          node?.nameIt?.toLowerCase() === defaultCause?.toLowerCase() ||
          node?.nameEs?.toLowerCase() === defaultCause?.toLowerCase()
      )?.node?.id
    if (supporterCauseIdFromParams)
      setFieldValue("supporterCause", supporterCauseIdFromParams)
  }, [defaultCause, supperterCauseData])

  const { isTablet, isWide } = getAllScreenTypes()
  const confirmationCodeText = formatMessage({
    id: "dashboard::offerForm::confirmationCode",
    defaultMessage: "Confirmation Code",
  })

  return (
    <Form className="campaign-form">
      <fieldset disabled={campaign?.status === REVIEW_STATUS.ENDED}>
        {!isTablet && (
          <StyledLabel className="top-align subheader">
            <FormattedMessage
              id={`dashboard::campaign::${
                isEventCampaign ? "event" : "collection"
              }Campaign`}
              defaultMessage={`${
                isEventCampaign ? "Event" : "Collection"
              } campaign`}
            />
          </StyledLabel>
        )}
        {(isSupporter || isMerchantCorporate || isMerchantOffline) && (
          <>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::charityToSupport"
                defaultMessage="Charity To Support"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledSupportCharityContainer>
              {charityLoading ? (
                <StyledCharitySpinnerContainer>
                  <Spinner condensed />
                </StyledCharitySpinnerContainer>
              ) : selectedCharity ? (
                <>
                  <StyledSupportCharityLogo>
                    <LazyImage
                      src={getValueForLocale(
                        selectedCharity,
                        "logo",
                        locale,
                        defaultLocale
                      )}
                      height={isWide ? 64 : 48}
                      altName={`card-image`}
                      fit="cover"
                    />
                  </StyledSupportCharityLogo>
                  <StyledSupportCharityName>
                    {getValueForLocale(
                      selectedCampaign ?? selectedCharity,
                      "name",
                      locale,
                      defaultLocale
                    )}
                  </StyledSupportCharityName>
                </>
              ) : (
                <>
                  {isTablet &&
                    !charityLoading &&
                    !selectedCharity &&
                    !charityData?.partner && (
                      <StyledSelectCharityWarning>
                        {selectCharityErrorText}
                      </StyledSelectCharityWarning>
                    )}
                </>
              )}
              {!isUpdate && (
                <>
                  {isTablet ? (
                    <>
                      <StyledSupportCharitySelectButton
                        onClick={() => {
                          setCharitySelectorModalStatus(true)
                        }}>
                        <FormattedMessage
                          id="dashboard::campaign::pickAnother"
                          defaultMessage="Pick another"
                        />
                      </StyledSupportCharitySelectButton>
                    </>
                  ) : (
                    <>
                      <StyledSupportCharitySelectButtonMobile
                        onClick={() => {
                          setCharitySelectorModalStatus(true)
                        }}>
                        <Button color="transparent">
                          <FormattedMessage
                            id="dashboard::campaign::pickAnother"
                            defaultMessage="Pick another"
                          />
                        </Button>
                      </StyledSupportCharitySelectButtonMobile>
                      {!selectedCharity && (
                        <StyledSelectCharityWarning className="mobile">
                          {selectCharityErrorText}
                        </StyledSelectCharityWarning>
                      )}
                    </>
                  )}
                </>
              )}
            </StyledSupportCharityContainer>
            {!isMerchantOffline && !isMerchantCorporate ? (
              <>
                {supperterCauseLoading ? (
                  <StyledCharitySpinnerContainer>
                    <Spinner condensed />
                  </StyledCharitySpinnerContainer>
                ) : (
                  <>
                    <StyledLabel className="top-align">
                      {isEventCampaign ? (
                        <FormattedMessage
                          id="dashboard::campaignChooseCause::title_Event"
                          defaultMessage="Choose your cause"
                        />
                      ) : (
                        <FormattedMessage
                          id="dashboard::campaignChooseCause::title"
                          defaultMessage="Choose your cause"
                        />
                      )}
                      {REQUIRED_FIELD_SYMBOL}
                    </StyledLabel>
                    <CampaignChooseCause
                      isMerchantOffline={isMerchantOffline}
                    />
                  </>
                )}
              </>
            ) : null}
          </>
        )}
        <StyledLabel>
          {isEventCampaign ? (
            <FormattedMessage
              id="dashboard::campaign::nameEvent"
              defaultMessage="Name your event"
            />
          ) : (
            <FormattedMessage
              id="dashboard::campaign::nameCampaign"
              defaultMessage="Name your campaign"
            />
          )}
          {REQUIRED_FIELD_SYMBOL}
        </StyledLabel>
        <TextInput
          newDesign
          data-testid="input-name-campaign"
          id={`name${localeSuffix}`}
          name={`name${localeSuffix}`}
          value={values[`name${localeSuffix}`]}
          onChange={handleChange}
          placeholder={
            isEventCampaign
              ? formatMessage({
                  id: "dashboard::campaign::nameEvent",
                  defaultMessage: "Name your event",
                })
              : formatMessage({
                  id: "dashboard::campaign::nameCampaign",
                  defaultMessage: "Name your campaign",
                })
          }
          onBlur={handleBlur}
          error={errors[`name${localeSuffix}`]}
          touched={touched[`name${localeSuffix}`]}
          autoComplete="off"
        />
        {!isEventCampaign ? (
          <>
            <StyledLabel className="top-align">
              <FormattedMessage
                id="dashboard::campaign::finishDate"
                defaultMessage="Finish date"
              />
              {REQUIRED_FIELD_SYMBOL}
              <p className="info">
                <FormattedMessage
                  id="dashboard::campaign::upto60"
                  defaultMessage="Your campaign can be up to 540 days"
                />
              </p>
            </StyledLabel>
            <StyledCampaignEndDateWrapper>
              <StyledFlexRow>
                <DatePicker
                  minDate={values.startsAt || new Date()}
                  maxDate={
                    isMerchantOffline || isEventCampaign
                      ? addDate(values.startsAt || new Date(), {
                          days: MAX_DAYS,
                        })
                      : ""
                  }
                  dataTestId="campaign-finish-date-picker"
                  selected={values.deadline}
                  onDateChange={(date) => handleDeadlineDateChange(date)}
                  error={errors.deadline}
                  touched={touched.deadline}
                  disabled={isEventCampaign ? false : values.hasNoDeadline}
                />
                <StyledFlexRowMod>
                  <StyledLabel className="top-align">
                    <FormattedMessage
                      id="dashboard::campaign::numberOfDays"
                      defaultMessage="or number of days"
                    />
                  </StyledLabel>
                  <TextInput
                    newDesign
                    data-testid="numberOfDays"
                    id="numberOfDays"
                    type="number"
                    name="numberOfDays"
                    onBlur={handleBlur}
                    disabled={isEventCampaign ? false : values.hasNoDeadline}
                    value={values.numberOfDays}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        numberOfDays:
                          e.target.value > MAX_DAYS ? MAX_DAYS : e.target.value,
                        deadline: addDate(values.startsAt || new Date(), {
                          days:
                            e.target.value > MAX_DAYS
                              ? MAX_DAYS
                              : e.target.value,
                        }),
                      })
                    }}
                    error={errors.numberOfDays}
                    touched={touched.numberOfDays}
                  />
                </StyledFlexRowMod>
              </StyledFlexRow>
              {!isEventCampaign && (
                <Checkbox
                  className="mt-large"
                  label={formatMessage({
                    id: "dashboard::campaign::hasNoDeadline",
                    defaultMessage: "This campaign has no finish date",
                  })}
                  id="hasNoDeadline"
                  isChecked={values.hasNoDeadline}
                  onChange={() => {
                    setValues({
                      ...values,
                      hasNoDeadline: !values.hasNoDeadline,
                      numberOfDays: "",
                      deadline: undefined,
                    })
                  }}
                />
              )}
            </StyledCampaignEndDateWrapper>
          </>
        ) : null}
        {isEventCampaign ? (
          <>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::eventDate"
                defaultMessage="Event date"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledFlexRow>
              <DatePicker
                minDate={
                  compareAsc(values?.startsAt, new Date()) === 1
                    ? values.startsAt
                    : new Date()
                }
                maxDate={
                  addDate(values.startsAt, {
                    days: MAX_DAYS,
                  }) || ""
                }
                dataTestId="campaign-event-date-picker"
                selected={values.eventStartsAt}
                onDateChange={(date) => {
                  setFieldValue("eventStartsAt", date, false)
                }}
                error={errors.eventStartsAt}
                touched={touched.eventStartsAt}
              />
            </StyledFlexRow>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::eventStarts"
                defaultMessage="Event starts"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledFlexRow>
              <DatePicker
                dataTestId="campaign-event-time-picker"
                selected={values.eventStartsAt}
                onDateChange={(date) => {
                  setFieldValue("eventStartsAt", date, false)
                  const startsAt = values?.startsAt
                  const eventStarts = date
                  startsAt?.setHours(eventStarts?.getHours())
                  startsAt?.setMinutes(eventStarts?.getMinutes())
                  startsAt?.setSeconds(eventStarts?.getSeconds())
                  setFieldValue("startsAt", startsAt)
                }}
                showTimeSelect
                showTimeSelectOnly
                error={errors.eventStartsAt}
                touched={touched.eventStartsAt}
              />
            </StyledFlexRow>
            {(isPartner || isSupporter || isMerchantCorporate) && (
              <>
                <StyledLabel className="top-align mt-none subheader">
                  <FormattedMessage
                    id="dashboard::campaign::typeOfEvent"
                    defaultMessage="Type of the event"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </StyledLabel>
                <RadioSelect
                  className="type-radio"
                  items={eventType}
                  defaultValue={values.isVirtualEvent ? "virtual" : "real"}
                  onChange={(e) => {
                    setFieldValue(
                      "isVirtualEvent",
                      e.target.value === "virtual" ? true : false
                    )
                  }}
                />
              </>
            )}
            {values.isVirtualEvent ? (
              <>
                <StyledLabel className="top-align subheader">
                  <FormattedMessage
                    id="dashboard::campaign::broadcastLink"
                    defaultMessage="Broadcast link"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </StyledLabel>
                <TextInput
                  newDesign
                  data-testid="virtualEventLink"
                  id="virtualEventLink"
                  name="virtualEventLink"
                  value={values.virtualEventLink}
                  onChange={(e) => {
                    const lowerCaseLink = e.target.value.toLowerCase()
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: lowerCaseLink,
                      },
                    })
                  }}
                  placeholder={
                    isTablet
                      ? formatMessage({
                          id: "dashboard::campaign::addVirtualEventLink",
                          defaultMessage:
                            "Paste broadcast link to your virtual event",
                        })
                      : formatMessage({
                          id: "dashboard::campaign::addVirtualEventLinkShort",
                          defaultMessage: "Paste link here",
                        })
                  }
                  onBlur={handleBlur}
                  error={errors.virtualEventLink}
                  touched={touched.virtualEventLink}
                />
              </>
            ) : (
              <>
                <StyledLabel className="top-align subheader">
                  <FormattedMessage
                    id="dashboard::campaign::eventAddress"
                    defaultMessage="Address of the event"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </StyledLabel>
                <div>
                  <StyledAddressLinkRow>
                    <TextInput
                      newDesign
                      data-testid="eventAddress"
                      id="eventAddress"
                      name="eventAddress"
                      value={values.eventAddress}
                      onChange={handleChange}
                      placeholder={formatMessage({
                        id: "dashboard::campaign::typeAddress",
                        defaultMessage:
                          "Type an address, starting from the city",
                      })}
                      onBlur={handleBlur}
                      error={errors.eventAddress}
                      touched={touched.eventAddress}
                    />
                  </StyledAddressLinkRow>
                  <StyledAddressLinkRow
                    error={errors.eventMapLink && touched.eventMapLink}>
                    <TextInput
                      newDesign
                      data-testid="addressLink"
                      id="eventMapLink"
                      name="eventMapLink"
                      value={values.eventMapLink}
                      onChange={handleChange}
                      className="mt-large"
                      placeholder={formatMessage({
                        id: "dashboard::campaign::addressLink",
                        defaultMessage: "Copy and paste Google Maps link",
                      })}
                      onBlur={handleBlur}
                      error={errors.eventMapLink}
                      touched={touched.eventMapLink}
                    />
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      onMouseEnter={() => setExplanationVisible(true)}
                      onMouseLeave={() => setExplanationVisible(false)}
                    />
                    <StyledExplanationText isUnlisted={isExplanationVisible}>
                      {formatMessage({
                        id: "dashboard::campaign::addressLinkTip",
                        defaultMessage:
                          "You can find the event venue on Google Maps and get the share link from the share screen. The share link helps people get directions for the location.",
                      })}
                    </StyledExplanationText>
                  </StyledAddressLinkRow>
                </div>
              </>
            )}
          </>
        ) : null}
        {!isMerchantOffline && !isEventCampaign && (
          <>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::recurringDonations"
                defaultMessage="Recurring donations"
              />
            </StyledLabel>

            <div>
              <Checkbox
                label={formatMessage({
                  id: "dashboard::campaign::recurringMessage",
                  defaultMessage:
                    "Let donors adjust recurring donation, monthly or annual",
                })}
                id="recurringIsEnabled"
                isChecked={values.recurringIsEnabled}
                onChange={() => {
                  setIsRecurring(!values.recurringIsEnabled)
                  setValues({
                    ...values,
                    recurringIsEnabled: !values.recurringIsEnabled,
                  })
                }}
              />
            </div>
          </>
        )}
        {values.recurringIsEnabled ? (
          <>
            <StyledLabel className="top-align subheader">
              <FormattedMessage
                id="dashboard::campaign::recurringOptions"
                defaultMessage="Donation Options"
              />
              <StyledLabel className="subtext">
                <FormattedMessage
                  id="dashboard::campaign::recurringOptionsMessage"
                  defaultMessage="Choose donation options for your donor. Set 3 donation options for single and recurring donations to your campaign as well as receive a custom donation amount. Minimum amount of a donation is 5 €"
                />
              </StyledLabel>
            </StyledLabel>

            <StyledRecurringOptionsWrapper>
              <StyledLabel className="inline-header">
                <FormattedMessage
                  id="dashboard::campaign::monthly"
                  defaultMessage="Monthly"
                />
              </StyledLabel>
              <StyledDonationOptionsWrapper>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 1`}
                  </p>
                  <TextInput
                    newDesign
                    type="currency"
                    data-testid={`recurringAmountOne`}
                    id={`recurringAmountOne`}
                    name={`recurringAmountOne`}
                    className="currency-icon"
                    value={values.recurringAmountOne}
                    error={errors?.recurringAmountOne}
                    touched={touched?.recurringAmountOne}
                    maxLength={8}
                    onBlur={() => setFieldTouched(`recurringAmountOne`)}
                    onValueChange={({ value }) => {
                      setFieldValue(
                        `recurringAmountOne`,
                        value ? Number(value) : "",
                        false
                      )
                      setMinValue1(value)
                    }}
                  />
                </div>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 2`}
                  </p>
                  <TextInput
                    newDesign
                    type="currency"
                    data-testid={`recurringAmountTwo`}
                    id={`recurringAmountTwo`}
                    name={`recurringAmountTwo`}
                    className="currency-icon"
                    value={values.recurringAmountTwo}
                    error={errors?.recurringAmountTwo}
                    touched={touched?.recurringAmountTwo}
                    maxLength={8}
                    onBlur={() => setFieldTouched(`recurringAmountTwo`)}
                    onValueChange={({ value }) => {
                      setFieldValue(
                        `recurringAmountTwo`,
                        value ? Number(value) : "",
                        false
                      )
                      setMinValue2(value)
                    }}
                  />
                </div>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 3`}
                  </p>
                  <TextInput
                    newDesign
                    type="currency"
                    data-testid={`recurringAmountThree`}
                    id={`recurringAmountThree`}
                    name={`recurringAmountThree`}
                    className="currency-icon"
                    value={values.recurringAmountThree}
                    error={errors?.recurringAmountThree}
                    touched={touched?.recurringAmountThree}
                    maxLength={8}
                    onBlur={() => setFieldTouched(`recurringAmountThree`)}
                    onValueChange={({ value }) => {
                      setFieldValue(
                        `recurringAmountThree`,
                        value ? Number(value) : "",
                        false
                      )
                    }}
                  />
                </div>
              </StyledDonationOptionsWrapper>
              {/* <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionMonthly`}
                  defaultMessage="Monthly donation description"
                />
                {` 1`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc1`}
                id={`recurringDonationDesc1`}
                name={`recurringDonationDesc1`}
                value={values.recurringDonationDesc1}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc1}
                touched={touched?.recurringDonationDesc1}
              />
              <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionMonthly`}
                  defaultMessage="Monthly donation description"
                />
                {` 2`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc2`}
                id={`recurringDonationDesc2`}
                name={`recurringDonationDesc2`}
                value={values.recurringDonationDesc2}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc2}
                touched={touched?.recurringDonationDesc2}
              />
              <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionMonthly`}
                  defaultMessage="Monthly donation description"
                />
                {` 3`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc3`}
                id={`recurringDonationDesc3`}
                name={`recurringDonationDesc3`}
                value={values.recurringDonationDesc3}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc3}
                touched={touched?.recurringDonationDesc3}
              /> */}

              <StyledLabel className="inline-header">
                <FormattedMessage
                  id="dashboard::campaign::annually"
                  defaultMessage="Annually"
                />
              </StyledLabel>
              <p>
                <FormattedMessage
                  id="dashboard::campaign::annuallyDesc"
                  defaultMessage="Annual donation amounts are based on monthly donation options."
                />
              </p>
              <StyledDonationOptionsWrapper>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 1`}
                  </p>
                  <Money value={(values.recurringAmountOne || 0) * 12} />
                </div>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 2`}
                  </p>
                  <Money value={(values.recurringAmountTwo || 0) * 12} />
                </div>
                <div>
                  <p className="caption">
                    <FormattedMessage
                      id={`dashboard::campaign::recurringDonationOption`}
                      defaultMessage="Donation option"
                    />
                    {` 3`}
                  </p>
                  <Money value={(values.recurringAmountThree || 0) * 12} />
                </div>
              </StyledDonationOptionsWrapper>
              {/* <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionAnnual`}
                  defaultMessage="Annual donation description"
                />
                {` 1`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc1`}
                id={`recurringDonationDesc1`}
                name={`recurringDonationDesc1`}
                value={values.recurringDonationDesc1}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc1}
                touched={touched?.recurringDonationDesc1}
              />
              <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionAnnual`}
                  defaultMessage="Annual donation description"
                />
                {` 2`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc2`}
                id={`recurringDonationDesc2`}
                name={`recurringDonationDesc2`}
                value={values.recurringDonationDesc2}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc2}
                touched={touched?.recurringDonationDesc2}
              />
              <p className="caption">
                <FormattedMessage
                  id={`dashboard::campaign::recurringDonationOptionAnnual`}
                  defaultMessage="Annual donation description"
                />
                {` 3`}
              </p>
              <TextInput
                newDesign
                data-testid={`recurringDonationDesc3`}
                id={`recurringDonationDesc3`}
                name={`recurringDonationDesc3`}
                value={values.recurringDonationDesc3}
                type="textarea"
                maxLength={160}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.recurringDonationDesc3}
                touched={touched?.recurringDonationDesc3}
              /> */}
            </StyledRecurringOptionsWrapper>
          </>
        ) : null}

        {!values.recurringIsEnabled ? (
          <>
            <div>
              <StyledLabel className="top-align">
                {confirmationCodeText}
                {REQUIRED_FIELD_SYMBOL}
                <p className={isTablet ? "info" : "confirmation-code-mobile"}>
                  <FormattedMessage
                    id="dashboard::campaign::confirmationCodeSubDescription"
                    defaultMessage="You can use this code for managing participants of your event or in case you will need to verify donors identities. To verify, insert confirmation codes on Campaigns page (for supporters and merchants) or Donations page (for non-profits)."
                  />
                </p>
              </StyledLabel>
              {campaign?.id && (
                <StyledLabel className="subtext">
                  <FormattedMessage
                    id="dashboard::service::confirmationCodeDescription"
                    defaultMessage="You cannot change the confirmation code status of an existing service. If you wish to make changes, please create a new one instead"
                  />
                </StyledLabel>
              )}
            </div>
            <div disabled={campaign?.id}>
              <VoucherCodeSelector
                disabled={campaign?.id}
                onChange={(val) => {
                  setFieldValue("isVoucher", val, true)
                }}
                isVoucher={values?.isVoucher}
                isCampaign={true}
              />
            </div>
            <StyledLabel className="top-align">
              <FormattedMessage
                id="dashboard::offerForm::reservation"
                defaultMessage="Reservation"
              />
            </StyledLabel>
            <StyledCenterCheckbox>
              <Checkbox
                className="check"
                label={formatMessage({
                  id: "dashboard::offerForm::reservationExplanation",
                  defaultMessage: "After the purchase, reservation is required",
                })}
                isChecked={values?.isVoucher && values?.isReservationRequired}
                onChange={() => {
                  const newVal = !values?.isReservationRequired
                  setFieldValue("isReservationRequired", newVal, true)
                }}
                disabled={!values?.isVoucher}
              />
            </StyledCenterCheckbox>
          </>
        ) : null}

        {!isMerchantOffline && !values.recurringIsEnabled && (
          <FieldArray name="tiers">
            {({ push, remove }) => (
              <>
                {values?.tiers?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <StyledLabel className="top-align subheader">
                        <FormattedMessage
                          id={`dashboard::campaign::${
                            isEventCampaign ? "entryDonation" : "donationOption"
                          }`}
                          defaultMessage={`${
                            isEventCampaign
                              ? "Entry donation"
                              : "Donation option"
                          }`}
                        />
                        {`${
                          values?.tiers?.length > 1 ? index + 1 : ""
                        }${REQUIRED_FIELD_SYMBOL}`}
                      </StyledLabel>
                      <StyledInlineRow>
                        <div>
                          <StyledLabel className="hide-on-desktop">
                            <FormattedMessage
                              id={`dashboard::campaign::${
                                isEventCampaign
                                  ? "entryDonation"
                                  : "donationOption"
                              }`}
                              defaultMessage={`${
                                isEventCampaign
                                  ? "Entry donation"
                                  : "Donation option"
                              }`}
                            />
                          </StyledLabel>

                          <TextInput
                            newDesign
                            type="currency"
                            data-testid={`tiers[${index}].donationAmount`}
                            id={`tiers[${index}].donationAmount`}
                            name={`tiers[${index}].donationAmount`}
                            className="currency-icon"
                            value={
                              values.tiers[index].isFree
                                ? 0
                                : values.tiers?.[index]?.donationAmount
                            }
                            disabled={values.tiers[index].isFree}
                            error={errors?.tiers?.[index]?.donationAmount}
                            touched={touched?.tiers?.[index]?.donationAmount}
                            maxLength={8}
                            onBlur={() =>
                              setFieldTouched(`tiers[${index}].donationAmount`)
                            }
                            onValueChange={({ value }) => {
                              setFieldValue(
                                `tiers[${index}].donationAmount`,
                                value ? Number(value) : "",
                                false
                              )
                            }}
                          />
                          <Checkbox
                            className={"free"}
                            label={formatMessage({
                              id: "dashboard::campaign::free",
                              defaultMessage: "Free",
                            })}
                            id="free"
                            isChecked={values.tiers?.[index]?.isFree}
                            onChange={(e) => {
                              setFieldValue(
                                `tiers[${index}].isFree`,
                                !values.tiers[index].isFree,
                                false
                              )
                              setFieldValue(
                                `tiers[${index}].donationAmount`,
                                0,
                                false
                              )
                            }}
                          />
                        </div>
                        {isEventCampaign ? (
                          <SeatComponent index={index} />
                        ) : (
                          <span />
                        )}
                        <TextInput
                          newDesign
                          data-testid={`tiers[${index}][description${localeSuffix}]`}
                          id={`tiers[${index}][description${localeSuffix}]`}
                          name={`tiers[${index}][description${localeSuffix}]`}
                          value={
                            values.tiers?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                          type="textarea"
                          maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={formatMessage({
                            id: `dashboard::campaign::${
                              isEventCampaign
                                ? "specifyOffer"
                                : "specifyDonation"
                            }`,
                            defaultMessage: `${
                              isEventCampaign
                                ? "Specify what this entry donation offers"
                                : "What this contribution is going to help"
                            }`,
                          })}
                          error={
                            errors?.tiers?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                          touched={
                            touched?.tiers?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                        />
                        <Button
                          data-testid="btn-delete-entry"
                          color="transparent"
                          variant="text"
                          className="btn-delete-entry"
                          type="button"
                          onClick={() => remove(index)}>
                          <FormattedMessage
                            id={`dashboard::campaign::${
                              isEventCampaign ? "deleteEntry" : "deleteDonation"
                            }`}
                            defaultMessage={`Delete this ${
                              isEventCampaign
                                ? "entry donation"
                                : "donation option"
                            }`}
                          />
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </StyledInlineRow>
                    </Fragment>
                  )
                })}
                {values?.tiers?.length < MAX_TIERS ? (
                  <Button
                    data-testid="btn-addTicket"
                    className="second-grid"
                    color="transparent"
                    type="button"
                    onClick={() =>
                      push({
                        donationAmount:
                          (values?.tiers?.length + 1) * DEFAULT_MIN_TIER_AMOUNT,
                        [`description${localeSuffix}`]: "",
                        maxTickets: "",
                        isUnlimitedTickets: true,
                        isFree: false,
                      })
                    }>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage
                      id={`dashboard::campaign::add${
                        isEventCampaign ? "Ticket" : "Donation"
                      }Option`}
                      defaultMessage={`Add ${
                        isEventCampaign ? "ticket" : "donation"
                      } option`}
                    />
                  </Button>
                ) : null}
              </>
            )}
          </FieldArray>
        )}
        {isMerchantOffline ? null : (
          <>
            <div>
              <StyledLabel className="top-align mt-none subheader">
                <FormattedMessage
                  id="dashboard::campaign::uploadPhoto"
                  defaultMessage="Upload a photo"
                />
                {REQUIRED_FIELD_SYMBOL}
              </StyledLabel>
              <StyledLabel className="subtext">
                <FormattedMessage
                  id="dashboard::campaign::uploadPhotoDescription"
                  defaultMessage="Upload images that represent your campaign. 1200x675px is the recommended resolution, which has an aspect ratio of 16/9."
                />
              </StyledLabel>
            </div>
            <div>
              <ImageGallery
                processingImages={processingImages}
                onDeleteImage={deleteCampaignImage}
              />
              <FieldError name="images" />
            </div>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::video"
                defaultMessage="Video"
              />
            </StyledLabel>
            <TextInput
              newDesign
              data-testid="input-video"
              id="video"
              name="video"
              value={values.video}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={formatMessage({
                id: "dashboard::campaign::pasteVideoLink",
                defaultMessage: "Paste link to the video here",
              })}
              error={errors.video}
              touched={touched.video}
            />
          </>
        )}
        <StyledLabel className="top-align  mt-none subheader">
          {isEventCampaign ? (
            <FormattedMessage
              id="dashboard::campaign::tellAboutEvent"
              defaultMessage="Tell about your event"
            />
          ) : (
            <FormattedMessage
              id="dashboard::campaign::tellAboutCampaign"
              defaultMessage="Tell about your campaign"
            />
          )}
          {REQUIRED_FIELD_SYMBOL}
        </StyledLabel>
        <div>
          <TextEditor
            data-testid={`input-description${localeSuffix}`}
            id={`description${localeSuffix}`}
            name={`description${localeSuffix}`}
            value={values[`description${localeSuffix}`]}
            placeholder={
              isEventCampaign
                ? formatMessage({
                    id: "dashboard::campaign::tellAboutEventMore",
                    defaultMessage:
                      "Tell potential donors more about your event. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest.",
                  })
                : formatMessage({
                    id: "dashboard::campaign::tellAboutCampaignMore",
                    defaultMessage:
                      "Tell potential donors more about your campaign. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest.",
                  })
            }
            onBlur={() => handleBlur(`description${localeSuffix}`)}
            onChange={(value) =>
              setFieldValue(`description${localeSuffix}`, value)
            }
            className={
              !!errors[`description${localeSuffix}`] &&
              !!touched[`description${localeSuffix}`]
                ? "text-editor-error"
                : "unset"
            }
          />
          <FieldError
            name={`description${localeSuffix}`}
            autoFocus={true}
            scrollOpts={{ block: "end", behavior: "smooth" }}
            error={errors}
            touched={touched}
          />
        </div>
        {!isSupporter && !isMerchantCorporate && !isMerchantOffline && (
          <>
            <StyledLabel className="top-align">
              <FormattedMessage
                id="dashboard::campaign::categories"
                defaultMessage="Categories"
              />
            </StyledLabel>
            <div data-testid="input-categories">
              <Dropdown
                isMulti={true}
                placeholder={formatMessage({
                  id: "dashboard::campaignForm::category",
                  defaultMessage: "Category",
                })}
                onBlur={() => setTouched({ ...touched, categories: true })}
                options={allCampaignCategories?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                defaultValue={
                  values?.categories?.length ? values.categories : []
                }
                onChange={(categories) => {
                  const fieldValue =
                    categories && categories.map(({ value }) => value)
                  setFieldValue("categories", fieldValue, false)
                }}
                error={touched.categories && errors?.categories}
              />
              <FieldError name="categories" />
            </div>
          </>
        )}

        <div>
          <StyledLabel className="top-align mt-none subheader">
            <FormattedMessage
              id="dashboard::campaign::attendees"
              defaultMessage="Attendees"
            />
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::campaign::attendeesDescription"
              defaultMessage="Enable this option if you want to collect the names of attendees during the donation. When activated, each donor will be required to provide the name of the attendee for each ticket"
            />
          </StyledLabel>
        </div>

        <StyledToggleWrapper>
          <CommonToggle
            defaultChecked={values.isNeedTicketBuyerInfo}
            handleChange={() =>
              setFieldValue(
                "isNeedTicketBuyerInfo",
                !values.isNeedTicketBuyerInfo
              )
            }
          />
          <FormattedMessage
            id="dashboard::campaign::requestAttendeesNames"
            defaultMessage="Request attendees names"
          />
        </StyledToggleWrapper>
        {!isMerchantOffline &&
          (values.tiers.length === 0 ||
            !values.tiers.every((tier) => tier.isFree)) && (
            <>
              <StyledLabel className="top-align  mt-none subheader">
                <FormattedMessage
                  id="dashboard::campaign::desiredAmountLabel"
                  defaultMessage="Desired amount"
                />
                {REQUIRED_FIELD_SYMBOL}
              </StyledLabel>
              <StyledDonationAmountField hasAmount={!!values.goalAmount}>
                <RadioSelect
                  items={[
                    {
                      id: "hasGoal",
                      onClick: () =>
                        values.hasNoGoal && setFieldValue("hasNoGoal", false),
                      label: (
                        <StyledDesiredAmountInputWrapper>
                          <TextInput
                            newDesign
                            className="currency-icon"
                            type="currency"
                            id="goalAmount"
                            name="goalAmount"
                            value={values.goalAmount}
                            placeholder={formatMessage({
                              id: "dashboard::campaign::desiredAmount",
                              defaultMessage:
                                "How much are you planning to raise",
                            })}
                            error={errors.goalAmount}
                            touched={touched.goalAmount}
                            onBlur={() => setFieldTouched("goalAmount")}
                            onValueChange={({ value }) => {
                              setFieldValue(
                                "goalAmount",
                                value ? Number(value) : "",
                                false
                              )
                            }}
                            maxLength={10}
                            disabled={!!values.hasNoGoal}
                          />
                        </StyledDesiredAmountInputWrapper>
                      ),
                    },
                    {
                      id: "hasNoGoal",
                      label: isEventCampaign
                        ? formatMessage({
                            id: "dashboard::campaign::hasNoSpecificGoalEvent",
                            defaultMessage:
                              "This is going to be an event without a specific goal",
                          })
                        : formatMessage({
                            id: "dashboard::campaign::hasNoSpecificGoal",
                            defaultMessage:
                              "This is going to be a campaign without a specific goal",
                          }),
                    },
                  ]}
                  defaultValue={values.hasNoGoal ? "hasNoGoal" : "hasGoal"}
                  onChange={(e) => {
                    e.target.value === "hasNoGoal"
                      ? setValues({
                          ...values,
                          hasNoGoal: true,
                          goalAmount: "",
                        })
                      : setFieldValue("hasNoGoal", false)
                  }}
                />
              </StyledDonationAmountField>
            </>
          )}

        <StyledLabel>
          <FormattedMessage
            id="dashboard::campaign::donorsMessage"
            defaultMessage="Donor's Message"
          />
          <p className="info">
            <FormattedMessage
              id="dashboard::campaign::donateMessage2"
              defaultMessage="Let your donors leave you a message while donating"
            />
          </p>
        </StyledLabel>

        <div>
          <Checkbox
            label={formatMessage({
              id: "dashboard::campaign::donorCheckout",
              defaultMessage:
                "Insert donor’s message text field on the checkout",
            })}
            id="enableDonationNote"
            isChecked={values.enableDonationNote}
            onChange={() => {
              setValues({
                ...values,
                enableDonationNote: !values.enableDonationNote,
              })
            }}
          />
        </div>

        {locale !== "en" ? (
          <>
            {isEnglishSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/svg/united-kingdom.svg"
                  }
                  alt="UK Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel>Name your campaign</StyledLabel>
                <TextInput
                  newDesign
                  data-testid="input-name-campaign-en"
                  id="nameEn"
                  name="nameEn"
                  value={values.nameEn}
                  onChange={handleChange}
                  placeholder="Name your campaign"
                  onBlur={handleBlur}
                  error={errors.nameEn}
                  touched={touched.nameEn}
                />
                <StyledLabel className="top-align">
                  Describe your campaign
                </StyledLabel>
                <div>
                  <TextEditor
                    data-testid="input-video"
                    id="descriptionEn"
                    name="descriptionEn"
                    value={values.descriptionEn}
                    placeholder="Tell more to your potential donors, about your campaign. Provide them all details, so they can donate"
                    onBlur={() => handleBlur("descriptionEn")}
                    onChange={(value) => setFieldValue("descriptionEn", value)}
                    className={
                      !!errors[`descriptionEn`] && !!touched[`descriptionEn`]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name="descriptionEn"
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </div>
                {!isMerchantOffline &&
                  values?.tiers?.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <StyledLabel className="top-align">
                          {`${
                            isEventCampaign
                              ? "Entry donation"
                              : "Donation option"
                          }`}
                          {values?.tiers?.length > 1 ? index + 1 : ""}
                        </StyledLabel>
                        <TextInput
                          newDesign
                          data-testid={`tiers[${index}].descriptionEn`}
                          id={`tiers[${index}].descriptionEn`}
                          name={`tiers[${index}].descriptionEn`}
                          type="textarea"
                          maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                          value={values.tiers?.[index]?.descriptionEn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={`${
                            isEventCampaign
                              ? "Specify what this entry donation offers"
                              : "What this contribution is going to help"
                          }`}
                          error={errors?.tiers?.[index]?.descriptionEn}
                          touched={touched?.tiers?.[index]?.descriptionEn}
                        />
                      </Fragment>
                    )
                  })}
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-english"
              color="transparent"
              onClick={handleEnglishSectionChange}
              type="button">
              <FontAwesomeIcon icon={isEnglishSectionOpen ? faMinus : faPlus} />
              {`${isEnglishSectionOpen ? "Delete" : "Add"} the English version`}
            </Button>
          </>
        ) : null}
        {locale !== "it" ? (
          <>
            {isItalianSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/italy.svg"
                  }
                  alt="Italy Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel>{`Assegna un nome alla tua campagna${REQUIRED_FIELD_SYMBOL}`}</StyledLabel>
                <TextInput
                  newDesign
                  data-testid="input-name-campaign-it"
                  id="nameIt"
                  name="nameIt"
                  value={values.nameIt}
                  onChange={handleChange}
                  placeholder="Il nome della tua campagna"
                  onBlur={handleBlur}
                  error={errors.nameIt}
                  touched={touched.nameIt}
                />
                <StyledLabel className="top-align">
                  Descrivi la tua campagna
                </StyledLabel>
                <div>
                  <TextEditor
                    data-testid="input-video"
                    id="descriptionIt"
                    name="descriptionIt"
                    value={values.descriptionIt}
                    placeholder="Racconta di più sulla campagna, ai tuoi potenziali donatori. Fornisci maggiori dettagli in modo che le persone possano donare"
                    onBlur={() => handleBlur("descriptionIt")}
                    onChange={(value) => setFieldValue("descriptionIt", value)}
                    className={
                      !!errors[`descriptionIt`] && !!touched[`descriptionIt`]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name="descriptionIt"
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </div>
                {!isMerchantOffline &&
                  values?.tiers?.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <StyledLabel className="top-align">
                          {`${
                            isEventCampaign
                              ? "Donazione ingresso"
                              : "Opzione di donazione"
                          }${
                            values?.tiers?.length > 1 ? index + 1 : ""
                          }${REQUIRED_FIELD_SYMBOL}`}
                        </StyledLabel>
                        <TextInput
                          newDesign
                          data-testid={`tiers[${index}].descriptionIt`}
                          id={`tiers[${index}].descriptionIt`}
                          name={`tiers[${index}].descriptionIt`}
                          type="textarea"
                          value={values.tiers?.[index]?.descriptionIt}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={`${
                            isEventCampaign
                              ? "Specifica cosa offre questa donazione di ingresso"
                              : "Che cosa aiuterà questo contributo"
                          }`}
                          error={errors?.tiers?.[index]?.descriptionIt}
                          touched={touched?.tiers?.[index]?.descriptionIt}
                        />
                      </Fragment>
                    )
                  })}
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-italian"
              color="transparent"
              onClick={handleItalianSectionChange}
              type="button">
              <FontAwesomeIcon icon={isItalianSectionOpen ? faMinus : faPlus} />
              {`${
                isItalianSectionOpen ? "Elimina" : "Aggiungere"
              } la versione italiana`}
            </Button>
          </>
        ) : null}
        {locale !== "es" ? (
          <>
            {isSpanishSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/spain.svg"
                  }
                  alt="Spain Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel>{`Nombra tu campaña${REQUIRED_FIELD_SYMBOL}`}</StyledLabel>
                <TextInput
                  newDesign
                  data-testid="input-name-campaign-es"
                  id="nameEs"
                  name="nameEs"
                  value={values.nameEs}
                  onChange={handleChange}
                  placeholder="Nombre de tu campaña"
                  onBlur={handleBlur}
                  error={errors.nameEs}
                  touched={touched.nameEs}
                />
                <StyledLabel className="top-align">
                  Describe tu campaña
                </StyledLabel>
                <div>
                  <TextEditor
                    data-testid="textarea-tell-about-es"
                    id="descriptionEs"
                    name="descriptionEs"
                    value={values.descriptionEs}
                    placeholder="Cuéntales a los posibles donantes más sobre tu campaña. Proporciona detalles que motiven a las personas a contribuir"
                    onBlur={() => handleBlur("descriptionEs")}
                    onChange={(value) => setFieldValue("descriptionEs", value)}
                    className={
                      !!errors[`descriptionEs`] && !!touched[`descriptionEs`]
                        ? "text-editor-error"
                        : "unset"
                    }
                  />
                  <FieldError
                    name="descriptionEs"
                    autoFocus={true}
                    scrollOpts={{ block: "end", behavior: "smooth" }}
                    error={errors}
                    touched={touched}
                  />
                </div>
                {!isMerchantOffline &&
                  values?.tiers?.map((item, index) => {
                    return (
                      <>
                        <StyledLabel className="top-align" key={index}>
                          {`Donación de entrada${
                            values?.tiers?.length > 1 ? index + 1 : ""
                          }${REQUIRED_FIELD_SYMBOL}`}
                        </StyledLabel>
                        <TextInput
                          newDesign
                          data-testid={`tiers[${index}].descriptionEs`}
                          id={`tiers[${index}].descriptionEs`}
                          name={`tiers[${index}].descriptionEs`}
                          type="textarea"
                          value={values.tiers?.[index]?.descriptionEs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={`${
                            isEventCampaign
                              ? "Especifique lo que ofrece esta donación de entrada"
                              : "En qué va a ayudar esta contribución"
                          }`}
                          error={errors?.tiers?.[index]?.descriptionEs}
                          touched={touched?.tiers?.[index]?.descriptionEs}
                        />
                      </>
                    )
                  })}
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-spanish"
              color="transparent"
              onClick={handleSpanishSectionChange}
              type="button">
              <FontAwesomeIcon icon={isSpanishSectionOpen ? faMinus : faPlus} />
              {isSpanishSectionOpen
                ? "Borrar opción de ticket"
                : "Añadi̇r la versi̇ón española"}
            </Button>
            {isSpanishSectionOpen ? <hr /> : null}
          </>
        ) : null}
        <StyledLabel>
          {isEventCampaign ? (
            <FormattedMessage
              id="dashboard::campaign::publishingDateEvent"
              defaultMessage="Event publishing date"
            />
          ) : (
            <FormattedMessage
              id="dashboard::campaign::publishingDate"
              defaultMessage="Campaign publishing date"
            />
          )}
          {REQUIRED_FIELD_SYMBOL}
        </StyledLabel>
        <StyledFlexRow>
          <DatePicker
            inputDataTip={formatMessage({
              id: "dashboard::campaign::publishingDateToolTip",
              defaultMessage:
                "The date, when your campaign starts to fundraise",
            })}
            inputDataFor="startsAt"
            minDate={new Date()}
            dataTestId="campaign-start-date-picker"
            selected={values.startsAt}
            onDateChange={(date) => handleStartDateChange(date)}
            error={errors.startsAt}
            touched={touched.startsAt}
          />
        </StyledFlexRow>
        {campaign?.status !== REVIEW_STATUS.ENDED && (
          <>
            {!isMerchantOffline && (
              <>
                <StyledLabel className="top-align  mt-none subheader">
                  <FormattedMessage
                    id="dashboard::campaign::privacy"
                    defaultMessage="Privacy"
                  />
                  {REQUIRED_FIELD_SYMBOL}
                </StyledLabel>
                <RadioSelect
                  items={items}
                  defaultValue={values.isUnlisted ? "private" : "public"}
                  onChange={(e) => {
                    setFieldValue("isUnlisted", e.target.value !== "public")
                  }}
                />
              </>
            )}
            <StyledLabel className="top-align  mt-none hide-on-mobile">
              <FormattedMessage
                id="dashboard::campaign::conditions"
                defaultMessage="Conditions"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <TermsAndConditions
              labelClassName="terms-label"
              name="isTermsAndPolicyAccepted"
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onClick={() =>
                setTouched({
                  ...touched,
                  isTermsAndPolicyAccepted: true,
                })
              }
            />
          </>
        )}
      </fieldset>
      <StyledButtonContainer>
        <div>
          <StyledActionButton
            data-testid="btn-leave"
            className="hide-on-mobile"
            color="transparent"
            type="button"
            onClick={() => {
              if (campaign?.status !== REVIEW_STATUS.ENDED) {
                setLeaveModalOpen(true)
              } else {
                goToCampaignList()
              }
            }}>
            {campaign?.status === REVIEW_STATUS.ENDED ? (
              <FormattedMessage
                id="dashboard::common::goBack"
                defaultMessage="Go Back"
              />
            ) : (
              <FormattedMessage
                id="dashboard::campaign::leave"
                defaultMessage="Leave without saving"
              />
            )}
          </StyledActionButton>
          {isUpdate && campaign?.status === REVIEW_STATUS.APPROVED ? (
            <StyledActionButton
              className="full-width"
              data-testid="btn-stop"
              color="transparent"
              onClick={() => setStopModalOpen(true)}
              type="button">
              {isEventCampaign ? (
                <FormattedMessage
                  id="dashboard::campaign::stopEvent"
                  defaultMessage="Stop Event"
                />
              ) : (
                <FormattedMessage
                  id="dashboard::campaign::stopCampaign"
                  defaultMessage="Stop Campaign"
                />
              )}
            </StyledActionButton>
          ) : null}
        </div>
        {campaign?.status !== REVIEW_STATUS.ENDED &&
        (!isPartner ||
          apiPermissions?.includes(API_PERMISSIONS.PARTNER_CREATE_CAMPAIGN)) ? (
          <StyledActionButton
            data-testid="btn-submit"
            className="full-width"
            color="red"
            type="submit">
            {isCampaignBeingCreated ? (
              <Spinner condensed />
            ) : campaign?.id ? (
              <FormattedMessage
                id="dashboard::campaignForm::submitForReview"
                defaultMessage="Submit For Review"
              />
            ) : isEventCampaign ? (
              <FormattedMessage
                id="dashboard::campaign::startEvent"
                defaultMessage="Start Event"
              />
            ) : (
              <FormattedMessage
                id="dashboard::campaign::startCampaign"
                defaultMessage="Start Campaign"
              />
            )}
          </StyledActionButton>
        ) : null}
      </StyledButtonContainer>
    </Form>
  )
}
