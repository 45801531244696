import React, { useState, useEffect, useRef, useContext } from "react"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { Formik } from "formik"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import * as Yup from "yup"
import queryString from "query-string"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth, useToast, useDefaultPartner } from "@tmu/hooks"
import { useApolloApiClients } from "@tmu/apollo/client"
import { addDate, differenceInDays } from "@tmu/utils/date"
import { capitalize } from "@tmu/utils/string"
import { useAllCampaignCategories } from "@tmu/hooks"
import {
  PARTNER_CAMPAIGN_DETAIL_QUERY,
  SUPPORTER_CAMPAIGN_DETAIL_QUERY,
  MERCHANT_CAMPAIGN_DETAIL_QUERY,
  ALL_SUPPORTER_CAMPAIGN_CAUSES_LISTING_QUERY,
} from "@tmu/apollo/dashboard/queries/campaign"
import {
  CREATE_USER_CAMPAIGN_MUTATION,
  CREATE_PARTNER_CAMPAIGN_MUTATION,
  CREATE_MERCHANT_CAMPAIGN_MUTATION,
  UPDATE_CAMPAIGN_MUTATION,
  CREATE_CAMPAIGN_IMAGE_MUTATION,
  DELETE_CAMPAIGN_IMAGE_MUTATION,
  CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION,
  MERCHANT_CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION,
  END_CAMPAIGN_MUTATION,
  DELETE_CAMPAIGN_TIER_MUTATION,
  UPDATE_CAMPAIGN_TIER_MUTATION,
  CREATE_CAMPAIGN_TIER_MUTATION,
} from "@tmu/apollo/dashboard/mutations/campaign"
import { Spinner, OnSubmitValidationError } from "@tmu/components/common"
import {
  campaignName,
  eventName,
  description,
  urlFormat,
  goalAmount,
  dateRequired,
  donationTier,
  categoryList,
  addressRequired,
  agreeTerms,
  dateRequiredWhenNoDeadline,
  virtualLinkRequired,
  supporterCauseRequired,
  imageRequired,
  numberOfDaysRequired,
  recurringDonationAmount,
  donationOption1,
  donationOption2,
  donationOption3,
} from "@tmu/utils/validation"
import {
  REVIEW_STATUS,
  VISIBILITY_STATUS,
  DEFAULT_MIN_TIER_AMOUNT,
  DEFAULT_DAY_OF_EVENT_START,
  CARDS_PER_PAGE,
} from "@tmu/apollo/constants"
import SEO from "@tmu/components/seo"
import {
  StyledCampaignCreationWrapper,
  StyledTitle,
  StyledHeader,
} from "./index.styles"
import { HomePageContext } from "@tmu/context/homePageContext"
import { LeaveModal } from "./LeaveModal"
import { CampaignCreationForm } from "./CampaignCreationForm"
import { StopModal } from "./StopModal"
import { CharitySelectorModal } from "./CharitySelectorModal"
import { isBrowser } from "@tmu/utils/auth"
import { CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE } from "@tmu/apollo/dashboard/mutations/campaign"
const CampaignCreation = ({ slug, location }) => {
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const mainCampaignType = params?.type === "event" ? "Event" : "Collection"
  const isSupporter = params?.supporter === "true"
  const isMerchantOffline = params?.offline === "true"
  const isMerchantCorporate = params?.corporate === "true"
  const backTo = params?.backTo
  const defaultCause = params?.defaultSupporterCause

  const [selectedCampaignType, setSelectedCampaignType] = useState()
  const { loading: loadingProfile } = useAuth()
  const [processingImages, setProcessingImages] = useState([])
  const [isEnglishSectionOpen, setEnglishSectionOpen] = useState(false)
  const [isItalianSectionOpen, setItalianSectionOpen] = useState(false)
  const [isSpanishSectionOpen, setSpanishSectionOpen] = useState(false)
  const [isExplanationVisible, setExplanationVisible] = useState(false)
  const { formatMessage, locale } = useIntl()
  const localeSuffix = capitalize(locale)
  const { defaultPartner, loading: loadingPartner } = useDefaultPartner()
  const { partnerClient, storefrontClient, merchantClient } =
    useApolloApiClients()
  const { success, error } = useToast()
  const { allCampaignCategories } = useAllCampaignCategories()
  const [selectedCharity, setSelectedCharity] = useState()
  const [selectedCampaign, setSelectedCampaign] = useState()
  const [charitySelectorModalStatus, setCharitySelectorModalStatus] = useState()
  const [isCampaignBeingCreated, setCampaignBeingCreated] = useState(false)
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false)
  const [isStopModalOpen, setStopModalOpen] = useState(false)
  const [isRecurring, setIsRecurring] = useState(false)
  const formRef = useRef()
  const { menuConfig, setMenuConfig } = useContext(HomePageContext)
  const [minValue1, setMinValue1] = useState()
  const [minValue2, setMinValue2] = useState()
  const CORPORATE_CAUSE_ID = process.env.CORPORATE_CAUSE_ID

  const { data: supperterCauseData, loading: supperterCauseLoading } = useQuery(
    ALL_SUPPORTER_CAMPAIGN_CAUSES_LISTING_QUERY,
    {
      variables: {
        first: CARDS_PER_PAGE,
        orderBy: "-order",
      },
    }
  )

  const activeClient =
    isMerchantOffline || isMerchantCorporate
      ? merchantClient
      : isSupporter
      ? storefrontClient
      : partnerClient
  const ACTIVE_CREATE_MUTATION =
    isMerchantOffline || isMerchantCorporate
      ? CREATE_MERCHANT_CAMPAIGN_MUTATION
      : isSupporter
      ? CREATE_USER_CAMPAIGN_MUTATION
      : CREATE_PARTNER_CAMPAIGN_MUTATION
  const query =
    isMerchantOffline || isMerchantCorporate
      ? MERCHANT_CAMPAIGN_DETAIL_QUERY
      : isSupporter || isMerchantCorporate
      ? SUPPORTER_CAMPAIGN_DETAIL_QUERY
      : PARTNER_CAMPAIGN_DETAIL_QUERY

  const { loading, data } = useQuery(query, {
    variables: {
      slug,
    },
    skip: !slug || slug === "create" || loadingPartner,
    client: activeClient,
    fetchPolicy: "cache-and-network",
  })

  const campaign = data?.campaign

  const isUpdate = campaign?.id?.length > 0

  useEffect(() => {
    if (isSupporter || isMerchantCorporate || isMerchantOffline) {
      setSelectedCharity(data?.campaign?.partner)
      setSelectedCampaign(data?.campaign?.supportedCampaign)
    }
  }, [data])

  const [
    callCampaign,
    {
      loading: charityLoading,
      data: charityData,
      called: charityCalled,
      refetch: charityRefetch,
    },
  ] = useLazyQuery(query, {
    variables: { slug: params?.charity, isPublic: true },
    client: storefrontClient,
  })

  useEffect(() => {
    if (charityData) {
      const campaign = charityData?.campaign
      if (params?.isCampaign === "true") {
        setSelectedCampaign(campaign)
      }

      setSelectedCharity(campaign?.partner)
      formRef?.current?.setFieldValue("partner", campaign?.partner?.id, true)
      return
    }
    const charitySlug = params?.charity
    if (charitySlug) {
      callCampaign({ variables: { slug: charitySlug } })
    } else if (isSupporter || isMerchantCorporate || isMerchantOffline) {
      setCharitySelectorModalStatus(true)
    }
    setMenuConfig({ ...menuConfig, hideOnDashboard: true, forceOverlay: true })
  }, [charityData])

  // useEffect(() => {
  //   if (selectedCharity?.slug && params?.charity !== selectedCharity?.slug) {
  //     params.charity = selectedCharity?.partner?.slug
  //     window?.history?.pushState({}, null, "?" + queryString.stringify(params))
  //     if (isBrowser) {
  //       window.scrollTo(0, 0)
  //     }
  //   }
  // }, [selectedCharity])

  const eventType = [
    {
      id: "real",
      label: formatMessage({
        id: "dashboard::campaignForm::real",
        defaultMessage: "Real",
      }),
    },
    {
      id: "virtual",
      label: formatMessage({
        id: "dashboard::campaignForm::virtual",
        defaultMessage: "Virtual",
      }),
    },
  ]
  const isEventCampaign =
    mainCampaignType === "Event" || campaign?.campaignType === "EV"

  const items = [
    {
      id: "public",
      label: formatMessage({
        id: isEventCampaign
          ? "dashboard::campaignForm::publicText_Event"
          : "dashboard::campaignForm::publicText",
        defaultMessage: "I want my page to be displayed on the site",
      }),
    },
    {
      id: "private",
      label: formatMessage({
        id: isEventCampaign
          ? "dashboard::campaignForm::privateText_Event"
          : "dashboard::campaignForm::privateText",

        defaultMessage:
          "I want to hide my page and make it visible only through a direct link or share and not be displayed on the site. (use this option if page is not ready and you are going to make changes before uploading it)",
      }),
    },
  ]

  const unknownError = formatMessage({
    id: "dashboard::campaignForm::errorMessage",
    defaultMessage: "An error occurred",
  })

  useEffect(() => {
    if (campaign?.nameEn) setEnglishSectionOpen(true)
    if (campaign?.nameIt) setItalianSectionOpen(true)
    if (campaign?.nameEs) setSpanishSectionOpen(true)
    setSelectedCampaignType(campaign?.campaignType || (isEventCampaign && "EV"))
  }, [
    campaign?.nameEn,
    campaign?.nameIt,
    campaign?.nameEs,
    campaign?.campaignType,
    isEventCampaign,
  ])

  const validationSchema = Yup.object().shape({
    nameEn:
      (locale === "en" || isEnglishSectionOpen) && isEventCampaign
        ? eventName({ formatMessage })
        : locale === "en" || isEnglishSectionOpen
        ? campaignName({ formatMessage })
        : null,
    nameIt:
      (locale === "it" || isItalianSectionOpen) && isEventCampaign
        ? eventName({ formatMessage })
        : locale === "it" || isItalianSectionOpen
        ? campaignName({ formatMessage })
        : null,
    nameEs:
      (locale === "es" || isSpanishSectionOpen) && isEventCampaign
        ? eventName({ formatMessage })
        : locale === "es" || isSpanishSectionOpen
        ? campaignName({ formatMessage })
        : null,
    descriptionEn: locale === "en" && description({ formatMessage }),
    descriptionIt: locale === "it" && description({ formatMessage }),
    descriptionEs: locale === "es" && description({ formatMessage }),
    startsAt: dateRequired({ formatMessage }),
    deadline:
      selectedCampaignType !== "EV" &&
      dateRequiredWhenNoDeadline({ formatMessage }),
    numberOfDays: !isEventCampaign && numberOfDaysRequired({ formatMessage }),
    video: urlFormat({ formatMessage }),
    images: !isMerchantOffline && imageRequired({ formatMessage }),
    categories:
      !isSupporter &&
      !isMerchantCorporate &&
      !isMerchantOffline &&
      selectedCampaignType !== "EV" &&
      categoryList({ formatMessage }),
    goalAmount:
      !isMerchantOffline & !isMerchantCorporate &&
      goalAmount({ formatMessage }),
    recurringAmountOne:
      isRecurring &&
      donationOption1({
        formatMessage,
        minValue: 5,
      }),

    recurringAmountTwo:
      isRecurring &&
      donationOption2({
        formatMessage,
        minValue1,
      }),

    recurringAmountThree:
      isRecurring &&
      donationOption3({
        formatMessage,
        minValue1,
        minValue2,
      }),
    eventStartsAt:
      selectedCampaignType === "EV" && dateRequired({ formatMessage }),
    eventAddress:
      selectedCampaignType === "EV" && addressRequired({ formatMessage }),
    virtualEventLink:
      selectedCampaignType === "EV" && virtualLinkRequired({ formatMessage }),
    tiers:
      !isMerchantOffline &&
      !isRecurring &&
      donationTier({
        locale,
        formatMessage,
        isEnglishSectionOpen,
        isItalianSectionOpen,
        isSpanishSectionOpen,
      }),
    isTermsAndPolicyAccepted: agreeTerms({ formatMessage }),
    supporterCause:
      isSupporter &&
      !isMerchantCorporate &&
      supporterCauseRequired({ formatMessage }),
  })

  const initialValues = {
    id: campaign?.id,
    nameEn: campaign?.nameEn || "",
    descriptionEn: campaign?.descriptionEn || "",
    nameIt: campaign?.nameIt || "",
    descriptionIt: campaign?.descriptionIt || "",
    nameEs: campaign?.nameEs || "",
    descriptionEs: campaign?.descriptionEs || "",
    hasNoGoal: campaign?.goalAmount ? false : campaign?.id ? true : false,
    startsAt:
      (campaign?.startsAt && new Date(campaign?.startsAt)) || new Date(),
    goalAmount: campaign?.goalAmount || "",
    deadline: campaign?.deadline
      ? new Date(campaign?.deadline)
      : isEventCampaign
      ? addDate(new Date(), { days: DEFAULT_DAY_OF_EVENT_START })
      : null,
    hasNoDeadline: isEventCampaign ? false : !!!campaign?.deadline,
    numberOfDays:
      campaign?.startsAt && campaign?.deadline
        ? differenceInDays(
            new Date(campaign?.deadline),
            new Date(campaign?.startsAt)
          )
        : "",
    video: campaign?.video || "",
    partner: campaign?.partner?.id
      ? campaign?.partner?.id
      : isSupporter || isMerchantOffline || isMerchantCorporate
      ? selectedCharity?.id || selectedCharity?.partner?.id || ""
      : defaultPartner?.id || "",
    supportedCampaign:
      isSupporter || isMerchantOffline || isMerchantCorporate
        ? campaign?.supportedCampaign?.id
        : null,

    images:
      campaign?.images?.edges?.map(({ node }) => ({
        ...node,
        existing: true,
      })) ?? [],
    isUnlisted: campaign?.id
      ? campaign?.visibilityStatus === VISIBILITY_STATUS?.UNLISTED
      : true,
    categories: campaign?.id
      ? campaign?.categories?.edges?.map(({ node }) => node.id)
      : [],
    supporterCause: isMerchantOffline
      ? CORPORATE_CAUSE_ID
      : campaign?.supporterCause?.id || "",
    campaignType: campaign?.campaignType || selectedCampaignType || "",
    eventAddress: campaign?.eventAddress || "",
    eventMapLink: campaign?.eventMapLink || "",
    isVirtualEvent: !!campaign?.virtualEventLink,
    virtualEventLink: campaign?.virtualEventLink || "",
    eventStartsAt: campaign?.eventStartsAt
      ? new Date(campaign?.eventStartsAt)
      : campaign?.startsAt
      ? new Date(
          addDate(new Date(campaign?.startsAt), {
            days: DEFAULT_DAY_OF_EVENT_START,
          })
        )
      : addDate(new Date(new Date().setHours(12, 0, 0, 0)), {
          days: DEFAULT_DAY_OF_EVENT_START,
        }),
    tiers: isMerchantOffline
      ? []
      : campaign?.tiers?.edges
          ?.map(({ node }) => ({
            ...node,
            isFree:
              node.donationAmount === "0.00" ||
              node.donationAmount === "0" ||
              node.donationAmount === 0 ||
              node.donationAmount === 0.0,
          }))
          .sort((a, b) => a?.donationAmount - b?.donationAmount) ?? [
          {
            donationAmount: DEFAULT_MIN_TIER_AMOUNT,
            [`description${localeSuffix}`]: "",
            maxTickets: "",
            isUnlimitedTickets: true,
            isFree: DEFAULT_MIN_TIER_AMOUNT === 0,
          },
        ],
    enableDonationNote: campaign?.enableDonationNote || false,
    recurringIsEnabled: campaign?.recurringIsEnabled || false,
    recurringAmountOne: campaign?.recurringAmountOne,
    recurringAmountTwo: campaign?.recurringAmountTwo,
    recurringAmountThree: campaign?.recurringAmountThree,
    isTermsAndPolicyAccepted: false,
    isVoucher: campaign?.id
      ? campaign?.isVoucher
      : params?.isVoucher === "true" || params?.type === "event",
    isReservationRequired: campaign?.isReservationRequired,
    isNeedTicketBuyerInfo: campaign?.isNeedTicketBuyerInfo,
  }

  useEffect(() => {
    setMinValue1(campaign?.recurringAmountOne)
    setMinValue2(campaign?.recurringAmountTwo)
  }, [campaign?.recurringAmountOne])

  const [createCampaign] = useMutation(ACTIVE_CREATE_MUTATION, {
    client: activeClient,
  })
  const [submitForReview] = useMutation(
    isMerchantOffline || isMerchantCorporate
      ? MERCHANT_CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION
      : CAMPAIGN_SUBMIT_FOR_REVIEW_MUTATION,
    {
      client: activeClient,
    }
  )
  const [partialUpdateCampaign] = useMutation(
    CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE,
    {
      client: activeClient,
    }
  )
  const [createCampaignImage] = useMutation(CREATE_CAMPAIGN_IMAGE_MUTATION, {
    client: activeClient,
  })
  const [deleteCampaignImage] = useMutation(DELETE_CAMPAIGN_IMAGE_MUTATION, {
    client: activeClient,
  })

  const [createCampaignTier] = useMutation(CREATE_CAMPAIGN_TIER_MUTATION, {
    client: activeClient,
  })
  const [updateCampaignTier] = useMutation(UPDATE_CAMPAIGN_TIER_MUTATION, {
    client: activeClient,
  })
  const [deleteCampaignTier] = useMutation(DELETE_CAMPAIGN_TIER_MUTATION, {
    client: activeClient,
  })
  const [endCampaign] = useMutation(END_CAMPAIGN_MUTATION, {
    client: activeClient,
  })

  const handleEndCampaign = () => {
    setStopModalOpen(false)
    endCampaign({ variables: { input: { id: campaign?.id } } }).then(
      ({ data, errors }) => {
        if (errors?.length) errors.map((err) => error(err.message))
        if (data.endCampaign?.errors?.length)
          data.endCampaign?.errors.map((err) => error(err.messages?.[0]))
        if (data.endCampaign?.success) {
          success(
            formatMessage({
              id: "dashboard::campaignList::campaignEnded",
              defaultMessage: "The campaign is ended successfully",
            })
          )
          goToCampaignList()
        }
      }
    )
  }

  const handleLeaveModalClose = () => {
    setLeaveModalOpen(false)
    setTimeout(() => {
      window?.scrollTo(0, 0)
    }, 100)
  }

  const handleStopModalClose = () => {
    setStopModalOpen(false)
  }

  const campaignMutationSuccessMessage = isUpdate
    ? formatMessage({
        id: "dashboard::campaignForm::updateMessage",
        defaultMessage: "Your updates are submitted for review!",
      })
    : isEventCampaign
    ? formatMessage({
        id: "dashboard::campaignForm::createdEvent",
        defaultMessage: "Your event is created!",
      })
    : formatMessage({
        id: "dashboard::campaignForm::created",
        defaultMessage: "Your campaign is created!",
      })

  const selectCharityErrorText = formatMessage({
    id: "dashboard::campaign::pleaseSelectCharity",
    defaultMessage: "Pick select a charity",
  })

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    if (isSupporter && !selectedCharity) {
      error(selectCharityErrorText)
      if (isBrowser) {
        window.scrollTo(0, 0)
      }
      setSubmitting(false)
      return
    }

    if (defaultPartner?.status === REVIEW_STATUS.WAITING_FOR_APPROVAL) {
      error(
        formatMessage({
          id: "dashboard::campaignForm::partnerWaitingApproval",
          defaultMessage: "Your partner account is waiting for review!",
        })
      )
      setSubmitting(false)
      return
    }
    setSubmitting(true)
    setCampaignBeingCreated(true)

    const {
      id: campaignId,
      images: campaignImages,
      tiers,
      hasNoDeadline,
      hasNoGoal,
      isTermsAndPolicyAccepted,
      numberOfDays,
      campaignType,
      eventAddress,
      eventMapLink,
      eventStartsAt,
      isUnlisted,
      isVirtualEvent,
      virtualEventLink,
      supporterCause,
      ...rest
    } = values
    const isAllTiersFree = values.tiers.every((tier) => tier.isFree)
    const editedCampaignType = isEventCampaign
      ? "EV"
      : isAllTiersFree
      ? "OP"
      : hasNoGoal === false && !isMerchantCorporate && !isMerchantOffline
      ? "GB"
      : !hasNoDeadline
      ? "TL"
      : "OP"
    const tiersToDelete = initialValues.tiers.filter((initialTierValue) => {
      if (!values.tiers.find((tier) => tier.id === initialTierValue.id))
        return initialTierValue
    })
    let newValues = {
      ...rest,
      supportedCampaign: selectedCampaign?.id,
      name: values?.[`name${localeSuffix}`],
      campaignType: editedCampaignType,
      deadline: values.deadline || null,
      goalAmount: values.goalAmount.toString() || null,
      visibilityStatus:
        campaign?.visibilityStatus === VISIBILITY_STATUS?.FEATURED
          ? VISIBILITY_STATUS?.FEATURED
          : values?.isUnlisted
          ? VISIBILITY_STATUS?.UNLISTED
          : VISIBILITY_STATUS?.LISTED,
      categories: values?.categories?.length ? values.categories : [],
      isVoucher: values?.isVoucher,
      isReservationRequired: values?.isVoucher && values?.isReservationRequired,
      recurringAmountOne: values.recurringAmountOne?.toString(),
      recurringAmountTwo: values.recurringAmountTwo?.toString(),
      recurringAmountThree: values.recurringAmountThree?.toString(),
      // Note: if you are going to change this line contact me
      // startsAt: values?.isUnlisted === false ? null : values?.startsAt, // this line should be deleted for offline merchant campaigns
    }
    if (editedCampaignType === "EV") {
      newValues = {
        ...newValues,
        eventAddress: isVirtualEvent ? null : eventAddress,
        eventMapLink: isVirtualEvent ? null : eventMapLink,
        virtualEventLink: isVirtualEvent ? virtualEventLink : null,
        eventStartsAt,
        deadline: eventStartsAt,
      }
    }
    if (isSupporter) {
      if (isMerchantOffline) {
        newValues = {
          ...newValues,
          supporterCause,
        }
      } else {
        newValues = {
          ...newValues,
          virtualEventLink: isVirtualEvent ? virtualEventLink : null,
          supporterCause,
        }
      }
    }

    const nameErrorText = formatMessage({
      id: "field::error::nameUnique",
      defaultMessage: "Name should be unique",
    })

    const resultFieldName = isUpdate
      ? "campaignSubmitForReview"
      : "createCampaign"
    const resultHandler = ({
      data: { [resultFieldName]: errorData },
      errors: apiErrors,
    }) => {
      const { campaign, errors } = { ...errorData }
      if (errors?.length || apiErrors?.length) {
        errors?.forEach((err) => {
          const msg = err?.messages?.[0]
          const errorMessage = msg?.includes("Must be unique")
            ? nameErrorText
            : msg

          if (err?.field) {
            let field =
              err.field === "nameEn" ? "name" + localeSuffix : err.field
            setFieldError(field, errorMessage || unknownError, true)
            const element = document?.querySelector(`[name="${field}"]`)
            if (element)
              element.scrollIntoView({ behavior: "smooth", block: "center" })
          } else {
            error(errorMessage || err.message || unknownError)
          }
        })
        apiErrors?.forEach((err) => {
          if (err.message?.includes("Must be unique")) error(nameErrorText)
          else error(err.messages?.[0] || err.message || unknownError)
        })
        setSubmitting(false)
        setCampaignBeingCreated(false)
      } else {
        const newImages = campaignImages.filter(
          (img) => img.id.indexOf("user-upload") > -1
        )
        try {
          if (tiersToDelete.length) {
            Promise.all(
              tiersToDelete
                ?.filter((item) => item?.id)
                .map(({ id }) => {
                  deleteCampaignTier({
                    variables: {
                      input: {
                        id,
                      },
                    },
                  })
                })
            ).then((result) => {})
          }
          if (!isRecurring && tiers.length) {
            Promise.all(
              tiers.map(({ id, ...tier }) => {
                if (id) {
                  updateCampaignTier({
                    variables: {
                      input: {
                        id,
                        description: tier[`description${localeSuffix}`],
                        donationAmount: tier.isFree
                          ? 0
                          : tier.donationAmount.toString(),
                        donationAmountOperator: "EQ",
                        maxTickets: tier.maxTickets || undefined,
                        isUnlimitedTickets: isEventCampaign
                          ? tier.isUnlimitedTickets
                          : true,
                        descriptionEn: tier.descriptionEn,
                        descriptionIt: tier.descriptionIt,
                        descriptionEs: tier.descriptionEs,
                      },
                    },
                  })
                    .then(({ data }) => {
                      if (data?.updateDonationTier?.errors?.length) {
                        errorHandler(data.updateDonationTier.errors)
                      }
                    })
                    .catch((err) => {
                      errorHandler(err)
                    })
                } else {
                  createCampaignTier({
                    variables: {
                      input: {
                        campaign: campaign?.id,
                        description: tier[`description${localeSuffix}`],
                        donationAmount: tier.isFree
                          ? 0
                          : tier.donationAmount.toString(),
                        donationAmountOperator: "EQ",
                        maxTickets: tier.maxTickets || undefined,
                        isUnlimitedTickets: isEventCampaign
                          ? tier.isUnlimitedTickets
                          : true,
                        descriptionEn: tier.descriptionEn,
                        descriptionIt: tier.descriptionIt,
                        descriptionEs: tier.descriptionEs,
                      },
                    },
                  })
                    .then(({ data }) => {
                      if (data?.createDonationTier?.errors?.length)
                        errorHandler(data?.createDonationTier?.errors)
                    })
                    .catch((err) => {
                      errorHandler(err)
                    })
                }
              })
            )
          }
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms))

          if (newImages.length) {
            setProcessingImages(newImages.map(({ id }) => id))

            const createImagesWithDelay = async () => {
              for (const { image, order, id } of newImages) {
                await delay(2500)
                await createCampaignImage({
                  variables: {
                    input: {
                      image,
                      order,
                      campaign: campaign?.id,
                    },
                  },
                })
              }
            }

            createImagesWithDelay().then(() => {
              setProcessingImages([])
              setTimeout(() => {
                partialUpdateCampaign({
                  variables: {
                    input: {
                      id: campaign?.id,
                      reviewStatus: REVIEW_STATUS.READY,
                    },
                  },
                })
              }, 2000)

              success(campaignMutationSuccessMessage)
              goToCampaignList(campaign?.slug)
            })
          }
        } catch (err) {
          errorHandler(err)
        } finally {
          if (!newImages.length) {
            setTimeout(() => {
              partialUpdateCampaign({
                variables: {
                  input: {
                    id: campaign?.id,
                    reviewStatus: REVIEW_STATUS.READY,
                  },
                },
              })
            }, 2000)
            success(campaignMutationSuccessMessage)
            goToCampaignList(campaign?.slug)
          }
        }
      }
    }
    const errorHandler = (errors) => {
      if (!errors) return
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          error(
            (err?.field
              ? err.field + " : " + err.messages?.[0]
              : err.messages?.[0]) ||
              err.message ||
              unknownError
          )
        })
      } else error(errors?.message ?? unknownError)
      setSubmitting(false)
      setCampaignBeingCreated(false)
    }
    if (isUpdate) {
      submitForReview({
        variables: {
          input: {
            ...newValues,
            id: campaign?.id,
          },
        },
        client: activeClient,
      })
        .then((data) => resultHandler(data))
        .catch(errorHandler)
    } else {
      createCampaign({
        variables: {
          input: newValues,
        },
        client: activeClient,
      })
        .then((data) => resultHandler(data))
        .catch(errorHandler)
    }
  }

  const handleStartDateChange = (date) => {
    const values = formRef?.current?.values
    const deadline =
      values.deadline && differenceInDays(values.deadline, date) < 0
        ? date
        : values.deadline
    const eventStartsAt =
      values.eventStartsAt && differenceInDays(values.eventStartsAt, date) < 0
        ? date
        : values.eventStartsAt
    const numberOfDays = deadline ? differenceInDays(deadline, date) + 1 : ""
    formRef?.current?.setValues({
      ...values,
      numberOfDays,
      startsAt: date,
      deadline,
      eventStartsAt,
    })
  }

  const handleDeadlineDateChange = (date) => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
      numberOfDays: date ? differenceInDays(date, values.startsAt) + 1 : "",
      deadline: date,
    })
  }

  const handleEnglishSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
      nameEn: "",
      descriptionEn: "",
    })
    values?.tiers?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `tiers[${index}].descriptionEn`,
        "",
        false
      )
    })

    setEnglishSectionOpen(!isEnglishSectionOpen)
  }

  const handleItalianSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
      nameIt: "",
      descriptionIt: "",
    })
    values?.tiers?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `tiers[${index}].descriptionIt`,
        "",
        false
      )
    })

    setItalianSectionOpen(!isItalianSectionOpen)
  }

  const handleSpanishSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
      nameEs: "",
      descriptionEs: "",
    })
    values?.tiers?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `tiers[${index}].descriptionEs`,
        "",
        false
      )
    })

    setSpanishSectionOpen(!isSpanishSectionOpen)
  }

  const goToCampaignList = (slug) => {
    if (!slug) {
      navigate(
        isSupporter
          ? `/dashboard/user/campaigns`
          : isMerchantOffline
          ? "/dashboard/merchants/campaigns/?offline=true"
          : isMerchantCorporate
          ? "/dashboard/merchants/campaigns/?corporate=true"
          : `/dashboard/charities/campaigns`
      )
    } else {
      navigate(isUpdate ? `/campaigns/${slug}` : `/campaigns/${slug}?new=true`)
    }
  }

  return loadingProfile ||
    loadingPartner ||
    loading ||
    isCampaignBeingCreated ? (
    <Spinner condensed />
  ) : (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "dashboard::campaignForm::pageTitle",
          defaultMessage: "New campaign",
        })}
      />
      <StyledCampaignCreationWrapper>
        <StyledHeader>
          <div className="back">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setLeaveModalOpen(true)}
            />
          </div>
          <StyledTitle data-testid="title">
            {isUpdate ? (
              <>
                <FormattedMessage
                  id="dashboard::campaignForm::campaignUpdate"
                  defaultMessage="Update Campaign"
                />
              </>
            ) : (
              <FormattedMessage
                id={`dashboard::campaign::create${
                  isEventCampaign
                    ? "Event"
                    : isMerchantOffline
                    ? "Merchant"
                    : isMerchantCorporate
                    ? "Brand"
                    : "Collection"
                }Campaign`}
                defaultMessage={`Create ${
                  isEventCampaign
                    ? "an event"
                    : isMerchantOffline
                    ? "a merchant's"
                    : isMerchantCorporate
                    ? "a coorporate"
                    : "a collection"
                } campaign`}
              />
            )}
          </StyledTitle>
        </StyledHeader>
        <Formik
          enableReinitialize={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}>
          {() => {
            return (
              <>
                <OnSubmitValidationError />
                <CampaignCreationForm
                  {...{
                    campaign,
                    isEventCampaign,
                    charityLoading,
                    supperterCauseLoading,
                    selectedCharity,
                    selectedCampaign,
                    charityData,
                    selectCharityErrorText,
                    isUpdate,
                    setCharitySelectorModalStatus,
                    handleStartDateChange,
                    handleDeadlineDateChange,
                    eventType,
                    setExplanationVisible,
                    isExplanationVisible,
                    processingImages,
                    deleteCampaignImage,
                    allCampaignCategories,
                    isEnglishSectionOpen,
                    handleEnglishSectionChange,
                    isItalianSectionOpen,
                    handleItalianSectionChange,
                    isSpanishSectionOpen,
                    handleSpanishSectionChange,
                    items,
                    setLeaveModalOpen,
                    goToCampaignList,
                    setStopModalOpen,
                    isCampaignBeingCreated,
                    supperterCauseData,
                    setMinValue1,
                    setMinValue2,
                    isRecurring,
                    setIsRecurring,
                  }}
                />
                {isLeaveModalOpen && (
                  <LeaveModal
                    {...{
                      isLeaveModalOpen,
                      handleLeaveModalClose,
                      isSupporter,
                      isMerchantOffline,
                      isMerchantCorporate,
                      charity: params?.charity,
                      defaultSupporterCause: defaultCause,
                      backTo,
                    }}
                  />
                )}
                {isStopModalOpen && (
                  <StopModal
                    {...{
                      isStopModalOpen,
                      handleStopModalClose,
                      handleEndCampaign,
                      isEvent: isEventCampaign,
                    }}
                  />
                )}
                {charitySelectorModalStatus && (
                  <CharitySelectorModal
                    {...{
                      charitySelectorModalStatus,
                      params,
                      setSelectedCharity,
                      setSelectedCampaign,
                      setCharitySelectorModalStatus,
                      scrollTopAfterClose: true,
                    }}
                  />
                )}
              </>
            )
          }}
        </Formik>
      </StyledCampaignCreationWrapper>
    </>
  )
}
export default CampaignCreation
