import React, { Fragment, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Form, FieldArray, useFormikContext } from "formik"
import { compareAsc } from "date-fns"
import { addDate } from "@tmu/utils/date"

import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DEFAULT_MIN_TIER_AMOUNT,
  ENTRY_DONATION_MAX_TEXT_LENGTH,
  MAX_TEXT_LENGTH,
  MAX_TIERS,
  REQUIRED_FIELD_SYMBOL,
  REVIEW_STATUS,
} from "@tmu/apollo/constants"
import {
  Button,
  Checkbox,
  CommonToggle,
  CountrySelectorFilter,
  DatePicker,
  FieldError,
  GroupedCategorySubcategorySelect,
  RadioSelect,
  Spinner,
  TermsAndConditions,
  TextEditor,
  TextInput,
} from "@tmu/components/common"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import {
  ImageSelector,
  FavoriteCharityProfileForm,
} from "@tmu/components/dashboard/dashboardCommon"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { capitalize } from "@tmu/utils/string"
import ContactForm from "./ContactForm"
import {
  StyledActionButton,
  StyledButtonContainer,
  StyledContactSection,
  StyledFlexRow,
  StyledInlineRow,
  StyledInlineRow2,
  StyledInvoicingWrapper,
  StyledLabel,
  StyledPacDiscount,
  StyledPhoneAndAddress,
  StyledAddressLinkRow,
  StyledExplanationText,
  StyledLabelHref,
  StyledFavCharityWrapper,
} from "./index.styles"

import { VoucherCodeSelector } from "@tmu/components/common"
import { EffectCards } from "swiper"

const { CLOUDFLARE_IMAGE_URL } = process.env

export const ServiceCreationForm = ({
  service,
  defaultMerchant,
  isUpdate,
  isEnglishSectionOpen,
  handleEnglishSectionChange,
  isItalianSectionOpen,
  handleItalianSectionChange,
  isSpanishSectionOpen,
  handleSpanishSectionChange,
  setStopModalOpen,
  isServiceBeingCreated,
  initialValues,
  isVoucher,
  isEventOffer,
  setIsVoucher,
  saveAddress,
}) => {
  const { formatMessage, locale, defaultLocale } = useIntl()
  const localeSuffix = capitalize(locale)
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    setFieldTouched,
    setTouched,
    isSubmitting,
    submitCount,
  } = useFormikContext()

  console.log("errors", errors)
  const [isContactFormVisible, setContactFormVisible] = useState(false)
  const [isFavoriteCharityFormVisible, setFavoriteCharityFormVisible] =
    useState(false)

  const eventType = [
    {
      id: "real",
      label: formatMessage({
        id: "dashboard::campaignForm::real",
        defaultMessage: "Real",
      }),
    },
    {
      id: "virtual",
      label: formatMessage({
        id: "dashboard::campaignForm::virtual",
        defaultMessage: "Virtual",
      }),
    },
  ]
  const privacyText = formatMessage({
    id: "dashboard::service::privateText",
    defaultMessage:
      "I want to hide the service and make it visible only through a direct link or share and not be displayed on the site",
  })

  const privacyVoucherText = formatMessage({
    id: "dashboard::service::privateVoucherText",
    defaultMessage:
      "I want to hide the voucher and make it visible only through a direct link or share and not be displayed on the site",
  })
  const privacyEventText = formatMessage({
    id: "dashboard::service::privateEventText",
    defaultMessage:
      "I want to hide the event and make it visible only through a direct link or share and not be displayed on the site",
  })

  const publicText = formatMessage({
    id: "dashboard::service::publicText",
    defaultMessage: "I want the service to be displayed on the site",
  })

  const publicVoucherText = formatMessage({
    id: "dashboard::service::publicVoucherText",
    defaultMessage: "I want the voucher to be displayed on the site",
  })

  const publicEventText = formatMessage({
    id: "dashboard::service::publicEventText",
    defaultMessage: "I want the event to be displayed on the site",
  })

  const items = [
    {
      id: "private",
      label: isEventOffer
        ? privacyEventText
        : isVoucher
        ? privacyVoucherText
        : privacyText,
    },
    {
      id: "public",
      label: isEventOffer
        ? publicEventText
        : isVoucher
        ? publicVoucherText
        : publicText,
    },
  ]

  const { isTablet } = getAllScreenTypes()

  useEffect(() => {
    if (!values?.favoriteCharity && defaultMerchant?.favoriteCharity) {
      setFieldValue("favoriteCharity", defaultMerchant?.favoriteCharity, false)
    }
  }, [defaultMerchant])

  const handleCountrySelect = (countries, cities) => {
    if (countries && Array.isArray(countries)) {
      const tempCountries = countries
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCountries", tempCountries, true)
    }
    if (cities && Array.isArray(cities)) {
      const tempCities = cities
        ?.map((item) => item?.value)
        ?.filter((item) => item !== null)
      setFieldValue("availableCities", tempCities, true)
    }
  }

  const handleCategorySelect = (selectedCategories) => {
    setFieldValue("categories", selectedCategories, true)
  }

  const confirmationCodeText = formatMessage({
    id: "dashboard::offerForm::confirmationCode",
    defaultMessage: "Confirmation Code",
  })

  const serviceDatesText = formatMessage({
    id: "dashboard::service::serviceDates",
    defaultMessage: "Expiry date",
  })

  const serviceOptionText = formatMessage({
    id: "dashboard::service::serviceOption",
    defaultMessage: "Service option",
  })

  const voucherOptionText = formatMessage({
    id: "dashboard::service::voucherOption",
    defaultMessage: "Voucher option",
  })

  const eventOptionText = formatMessage({
    id: "dashboard::service::eventOption",
    defaultMessage: "Event option",
  })

  const nameYourServiceText = formatMessage({
    id: "dashboard::offerForm::nameYourService",
    defaultMessage: "Name your service",
  })

  const nameYourVoucherText = formatMessage({
    id: "dashboard::offerForm::nameYourVoucher",
    defaultMessage: "Name your voucher",
  })

  const serviceDescriptionText = formatMessage({
    id: "dashboard::service::description",
    defaultMessage: "Description",
  })

  const serviceFinishDateCheckText = formatMessage({
    id: "dashboard::service::hasNoDeadline",
    defaultMessage: "This service won't have a finish date",
  })

  const voucherFinishDateCheckText = formatMessage({
    id: "dashboard::service::voucherHasNoDeadline",
    defaultMessage: "This voucher won't have a finish date",
  })

  const descriptionServiceExplanationText = formatMessage({
    id: "dashboard::service::descriptionSubtext",
    defaultMessage: "Customers will see this description on your service card.",
  })

  const descriptionVoucherExplanationText = formatMessage({
    id: "dashboard::service::descriptionVoucherSubtext",
    defaultMessage: "Customers will see this description on your voucher card.",
  })

  const regionServiceExplanationText = formatMessage({
    id: "dashboard::service::chooseRegionDescription",
    defaultMessage: "In what countries you are ready to provide your service",
  })

  const regionVoucherExplanationText = formatMessage({
    id: "dashboard::service::chooseRegionVoucherDescription",
    defaultMessage: "In what countries you are ready to provide your voucher",
  })

  const descriptionPlaceholderText = formatMessage({
    id: "dashboard::service::tellAbout",
    defaultMessage:
      "Tell customers more about your service. Provide details that will interest people in purchasing. A good pitch is compelling, informative, and easy to digest.",
  })

  const descriptionVoucherPlaceholderText = formatMessage({
    id: "dashboard::service::tellAboutVoucher",
    defaultMessage:
      "Tell customers more about your voucher. Provide details that will interest people in purchasing. A good pitch is compelling, informative, and easy to digest.",
  })

  const nameYourEventText = formatMessage({
    id: "dashboard::offerForm::nameYourEvent",
    defaultMessage: "Name your event",
  })

  const regionEventExplanationText = formatMessage({
    id: "dashboard::service::chooseRegionEventDescription",
    defaultMessage: "In what countries you are ready to provide your event",
  })

  const eventFinishDateCheckText = formatMessage({
    id: "dashboard::service::hasNoDeadline",
    defaultMessage: "This event won't have a finish date",
  })

  return (
    <Form className="service-form">
      <fieldset disabled={service?.status === REVIEW_STATUS.ENDED}>
        {/* {!isTablet && (
          <StyledLabel className="top-align subheader">
            <FormattedMessage
              id={`dashboard::service::service`}
              defaultMessage={`Service`}
              tagName="span"
            />
          </StyledLabel>
        )} */}

        <StyledLabel className="top-align">
          {isEventOffer
            ? nameYourEventText
            : isVoucher
            ? nameYourVoucherText
            : nameYourServiceText}
          {REQUIRED_FIELD_SYMBOL}
        </StyledLabel>
        <TextInput
          newDesign
          data-testid="input-name-service"
          id={`name`}
          name={`name`}
          value={values.name}
          onChange={handleChange}
          placeholder={
            isEventOffer
              ? nameYourEventText
              : isVoucher
              ? nameYourVoucherText
              : nameYourServiceText
          }
          onBlur={handleBlur}
          error={errors.name}
          touched={touched.name}
          autoComplete="off"
        />
        <div>
          <StyledLabel className="top-align">
            <FormattedMessage
              id="dashboard::service::chooseCategory"
              defaultMessage="Choose category"
            />
            {REQUIRED_FIELD_SYMBOL}
          </StyledLabel>
        </div>
        <div data-testid="input-categories">
          <GroupedCategorySubcategorySelect
            placeholder={formatMessage({
              id: "dashboard::service::chooseCategory",
              defaultMessage: "Choose category",
            })}
            onSelect={handleCategorySelect}
            selected={values?.categories}
            selectedCategoryItems={values?.categoryItems}
            hideLine={true}
          />
          <FieldError name="categories" />
        </div>
        <>
          <div>
            <StyledLabel className="top-align">
              <FormattedMessage
                id="dashboard::service::chooseRegion"
                defaultMessage="Choose Region"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledLabel className="subtext">
              {isEventOffer
                ? regionEventExplanationText
                : isVoucher
                ? regionVoucherExplanationText
                : regionServiceExplanationText}
            </StyledLabel>
          </div>
          <div>
            <StyledFlexRow>
              <CountrySelectorFilter
                isOfferList={true}
                handleSelect={handleCountrySelect}
                isDropdown={false}
                searchable={true}
                inlineSearchableComponent={true}
                activeCity={null}
                activeCountry={null}
                dontShowSelected={true}
                withTagsBelow={true}
                cities={values?.availableCities}
                countries={values?.availableCountries}
                disabled={values.isWorldwide}
              />
            </StyledFlexRow>
            <div style={{ marginTop: "0.5rem" }}>
              <Checkbox
                label={formatMessage({
                  id: "dashboard::service::isWorldwide",
                  defaultMessage: "Worldwide",
                })}
                id="isWorldwide"
                isChecked={values.isWorldwide}
                onChange={() => {
                  const temp = values.isWorldwide
                  setValues({
                    ...values,
                    availableCountries: [],
                    availableCities: [],
                    isWorldwide: !temp,
                  })
                }}
              />
            </div>
            <FieldError name="country" />
          </div>
          <div>
            <StyledLabel className="top-align">
              {serviceDatesText}
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledLabel className="subtext">
              {formatMessage({
                id: "dashboard::service::expiryDateDesc",
                defaultMessage:
                  "Specify the time period during which your service/event will be available for sale.",
              })}
            </StyledLabel>
          </div>
          <div>
            <StyledFlexRow>
              <DatePicker
                showRange={!values.hasNoDeadline}
                minDate={new Date()}
                dataTestId="service-date-picker"
                selected={values?.startDate}
                startDate={values?.startDate}
                endDate={values?.hasNoDeadline ? undefined : values?.endDate}
                onDateChange={(date) => {
                  values.hasNoDeadline
                    ? setFieldValue("startDate", date, true)
                    : setValues({
                        ...values,
                        startDate: date[0],
                        endDate: values?.hasNoDeadline ? undefined : date[1],
                      })
                }}
                placeholderText={
                  values?.hasNoDeadline
                    ? formatMessage({
                        id: "dashboard::service::startDate",
                        defaultMessage: "Start Date",
                      })
                    : formatMessage({
                        id: "dashboard::service::startEndDate",
                        defaultMessage: "Start - End Date",
                      })
                }
              />
            </StyledFlexRow>

            <Checkbox
              className={!isTablet ? "mt-small" : "mt-large"}
              label={
                isEventOffer
                  ? eventFinishDateCheckText
                  : isVoucher
                  ? voucherFinishDateCheckText
                  : serviceFinishDateCheckText
              }
              id="hasNoDeadline"
              isChecked={values.hasNoDeadline}
              onChange={() => {
                setValues({
                  ...values,
                  hasNoDeadline: !values.hasNoDeadline,
                })
              }}
            />
          </div>
        </>
        {isEventOffer ? (
          <>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::eventDate"
                defaultMessage="Event date"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledFlexRow>
              <DatePicker
                minDate={
                  compareAsc(values?.startsAt, new Date()) === 1
                    ? values.startsAt
                    : new Date()
                }
                maxDate={
                  addDate(values.startsAt, {
                    days: 60,
                  }) || ""
                }
                dataTestId="campaign-event-date-picker"
                selected={values.eventStartsAt}
                onDateChange={(date) => {
                  setFieldValue("eventStartsAt", date, false)
                }}
                error={errors.eventStartsAt}
                touched={touched.eventStartsAt}
              />
            </StyledFlexRow>
            <StyledLabel>
              <FormattedMessage
                id="dashboard::campaign::eventStarts"
                defaultMessage="Event starts"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledFlexRow>
              <DatePicker
                dataTestId="campaign-event-time-picker"
                selected={values.eventStartsAt}
                onDateChange={(date) => {
                  setFieldValue("eventStartsAt", date, false)
                  const startsAt = values?.startsAt
                  const eventStarts = date
                  startsAt?.setHours(eventStarts?.getHours())
                  startsAt?.setMinutes(eventStarts?.getMinutes())
                  startsAt?.setSeconds(eventStarts?.getSeconds())
                  setFieldValue("startsAt", startsAt)
                }}
                showTimeSelect
                showTimeSelectOnly
                error={errors.eventStartsAt}
                touched={touched.eventStartsAt}
              />
            </StyledFlexRow>
            <>
              <StyledLabel className="top-align mt-none subheader">
                <FormattedMessage
                  id="dashboard::campaign::typeOfEvent"
                  defaultMessage="Type of the event"
                />
                {REQUIRED_FIELD_SYMBOL}
              </StyledLabel>
              <RadioSelect
                className="type-radio"
                items={eventType}
                defaultValue={values.isVirtualEvent ? "virtual" : "real"}
                onChange={(e) => {
                  setFieldValue(
                    "isVirtualEvent",
                    e.target.value === "virtual" ? true : false
                  )
                  setFieldValue("virtualEventLink", "")
                  setFieldValue("eventMapLink", "")
                }}
              />
            </>

            {values.isVirtualEvent ? (
              <>
                <div>
                  <StyledLabel className="top-align mt-none subheader">
                    <FormattedMessage
                      id="dashboard::campaign::broadcastLink"
                      defaultMessage="Broadcast link"
                    />
                    {REQUIRED_FIELD_SYMBOL}
                  </StyledLabel>
                  <StyledLabel className="subtext">
                    <FormattedMessage
                      id="service::event::linkWarning"
                      defaultMessage="Please copy and paste the broadcast link, starting with http or https, from the URL address bar"
                    />
                  </StyledLabel>
                </div>

                <TextInput
                  newDesign
                  data-testid="virtualEventLink"
                  id="virtualEventLink"
                  name="virtualEventLink"
                  value={values.virtualEventLink}
                  onChange={(e) => {
                    const lowerCaseLink = e.target.value.toLowerCase()
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: lowerCaseLink,
                      },
                    })
                  }}
                  placeholder={
                    isTablet
                      ? formatMessage({
                          id: "dashboard::campaign::addVirtualEventLink",
                          defaultMessage:
                            "Paste broadcast link to your virtual event",
                        })
                      : formatMessage({
                          id: "dashboard::campaign::addVirtualEventLinkShort",
                          defaultMessage: "Paste link here",
                        })
                  }
                  onBlur={handleBlur}
                  error={errors.virtualEventLink}
                  touched={touched.virtualEventLink}
                />
              </>
            ) : isEventOffer ? (
              <>
                <span />
                <ContactForm
                  className="mt-0"
                  addressLine1={values?.addressLine1}
                  country={values?.country}
                  postCode={values?.postCode}
                  saveAddress={saveAddress}
                />
              </>
            ) : (
              <>
                <div>
                  <StyledLabel className="top-align mt-none subheader">
                    <FormattedMessage
                      id="dashboard::campaign::eventAddress"
                      defaultMessage="Address of the event"
                    />
                    {REQUIRED_FIELD_SYMBOL}
                  </StyledLabel>
                  <StyledLabelHref
                    className="subtext maps"
                    href="https://help.trustmeup.com/it/articles/10546646-come-creare-e-copiare-il-link-di-google-maps"
                    target="_blank">
                    <FormattedMessage
                      id="service::event::howtoGet"
                      defaultMessage="How to get a shareable Google Maps link"
                    />
                  </StyledLabelHref>
                </div>

                <div>
                  <StyledAddressLinkRow
                    error={errors.eventMapLink && touched.eventMapLink}>
                    <TextInput
                      newDesign
                      data-testid="addressLink"
                      id="eventMapLink"
                      name="eventMapLink"
                      value={values.eventMapLink}
                      onChange={handleChange}
                      className="mt-large"
                      placeholder={formatMessage({
                        id: "dashboard::campaign::addressLink",
                        defaultMessage: "Copy and paste Google Maps link",
                      })}
                      onBlur={handleBlur}
                      error={errors.eventMapLink}
                      touched={touched.eventMapLink}
                    />
                  </StyledAddressLinkRow>
                </div>
              </>
            )}
          </>
        ) : null}
        <div>
          <StyledLabel className="top-align mt-none subheader">
            <FormattedMessage
              id="dashboard::service::uploadPhoto"
              defaultMessage="Upload a photo"
            />
            {REQUIRED_FIELD_SYMBOL}
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::service::uploadPhotoDescription"
              defaultMessage="Upload images that represent your service. 1200x675px is the recommended resolution, which has an aspect ratio of 16/9."
            />
          </StyledLabel>
        </div>
        <div>
          <ImageSelector
            dashed
            defaultValue={values?.image}
            onChange={(image) => setFieldValue("image", image, true)}
            onCropChange={(croppedImage) =>
              setFieldValue("image", croppedImage, true)
            }
            onReset={() =>
              setFieldValue("image", initialValues?.image || "", true)
            }
          />
          <FieldError name="image" />
        </div>
        <div>
          <StyledLabel className="top-align  mt-none subheader">
            {serviceDescriptionText}

            {REQUIRED_FIELD_SYMBOL}
          </StyledLabel>
          <StyledLabel className="subtext">
            {isVoucher
              ? descriptionVoucherExplanationText
              : descriptionServiceExplanationText}
          </StyledLabel>
        </div>
        <div>
          <TextEditor
            newDesign
            data-testid={`input-description${localeSuffix}`}
            id={`description${localeSuffix}`}
            name={`description${localeSuffix}`}
            type="textarea"
            maxLength={MAX_TEXT_LENGTH}
            value={values[`description${localeSuffix}`]}
            onChange={(value) =>
              setFieldValue(`description${localeSuffix}`, value)
            }
            placeholder={
              isVoucher
                ? descriptionVoucherPlaceholderText
                : descriptionPlaceholderText
            }
            className={
              !!errors[`description${localeSuffix}`] &&
              !!touched[`description${localeSuffix}`]
                ? "text-editor-error"
                : "unset"
            }
            onBlur={() => handleBlur(`description${localeSuffix}`)}
            error={errors[`description${localeSuffix}`]}
            touched={touched[`description${localeSuffix}`]}
          />
          <FieldError
            name={`description${localeSuffix}`}
            autoFocus={true}
            scrollOpts={{ block: "end", behavior: "smooth" }}
            error={errors}
            touched={touched}
          />
        </div>
        <div>
          <StyledLabel className="top-align">
            {confirmationCodeText}
          </StyledLabel>
          {service?.id && (
            <StyledLabel className="subtext">
              <FormattedMessage
                id="dashboard::service::confirmationCodeDescription"
                defaultMessage="You cannot change the confirmation code status of an existing service. If you wish to make changes, please create a new one instead"
              />
            </StyledLabel>
          )}
        </div>
        <div disabled={service?.id}>
          <VoucherCodeSelector
            disabled={service?.id}
            onChange={(val) => {
              setFieldValue("isVoucher", val, true)
              setIsVoucher(val)
            }}
            isVoucher={values?.isVoucher}
          />
        </div>
        <StyledLabel className="top-align">
          <FormattedMessage
            id="dashboard::offerForm::reservation"
            defaultMessage="Reservation"
          />
        </StyledLabel>
        <div>
          <Checkbox
            className="check"
            label={formatMessage({
              id: "dashboard::offerForm::reservationExplanation",
              defaultMessage: "After the purchase, reservation is required",
            })}
            isChecked={values?.isVoucher && values?.isReservationRequired}
            onChange={() => {
              const newVal = !values?.isReservationRequired
              setFieldValue("isReservationRequired", newVal, true)

              if (newVal) {
                setFieldValue("showAddressAndPhone", true, true)
              }
            }}
            disabled={!values?.isVoucher}
          />
        </div>
        <div>
          <StyledLabel className="top-align">
            <FormattedMessage
              id="dashboard::settings::favouriteCharity"
              defaultMessage="Favorite Charity"
            />
            {REQUIRED_FIELD_SYMBOL}
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::settings::favouriteCharityExplanation2"
              defaultMessage="Make charity your favourite, we will remember your choice and it will be used for donations by shopping as well as orders"
            />
          </StyledLabel>
          <StyledFavCharityWrapper>
            <div
              className="change"
              onClick={() => setFavoriteCharityFormVisible(true)}>
              {formatMessage({
                id: "dashboard::settings::change",
                defaultMessage: "Change",
              })}
            </div>
          </StyledFavCharityWrapper>
        </div>
        <div>
          {values?.favoriteCharity ? (
            <FavoriteCharityProfileForm
              partnerLogo={values?.favoriteCharity?.partner?.logo}
              isEdit={isFavoriteCharityFormVisible}
              hideTitle
              tempFavoriteCharityId={
                values?.favoriteCharity?.id || values?.favoriteCharity?.value
              }
              tempFavoriteCharity={
                values?.favoriteCharity || values?.favoriteCharity?.charity
              }
              onFavChange={(charityId, charity) => {
                setFieldValue(
                  "favoriteCharity",
                  charity || values?.favoriteCharity
                )
              }}
              onToggleChange={() => {
                setFieldValue(
                  "allowUsersToSelectADifferentCampaign",
                  !values?.allowUsersToSelectADifferentCampaign
                )
              }}
            />
          ) : null}
        </div>
        {isEventOffer ? null : (
          <>
            <StyledLabel className="top-align mt-none hide-on-mobile">
              <FormattedMessage
                id="dashboard::service::contacts"
                defaultMessage="Contacts"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <StyledContactSection>
              <Checkbox
                label={formatMessage({
                  id: "dashboard::service::showAddress",
                  defaultMessage:
                    "Show my address and phone number on the service page",
                })}
                id="showAddressAndPhone"
                isChecked={values.showAddressAndPhone}
                onChange={() => {
                  setValues({
                    ...values,
                    showAddressAndPhone: !values.showAddressAndPhone,
                  })
                }}
                disabled={values?.isReservationRequired}
              />

              {isContactFormVisible ? (
                <>
                  <ContactForm
                    setContactFormVisible={setContactFormVisible}
                    saveAddress={saveAddress}
                  />
                </>
              ) : (
                <>
                  <StyledPhoneAndAddress>
                    <FontAwesomeIcon icon={faPhone} />
                    <span>
                      {getFormatPhoneNumber(
                        values?.phoneNumber || defaultMerchant?.phoneNumber
                      )}
                    </span>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <p>
                      {values?.addressLine1 || defaultMerchant?.addressLine1}
                    </p>
                  </StyledPhoneAndAddress>
                  <Button
                    label="address"
                    variant="text"
                    className="btn-change-address"
                    onClick={() => setContactFormVisible(true)}>
                    <FormattedMessage
                      id="dashboard::service::changeAddress"
                      defaultMessage="Enter different address"
                    />
                  </Button>
                </>
              )}
            </StyledContactSection>
          </>
        )}
        {/* <>
          <StyledLabel className="top-align subheader">
            <FormattedMessage
              id="dashboard::service::pacDiscount"
              defaultMessage="PAC Discount"
            />
            {REQUIRED_FIELD_SYMBOL}
          </StyledLabel>
          <StyledPacDiscount>
            <TextInput
              newDesign
              className="discount-input"
              type="number"
              id="maxPacDiscount"
              name="maxPacDiscount"
              value={values.maxPacDiscount}
              error={errors.maxPacDiscount}
              touched={touched.maxPacDiscount}
              onBlur={() => setFieldTouched("maxPacDiscount")}
              onChange={handleChange}
            />
          </StyledPacDiscount>
        </> */}

        <FieldArray name="productVariants">
          {({ push, remove }) => (
            <>
              {values?.productVariants?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <StyledLabel className="top-align subheader">
                      {isEventOffer
                        ? eventOptionText
                        : isVoucher
                        ? voucherOptionText
                        : serviceOptionText}
                      {values?.productVariants?.length > 1
                        ? ` ${index + 1}`
                        : null}
                      {REQUIRED_FIELD_SYMBOL}
                    </StyledLabel>
                    <StyledInlineRow>
                      <div>
                        <StyledLabel className="hide-on-desktop">
                          <FormattedMessage
                            id={`dashboard::service::${"donationOption"}`}
                            defaultMessage={`${"Donation option"}`}
                          />
                        </StyledLabel>
                        <TextInput
                          newDesign
                          type="currency"
                          data-testid={`productVariants[${index}].price`}
                          id={`productVariants[${index}].price`}
                          name={`productVariants[${index}].price`}
                          className="currency-icon"
                          value={values.productVariants?.[index]?.price}
                          error={errors?.productVariants?.[index]?.price}
                          touched={touched?.productVariants?.[index]?.price}
                          maxLength={8}
                          onBlur={() =>
                            setFieldTouched(`productVariants[${index}].price`)
                          }
                          onValueChange={({ value }) => {
                            setFieldValue(
                              `productVariants[${index}].price`,
                              value ? Number(value) : "",
                              false
                            )
                          }}
                        />
                      </div>

                      <>
                        <span />
                        <TextInput
                          newDesign
                          data-testid={`productVariants[${index}][name${localeSuffix}]`}
                          id={`productVariants[${index}][name${localeSuffix}]`}
                          name={`productVariants[${index}][name${localeSuffix}]`}
                          maxLength={64}
                          value={
                            values.productVariants?.[index]?.[
                              `name${localeSuffix}`
                            ]
                          }
                          error={
                            errors?.productVariants?.[index]?.[
                              `name${localeSuffix}`
                            ]
                          }
                          touched={true}
                          placeholder={formatMessage({
                            id: `dashboard::service::optionName`,
                            defaultMessage: `Option Name`,
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <TextInput
                          newDesign
                          className="full-width"
                          data-testid={`productVariants[${index}][description${localeSuffix}]`}
                          id={`productVariants[${index}][description${localeSuffix}]`}
                          name={`productVariants[${index}][description${localeSuffix}]`}
                          value={
                            values.productVariants?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                          type="textarea"
                          maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={formatMessage({
                            id: `dashboard::service::specifyOption`,
                            defaultMessage: `Specify what this option offers`,
                          })}
                          error={
                            errors?.productVariants?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                          touched={
                            touched?.productVariants?.[index]?.[
                              `description${localeSuffix}`
                            ]
                          }
                        />

                        <StyledPacDiscount>
                          <TextInput
                            newDesign
                            className="discount-input"
                            data-testid={`productVariants[${index}][pacDiscount]`}
                            id={`productVariants[${index}][pacDiscount]`}
                            name={`productVariants[${index}][pacDiscount]`}
                            value={
                              values.productVariants?.[index]?.[`pacDiscount`]
                            }
                            type="number"
                            maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={formatMessage({
                              id: `dashboard::service::pac`,
                              defaultMessage: `PAC`,
                            })}
                            error={
                              errors?.productVariants?.[index]?.[`pacDiscount`]
                            }
                            touched={
                              touched?.productVariants?.[index]?.[
                                `pacDiscount`
                              ] || !!submitCount
                            }
                          />
                        </StyledPacDiscount>
                        <span />

                        <div>
                          <TextInput
                            newDesign
                            data-testid={`productVariants[${index}][maxTickets]`}
                            id={`productVariants[${index}][maxTickets]`}
                            name={`productVariants[${index}][maxTickets]`}
                            value={
                              values.productVariants?.[index]?.[`maxTickets`]
                            }
                            disabled={
                              values.productVariants?.[index]
                                ?.isUnlimitedTickets
                            }
                            maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                            type="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={formatMessage({
                              id: `dashboard::service::amount`,
                              defaultMessage: `Quantity`,
                            })}
                            error={
                              errors?.productVariants?.[index]?.[`maxTickets`]
                            }
                            touched={
                              touched?.productVariants?.[index]?.[
                                `maxTickets`
                              ] || !!submitCount
                            }
                          />
                        </div>

                        <div
                          style={{
                            gridColumn: "1/3",
                            marginBottom: "-0.75rem",
                          }}>
                          <Checkbox
                            label={formatMessage({
                              id: "dashboard::service::limitAvailableAmount",
                              defaultMessage: "Limit available amount",
                            })}
                            isChecked={
                              !values.productVariants?.[index]
                                ?.isUnlimitedTickets
                            }
                            onChange={() => {
                              setFieldValue(`productVariants[${index}]`, {
                                ...values.productVariants[index],
                                isUnlimitedTickets:
                                  !values.productVariants?.[index]
                                    ?.isUnlimitedTickets,
                              })
                            }}
                          />
                        </div>

                        <TextEditor
                          newDesign
                          className="full-width"
                          data-testid={`productVariants[${index}][shortDescription${localeSuffix}]`}
                          id={`productVariants[${index}][shortDescription${localeSuffix}]`}
                          name={`productVariants[${index}][shortDescription${localeSuffix}]`}
                          value={
                            values?.productVariants?.[index]?.[
                              `shortDescription${localeSuffix}`
                            ]
                          }
                          type="textarea"
                          maxLength={MAX_TEXT_LENGTH}
                          onChange={(value) =>
                            setFieldValue(
                              `productVariants[${index}][shortDescription${localeSuffix}]`,
                              value
                            )
                          }
                          onBlur={() =>
                            handleBlur(
                              `productVariants[${index}][shortDescription${localeSuffix}]`
                            )
                          }
                          placeholder={formatMessage({
                            id: `dashboard::service::buyerMessageDescription`,
                            defaultMessage: `Your message for the buyers. Tailor the message customers receive by email after the purchase, could be a link, code or just text of appreciation. If you have a reservation link, place it here.`,
                          })}
                          error={
                            errors?.productVariants?.[index]?.[
                              `shortDescription${localeSuffix}`
                            ]
                          }
                          touched={
                            touched?.productVariants?.[index]?.[
                              `shortDescription${localeSuffix}`
                            ]
                          }
                        />

                        <Button
                          data-testid="btn-delete-entry"
                          color="transparent"
                          variant="text"
                          className="btn-delete-entry"
                          type="button"
                          onClick={() => remove(index)}>
                          <FormattedMessage
                            id={`dashboard::service::deleteOption`}
                            defaultMessage={`Delete service option`}
                          />
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </>
                    </StyledInlineRow>
                  </Fragment>
                )
              })}
              {values?.productVariants?.length < MAX_TIERS ? (
                <>
                  <span />
                  <Button
                    data-testid="btn-addTicket"
                    color="transparent"
                    type="button"
                    onClick={() =>
                      push({
                        price:
                          (values?.productVariants?.length + 1) *
                          DEFAULT_MIN_TIER_AMOUNT,
                        [`description${localeSuffix}`]: "",
                        maxTickets: "",
                        isUnlimitedTickets: true,
                        allowCustomAmount: false,
                      })
                    }
                    style={{ margin: "0.2rem 0" }}>
                    <FontAwesomeIcon icon={faPlus} />
                    <FormattedMessage
                      id={`dashboard::service::addOption`}
                      defaultMessage={`Add another option`}
                    />
                  </Button>
                </>
              ) : null}
            </>
          )}
        </FieldArray>
        <div>
          <StyledLabel className="top-align  mt-none subheader">
            <FormattedMessage
              id="dashboard::service::addCustomPriceOption"
              defaultMessage="Add custom price option"
            />
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::service::addCustomPriceOptionDesc"
              defaultMessage="Choose this option to allow customers to enter a custom payment amount after reaching an agreement with you. The minimum amount is €10"
            />
          </StyledLabel>
        </div>
        <div>
          <StyledInlineRow2>
            <Checkbox
              className="check"
              label={formatMessage({
                id: "dashboard::service::addCustomPriceOption1",
                defaultMessage: "Add custom price option",
              })}
              isChecked={
                !values?.productVariants?.length ||
                !!values?.customAmountProductVariant
              }
              onChange={(e) => {
                setFieldValue(
                  "customAmountProductVariant",
                  !values?.customAmountProductVariant
                    ? {
                        price: DEFAULT_MIN_TIER_AMOUNT,
                        [`name${localeSuffix}`]: "",
                        [`description${localeSuffix}`]: "",
                        [`shortDescription${localeSuffix}`]: "",
                        maxTickets: "",
                        pacDiscount: "",
                        isUnlimitedTickets: true,
                        allowCustomAmount: true,
                      }
                    : null,
                  true
                )
              }}
            />
          </StyledInlineRow2>
          {!values?.productVariants?.length ||
          values?.customAmountProductVariant ? (
            <StyledInlineRow2>
              <div className="mt-16">
                <StyledPacDiscount>
                  <TextInput
                    newDesign
                    className="discount-input"
                    data-testid={`customAmountProductVariant[pacDiscount]`}
                    id={`customAmountProductVariant[pacDiscount]`}
                    name={`customAmountProductVariant[pacDiscount]`}
                    value={values?.customAmountProductVariant?.[`pacDiscount`]}
                    type="number"
                    maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={formatMessage({
                      id: `dashboard::service::pac`,
                      defaultMessage: `PAC`,
                    })}
                    error={errors?.customAmountProductVariant?.[`pacDiscount`]}
                    touched={
                      touched?.customAmountProductVariant?.[`pacDiscount`] ||
                      !!submitCount
                    }
                  />
                </StyledPacDiscount>
              </div>
              <div className="amount">
                <TextInput
                  newDesign
                  data-testid={`customAmountProductVariant[maxTickets]`}
                  id={`customAmountProductVariant[maxTickets]`}
                  name={`customAmountProductVariant[maxTickets]`}
                  type="number"
                  value={values.customAmountProductVariant?.[`maxTickets`]}
                  disabled={
                    values.customAmountProductVariant?.isUnlimitedTickets
                  }
                  maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={formatMessage({
                    id: `dashboard::service::amount`,
                    defaultMessage: `Quantity`,
                  })}
                  error={errors?.customAmountProductVariant?.[`maxTickets`]}
                  touched={
                    touched?.customAmountProductVariant?.[`maxTickets`] ||
                    !!submitCount
                  }
                />
              </div>

              <div
                style={{
                  gridColumn: "1/3",
                  marginBottom: "0rem",
                }}>
                <Checkbox
                  label={formatMessage({
                    id: "dashboard::service::limitAvailableAmount",
                    defaultMessage: "Limit available amount",
                  })}
                  isChecked={
                    !values.customAmountProductVariant?.isUnlimitedTickets
                  }
                  onChange={() => {
                    setFieldValue(`customAmountProductVariant`, {
                      ...values.customAmountProductVariant,
                      isUnlimitedTickets:
                        !values.customAmountProductVariant?.isUnlimitedTickets,
                    })
                  }}
                />
              </div>
              <TextEditor
                newDesign
                className="full-width"
                data-testid={`customAmountProductVariant[shortDescription${localeSuffix}]`}
                id={`customAmountProductVariant[shortDescription${localeSuffix}]`}
                name={`customAmountProductVariant[shortDescription${localeSuffix}]`}
                value={
                  values?.customAmountProductVariant?.[
                    `shortDescription${localeSuffix}`
                  ]
                }
                type="textarea"
                maxLength={MAX_TEXT_LENGTH}
                onChange={(value) =>
                  setFieldValue(
                    `customAmountProductVariant[shortDescription${localeSuffix}]`,
                    value
                  )
                }
                onBlur={() =>
                  handleBlur(
                    `customAmountProductVariant[shortDescription${localeSuffix}]`
                  )
                }
                placeholder={formatMessage({
                  id: `dashboard::service::buyerMessageDescription`,
                  defaultMessage: `Your message for the buyers. Tailor the message customers receive by email after the purchase, could be a link, code or just text of appreciation. If you have a reservation link, place it here.`,
                })}
                error={
                  errors?.customAmountProductVariant?.[
                    `shortDescription${localeSuffix}`
                  ]
                }
                touched={
                  touched?.customAmountProductVariant?.[
                    `shortDescription${localeSuffix}`
                  ]
                }
              />
            </StyledInlineRow2>
          ) : null}
        </div>
        <div>
          <StyledLabel className="top-align  mt-none subheader">
            <FormattedMessage
              id="dashboard::service::invoicing"
              defaultMessage="Invoicing"
            />
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::service::autoInvoicingDesc"
              defaultMessage="Turning off automatic invoicing lets customers decide at checkout if
            they want an invoice or not."
            />
          </StyledLabel>
        </div>
        <StyledInvoicingWrapper>
          <CommonToggle
            defaultChecked={values?.isInvoiceMandatory}
            handleChange={() => {
              const newVal = !values?.isInvoiceMandatory
              setFieldValue("isInvoiceMandatory", newVal)
            }}
          />
          <FormattedMessage
            id="offerListing::filters::autoInvoicing"
            defaultMessage="Automatic invoicing"
          />
        </StyledInvoicingWrapper>
        <div>
          <StyledLabel className="top-align  mt-none subheader">
            <FormattedMessage
              id="dashboard::campaign::attendees"
              defaultMessage="Attendees"
            />
          </StyledLabel>
          <StyledLabel className="subtext">
            <FormattedMessage
              id="dashboard::campaign::attendeesDescriptionEvent"
              defaultMessage="Enable this option if you want to collect the names of attendees during the donation. When activated, each donor will be required to provide the name of the attendee for each ticket"
            />
          </StyledLabel>
        </div>
        <StyledInvoicingWrapper>
          <CommonToggle
            defaultChecked={values?.isNeedTicketBuyerInfo}
            handleChange={() => {
              const newVal = !values?.isNeedTicketBuyerInfo
              setFieldValue("isNeedTicketBuyerInfo", newVal)
            }}
          />
          <FormattedMessage
            id="dashboard::campaign::requestAttendeesNames"
            defaultMessage="Request attendees names"
          />
        </StyledInvoicingWrapper>

        {values?.productVariants?.length < 2 && (
          <>
            <div>
              <StyledLabel className="top-align  mt-none subheader">
                <FormattedMessage
                  id="dashboard::service::shortDescription"
                  defaultMessage="Your message for the buyers"
                />
              </StyledLabel>
              <StyledLabel className="subtext">
                <FormattedMessage
                  id="dashboard::service::shortDescriptionSubtext"
                  defaultMessage="Tailor the message customers receive by email after the purchase, could be a link, code or just text of appreciation"
                />
              </StyledLabel>
            </div>

            <TextEditor
              newDesign
              data-testid={`input-shortDescription${localeSuffix}`}
              id={`shortDescription${localeSuffix}`}
              name={`shortDescription${localeSuffix}`}
              type="textarea"
              value={values?.[`shortDescription${localeSuffix}`]}
              onChange={(value) =>
                setFieldValue(`shortDescription${localeSuffix}`, value)
              }
              onBlur={() => handleBlur(`shortDescription${localeSuffix}`)}
              placeholder={formatMessage({
                id: "dashboard::service::tellShortDescription",
                defaultMessage: "Insert your message",
              })}
              error={errors[`shortDescription${localeSuffix}`]}
              touched={touched[`shortDescription${localeSuffix}`]}
            />
          </>
        )}
        {locale !== "en" ? (
          <>
            {isEnglishSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/svg/united-kingdom.svg"
                  }
                  alt="UK Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel>
                  {isEventOffer
                    ? "Name your event"
                    : isVoucher
                    ? "Name your voucher"
                    : "Name your service"}
                </StyledLabel>
                <TextInput
                  newDesign
                  data-testid="input-name-service"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Name your service"
                  onBlur={handleBlur}
                  error={errors.name}
                  touched={touched.name}
                />
                <StyledLabel className="top-align">
                  {isEventOffer
                    ? "Tell about your event"
                    : isVoucher
                    ? "Tell about your voucher"
                    : "Tell about your service"}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid="input-video"
                  id="descriptionEn"
                  name="descriptionEn"
                  type="textarea"
                  langOverride="en"
                  value={values.descriptionEn}
                  onChange={(value) => setFieldValue(`descriptionEn`, value)}
                  onBlur={() => handleBlur(`descriptionEn`)}
                  maxLength={MAX_TEXT_LENGTH}
                  placeholder="Tell potential donors more about your service. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest."
                  error={errors.descriptionEn}
                  touched={touched.descriptionEn}
                />
                {values?.productVariants?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <StyledLabel className="top-align">
                        Service option name{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].nameEn`}
                        id={`productVariants[${index}].nameEn`}
                        name={`productVariants[${index}].nameEn`}
                        value={values.productVariants?.[index]?.nameEn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"What this contribution is going to help"}`}
                        error={errors?.productVariants?.[index]?.nameEn}
                        touched={touched?.productVariants?.[index]?.nameEn}
                      />
                      <StyledLabel className="top-align">
                        {`${"Donation option description"}`}{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].descriptionEn`}
                        id={`productVariants[${index}].descriptionEn`}
                        name={`productVariants[${index}].descriptionEn`}
                        type="textarea"
                        maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                        value={values.productVariants?.[index]?.descriptionEn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"What this contribution is going to help"}`}
                        error={errors?.productVariants?.[index]?.descriptionEn}
                        touched={
                          touched?.productVariants?.[index]?.descriptionEn
                        }
                      />
                      <StyledLabel className="top-align">
                        {`${"Buyer Message"}`}
                        {values?.productVariants?.length > 1 ? index + 1 : null}
                      </StyledLabel>
                      <TextEditor
                        newDesign
                        data-testid={`productVariants[${index}].shortDescriptionEn`}
                        id={`productVariants[${index}].shortDescriptionEn`}
                        name={`productVariants[${index}].shortDescriptionEn`}
                        type="textarea"
                        maxLength={ENTRY_DONATION_MAX_TEXT_LENGTH}
                        value={
                          values?.productVariants?.[index]?.shortDescriptionEn
                        }
                        onChange={(value) =>
                          setFieldValue(
                            `productVariants[${index}].shortDescriptionEn`,
                            value
                          )
                        }
                        onBlur={() =>
                          handleBlur(
                            `productVariants[${index}].shortDescriptionEn`
                          )
                        }
                        placeholder={`${"What this contribution is going to help"}`}
                        error={
                          errors?.productVariants?.[index]?.shortDescriptionEn
                        }
                        touched={
                          touched?.productVariants?.[index]?.shortDescriptionEn
                        }
                      />
                    </Fragment>
                  )
                })}
                <StyledLabel className="top-align">
                  {`${"Buyer Message"}`}{" "}
                  {values?.productVariants?.length > 1
                    ? values?.productVariants?.length + 1
                    : null}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid={`customAmountProductVariant.shortDescriptionEn`}
                  id={`customAmountProductVariant.shortDescriptionEn`}
                  name={`customAmountProductVariant.shortDescriptionEn`}
                  type="textarea"
                  value={values?.customAmountProductVariant?.shortDescriptionEn}
                  onChange={(value) =>
                    setFieldValue(
                      `customAmountProductVariant.shortDescriptionEn`,
                      value
                    )
                  }
                  onBlur={() =>
                    handleBlur(`customAmountProductVariant.shortDescriptionEn`)
                  }
                  placeholder={`${"Messaggio dell'acquirente"}`}
                  error={errors?.customAmountProductVariant?.shortDescriptionEn}
                  touched={
                    touched?.customAmountProductVariant?.shortDescriptionEn
                  }
                />
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-english"
              color="transparent"
              onClick={handleEnglishSectionChange}
              type="button">
              <FontAwesomeIcon icon={isEnglishSectionOpen ? faMinus : faPlus} />
              {`${isEnglishSectionOpen ? "Delete" : "Add"} the English version`}
            </Button>
          </>
        ) : null}

        {locale !== "it" ? (
          <>
            {isItalianSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/italy.svg"
                  }
                  alt="Italy Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel className="top-align">
                  {`Racconta la tua campagna`}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid="input-video"
                  id="descriptionIt"
                  langOverride="it"
                  name="descriptionIt"
                  type="textarea"
                  value={values.descriptionIt}
                  onChange={(value) => setFieldValue(`descriptionIt`, value)}
                  onBlur={() => handleBlur(`descriptionIt`)}
                  maxLength={MAX_TEXT_LENGTH}
                  placeholder="Dì ai potenziali donatori di più sulla tua campagna. Fornisci dettagli che motiveranno le persone a contribuire. Una buona presentazione è avvincente, informativa e facile da digerire"
                  error={errors.descriptionIt}
                  touched={touched.descriptionIt}
                />
                {values?.productVariants?.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <StyledLabel className="top-align">
                        Un nome di opzione{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}{" "}
                        del servizio
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].nameIt`}
                        id={`productVariants[${index}].nameIt`}
                        name={`productVariants[${index}].nameIt`}
                        value={values.productVariants?.[index]?.nameIt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"Che cosa aiuterà questo contributo"}`}
                        error={errors?.productVariants?.[index]?.nameIt}
                        touched={touched?.productVariants?.[index]?.nameIt}
                      />
                      <StyledLabel className="top-align">
                        Discrizione del opzione{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}{" "}
                        del servizio
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].descriptionIt`}
                        id={`productVariants[${index}].descriptionIt`}
                        name={`productVariants[${index}].descriptionIt`}
                        type="textarea"
                        value={values.productVariants?.[index]?.descriptionIt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"Che cosa aiuterà questo contributo"}`}
                        error={errors?.productVariants?.[index]?.descriptionIt}
                        touched={
                          touched?.productVariants?.[index]?.descriptionIt
                        }
                      />
                      <StyledLabel className="top-align">
                        Messaggio dell'acquirente{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}{" "}
                      </StyledLabel>
                      <TextEditor
                        newDesign
                        data-testid={`productVariants[${index}].shortDescriptionIt`}
                        id={`productVariants[${index}].shortDescriptionIt`}
                        name={`productVariants[${index}].shortDescriptionIt`}
                        type="textarea"
                        value={
                          values?.productVariants?.[index]?.shortDescriptionIt
                        }
                        onChange={(value) =>
                          setFieldValue(
                            `productVariants[${index}].shortDescriptionIt`,
                            value
                          )
                        }
                        onBlur={() =>
                          handleBlur(
                            `productVariants[${index}].shortDescriptionIt`
                          )
                        }
                        placeholder={`${"Messaggio dell'acquirente"}`}
                        error={
                          errors?.productVariants?.[index]?.shortDescriptionIt
                        }
                        touched={
                          touched?.productVariants?.[index]?.shortDescriptionIt
                        }
                      />
                    </Fragment>
                  )
                })}
                <StyledLabel className="top-align">
                  Messaggio dell'acquirente{" "}
                  {values?.productVariants?.length > 1
                    ? values?.productVariants?.length + 1
                    : null}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid={`customAmountProductVariant.shortDescriptionIt`}
                  id={`customAmountProductVariant.shortDescriptionIt`}
                  name={`customAmountProductVariant.shortDescriptionIt`}
                  type="textarea"
                  value={values?.customAmountProductVariant?.shortDescriptionIt}
                  onChange={(value) =>
                    setFieldValue(
                      `customAmountProductVariant.shortDescriptionIt`,
                      value
                    )
                  }
                  onBlur={() =>
                    handleBlur(`customAmountProductVariant.shortDescriptionIt`)
                  }
                  placeholder={`${"Messaggio dell'acquirente"}`}
                  error={errors?.customAmountProductVariant?.shortDescriptionIt}
                  touched={
                    touched?.customAmountProductVariant?.shortDescriptionIt
                  }
                />
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-italian"
              color="transparent"
              onClick={handleItalianSectionChange}
              type="button"
              style={{ margin: "0.2rem 0" }}>
              <FontAwesomeIcon icon={isItalianSectionOpen ? faMinus : faPlus} />
              {`${
                isItalianSectionOpen ? "Elimina" : "Aggiungere"
              } la versione italiana`}
            </Button>
          </>
        ) : null}
        {locale !== "es" ? (
          <>
            {isSpanishSectionOpen ? (
              <>
                <hr />
                <img
                  src={
                    CLOUDFLARE_IMAGE_URL + "/static/assets/images/svg/spain.svg"
                  }
                  alt="Spain Flag"
                  width="20"
                  height="20"
                  className="second-grid"
                />
                <StyledLabel className="top-align">
                  {`Cuéntanos sobre tu campaña`}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid="textarea-tell-about-es"
                  langOverride="es"
                  id="descriptionEs"
                  name="descriptionEs"
                  type="textarea"
                  value={values.descriptionEs}
                  onChange={(value) => setFieldValue(`descriptionEs`, value)}
                  onBlur={() => handleBlur(`descriptionEs`)}
                  maxLength={MAX_TEXT_LENGTH}
                  placeholder="Cuéntales a los posibles donantes más sobre tu campaña. Proporcione detalles que motiven a las personas a contribuir. Un buen discurso es convincente, informativo y fácil de digerir."
                  error={errors.descriptionEs}
                  touched={touched.descriptionEs}
                />
                {values?.productVariants?.map((item, index) => {
                  return (
                    <>
                      <StyledLabel className="top-align">
                        Un nombre de opción de servicio{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}{" "}
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].nameEs`}
                        id={`productVariants[${index}].nameEs`}
                        name={`productVariants[${index}].nameEs`}
                        value={values.productVariants?.[index]?.nameEs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"Che cosa aiuterà questo contributo"}`}
                        error={errors?.productVariants?.[index]?.nameEs}
                        touched={touched?.productVariants?.[index]?.nameEs}
                      />
                      <StyledLabel className="top-align" key={index}>
                        Descripción de la opción de servicio{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}
                      </StyledLabel>
                      <TextInput
                        newDesign
                        data-testid={`productVariants[${index}].descriptionEs`}
                        id={`productVariants[${index}].descriptionEs`}
                        name={`productVariants[${index}].descriptionEs`}
                        type="textarea"
                        value={values.productVariants?.[index]?.descriptionEs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`${"En qué va a ayudar esta contribución"}`}
                        error={errors?.productVariants?.[index]?.descriptionEs}
                        touched={
                          touched?.productVariants?.[index]?.descriptionEs
                        }
                      />
                      <StyledLabel className="top-align" key={index}>
                        Mensaje del comprador{" "}
                        {values?.productVariants?.length > 1 ? index + 1 : null}
                      </StyledLabel>
                      <TextEditor
                        newDesign
                        data-testid={`productVariants[${index}].shortDescriptionEs`}
                        id={`productVariants[${index}].shortDescriptionEs`}
                        name={`productVariants[${index}].shortDescriptionEs`}
                        type="textarea"
                        value={
                          values?.productVariants?.[index]?.shortDescriptionEs
                        }
                        onChange={(value) =>
                          setFieldValue(
                            `productVariants[${index}].shortDescriptionEs`,
                            value
                          )
                        }
                        onBlur={() =>
                          handleBlur(
                            `productVariants[${index}].shortDescriptionEs`
                          )
                        }
                        placeholder={`${"Mensaje del comprador"}`}
                        error={
                          errors?.productVariants?.[index]?.shortDescriptionEs
                        }
                        touched={
                          touched?.productVariants?.[index]?.shortDescriptionEs
                        }
                      />
                    </>
                  )
                })}
                <StyledLabel className="top-align">
                  Mensaje del comprador{" "}
                  {values?.productVariants?.length > 1
                    ? values?.productVariants?.length + 1
                    : null}
                </StyledLabel>
                <TextEditor
                  newDesign
                  data-testid={`customAmountProductVariant.shortDescriptionEs`}
                  id={`customAmountProductVariant.shortDescriptionEs`}
                  name={`customAmountProductVariant.shortDescriptionEs`}
                  type="textarea"
                  value={values?.customAmountProductVariant?.shortDescriptionEs}
                  onChange={(value) =>
                    setFieldValue(
                      `customAmountProductVariant.shortDescriptionEs`,
                      value
                    )
                  }
                  onBlur={() =>
                    handleBlur(`customAmountProductVariant.shortDescriptionEs`)
                  }
                  placeholder={`${"Messaggio dell'acquirente"}`}
                  error={errors?.customAmountProductVariant?.shortDescriptionEs}
                  touched={
                    touched?.customAmountProductVariant?.shortDescriptionEs
                  }
                />
              </>
            ) : null}
            <Button
              className="second-grid"
              data-testid="btn-add-spanish"
              color="transparent"
              onClick={handleSpanishSectionChange}
              type="button"
              style={{ margin: "0.2rem 0" }}>
              <FontAwesomeIcon icon={isSpanishSectionOpen ? faMinus : faPlus} />
              {isSpanishSectionOpen
                ? "Borrar opción de ticket"
                : "Añadi̇r la versi̇ón española"}
            </Button>
            {isSpanishSectionOpen ? <hr /> : null}
          </>
        ) : null}

        {service?.status !== REVIEW_STATUS.ENDED && (
          <>
            <StyledLabel className="top-align  mt-none subheader">
              <FormattedMessage
                id="dashboard::service::conditions"
                defaultMessage="Conditions"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <TermsAndConditions
              labelClassName="terms-label"
              name="isTermsAndPolicyAccepted"
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onClick={() =>
                setTouched({
                  ...touched,
                  isTermsAndPolicyAccepted: true,
                })
              }
            />

            <StyledLabel className="top-align  mt-none subheader">
              <FormattedMessage
                id="dashboard::service::privacy"
                defaultMessage="Privacy"
              />
              {REQUIRED_FIELD_SYMBOL}
            </StyledLabel>
            <RadioSelect
              items={items}
              defaultValue={values.isUnlisted ? "private" : "public"}
              onChange={(e) => {
                setFieldValue(
                  "isUnlisted",
                  e.target.value === "private" ? true : false
                )
              }}
            />
          </>
        )}
      </fieldset>
      <StyledButtonContainer>
        <div>
          {isUpdate && service?.status === REVIEW_STATUS.APPROVED ? (
            <StyledActionButton
              className="full-width"
              data-testid="btn-stop"
              color="transparent"
              onClick={() => setStopModalOpen(true)}
              type="button">
              <FormattedMessage
                id="dashboard::service::stopService"
                defaultMessage="Finish"
              />
            </StyledActionButton>
          ) : null}
        </div>
        {service?.status !== REVIEW_STATUS.ENDED ? (
          <StyledActionButton
            data-testid="btn-submit"
            className={service?.id ? "" : "full-width"}
            color="carrot"
            type="submit">
            {isServiceBeingCreated || isSubmitting ? (
              <Spinner condensed />
            ) : service?.id ? (
              <FormattedMessage
                id="dashboard::serviceForm::submitForReview"
                defaultMessage="Submit For Review"
              />
            ) : (
              <FormattedMessage
                id="dashboard::service::createService"
                defaultMessage="Create"
              />
            )}
          </StyledActionButton>
        ) : null}
      </StyledButtonContainer>
    </Form>
  )
}
