import styled from "@emotion/styled"

export const StyledMainContainer = styled.div`
  background: ${(p) => p.theme.colors.white};

  margin-top: 1.5rem;

  button {
    width: 100%;
    margin: 0 auto;
  }

  input {
    padding-left: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 1rem;
    padding-bottom: 1.25rem;

    button {
      width: 13.6875rem;
      margin: 0 auto;
      height: auto;
      min-height: 2.5rem;
      padding: 0 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    button {
      width: 17.5rem;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2rem;
    padding-bottom: 1.5rem;

    button {
      min-height: 3.375rem;
      width: 29.375rem;
      margin: 0 auto;
    }
  }

  .react-tabs {
    max-width: 22.5rem;
    margin: auto;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      max-width: unset;
      margin: unset;
    }

    ul {
      margin-bottom: 1.25rem;
      display: flex;
      justify-content: space-between;
      padding: 0 1.25rem;
      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    li {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.disabled};

      text-align: center;

      font-size: 1.125rem;
      line-height: 3.375rem;

      margin: 0;
      padding: 0;

      height: 3.375rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 0.75rem;
        line-height: 3.375rem;

        height: 3.375rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
        font-size: 1rem;
        line-height: 3.5rem;
        height: 3.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.25rem;
        line-height: 4rem;
        height: 4rem;
      }
    }
  }

  .react-tabs__tab--selected {
    color: ${(p) => p.theme.colors.pitchBlack} !important;
    border-bottom: 0.0625rem solid ${(p) => p.theme.colors.blue};
    border-radius: 0 0.25rem 0 0;
  }
`

export const StyledDonationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;

  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(3, 4.3125rem);
    padding: 0 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, 5.5625rem);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: repeat(3, 9.25rem);
    grid-column-gap: 1rem;
    padding: 0 1.5rem;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 1rem;
    line-height: 1.5rem;

    height: 3rem;
    background: ${(p) => p.theme.colors.whiteSmoke};
    border-radius: 0.25rem;

    margin-bottom: 1rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-bottom: 1.25rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 3.375rem;
      font-size: 1.25rem;
      line-height: 1.75rem;

      margin-bottom: 1.5rem;
    }

    &.selected {
      background: ${(p) => p.theme?.colors?.blue};
      color: ${(p) => p.theme?.colors?.white};
    }
  }
`

export const StyledDonationContainer2 = styled(StyledDonationContainer)`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
export const StyledDonationDescription = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;

  margin-bottom: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    line-height: 1.25rem;

    margin: 0 1.25rem 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 1.375rem;

    margin: 0 1.25rem 1.25rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`
export const StyledDonationAmount = styled.div`
  .currency-icon:before {
    content: ${(p) => (p.hasAmount ? '"€"' : '""')} !important;
  }

  margin-bottom: 1.875rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0 1.25rem 1.875rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 0 1.25rem 1.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.125rem;
  }
`
