import { useLazyQuery } from "@apollo/client"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Elements } from "@stripe/react-stripe-js"
import { useApolloApiClients } from "@tmu/apollo/client"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { OFFER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/offer"
import {
  AmountProvisioner,
  CharitySelector,
  LazyImage,
  Money,
  Spinner,
  TextInput,
} from "@tmu/components/common"
import { ServicePurchaseForm } from "@tmu/components/forms"
import SEO from "@tmu/components/seo"
import { HomePageContext } from "@tmu/context/homePageContext"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useAuth, useCurrency, useFooterType, useToast } from "@tmu/hooks"
import { useOrder } from "@tmu/src/hooks"
import { isBrowser, isTokenExpired } from "@tmu/utils/auth"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { get } from "@tmu/utils/storage"
import { getValueForLocale } from "@tmu/utils/string"
import { loadStripeInstance } from "@tmu/utils/stripe"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import queryString from "query-string"
import { useContext, useEffect, useState } from "react"
import { StyledProfileSectionTitle } from "../../dashboard/dashboardCommon/ProfileForm/index.styles"
import {
  StyledBackButton,
  StyledExplanation,
  StyledFormTitle,
  StyledGeneralText,
  StyledHorizontalContainer,
  StyledMobileGraySection,
  StyledPickAnother,
  StyledPrice,
  StyledRowGrid,
  StyledSection,
  StyledSectionContainer,
  StyledSectionItem,
  StyledSectionTitle,
  StyledSmallDetailedText,
  StyledSupportCheckoutForm,
  StyledTotalText,
  StyledWrapper,
} from "./index.styles"

const pacsLogo =
  process.env.CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"

const pacsLogoContent = (
  <img
    className="pacs-image"
    src={pacsLogo}
    alt={`pacs`}
    width={16}
    height={12}
  />
)

const ServiceCheckoutForm = ({ slug }) => {
  const searchStr = isBrowser ? window?.location?.search : ""
  const params = queryString.parse(searchStr, { arrayFormat: "comma" })

  const toast = useToast()

  const contextData = useContext(HomePageContext)
  const { user } = useAuth()
  const userFavoriteCharity = user?.userPreference?.campaign
  const userBalance = contextData?.globalPacBalance
  const { formatMessage, locale, defaultLocale } = useIntl()
  const { variant, set } = params

  const { currency } = useCurrency()
  const { isTablet, isWide } = getAllScreenTypes()

  const imgSize = {
    width: isWide ? 160 : 100,
    height: isWide ? 88 : 55,
  }
  const [internalOrderValues, setInternalOrderValues] = useState()
  const [customAmount, setCustomAmount] = useState()
  const [customAmountError, setCustomAmountError] = useState()
  const [isQuantityAvailable, setIsQuantityAvailable] = useState(true)
  const [quantity, setQuantity] = useState(1)
  const defaultErrorMessage = formatMessage({
    id: "forms::error::stripeClientSecret",
    defaultMessage: "There was a problem during the transaction",
  })
  const { storefrontClient } = useApolloApiClients()
  useFooterType({ footerType: FOOTER_TYPE.HIDE })
  const isAuthenticated = !isTokenExpired(get("token"))
  const [charitySelectorModalStatus, setCharitySelectorModalStatus] = useState()
  const [selectedCharity, setSelectedCharity] = useState()
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)

  const [offer, setOffer] = useState(null)

  const {
    createNewOrder,
    error: orderError,
    data: orderData,
    discardOrder,
    discardError,
    discardData,
    createNewOrderLoading,
  } = useOrder()

  const productVariation =
    orderData?.createOrder?.order?.cartItems?.edges?.[0]?.node?.productVariation

  useEffect(() => {
    setGlobalSpinnerStatus(createNewOrderLoading)
  }, [createNewOrderLoading])

  const createInitialOrder = async (props) => {
    const input = {
      productVariation: variant,
      currency,
      quantity: props.quantity || quantity,
      referral: params.referral,
    }

    if (set) {
      const finalCustomAmount = props?.value ?? customAmount
      if (
        !finalCustomAmount ||
        finalCustomAmount < 10 ||
        finalCustomAmount >= 10000
      )
        return
      input.customAmount = String(finalCustomAmount)
    }

    const { data } = await createNewOrder({
      variables: {
        input,
      },
    })
    const { order, errors } = { ...data?.createOrder }

    if (errors?.length || !order) {
      handleError()
    } else {
      setInternalOrderValues(order?.internalOrderValues)
    }
  }

  const handleDiscardOrder = (errorText) => {
    toast?.error(errorText || defaultErrorMessage)
    if (orderData?.createOrder?.order?.id)
      discardOrder({
        variables: { input: { order: orderData?.createOrder?.order?.id } },
      })
  }

  const handleError = (errorMessage) => {
    handleDiscardOrder(errorMessage)
    navigate(`/services/${slug}`)
  }

  const [getServiceDetail, { loading, error, data }] = useLazyQuery(
    OFFER_DETAIL_QUERY({ locale }),
    {
      variables: { slug, isPublic: true },
      client: storefrontClient,
    }
  )

  if (error) {
    handleError(error?.message)
  }

  useEffect(() => {
    if (!variant) navigate(`/services/${slug}`)
  }, [variant])

  const handlePurchaseComplete = async ({ id, isDWOR }) => {
    navigate(
      `/services/${slug}/checkout/success?id=${id}${
        isDWOR ? "&dwor=true" : ""
      }`,
      {
        state: {
          data: { orderData },
        },
      }
    )
  }

  useEffect(() => {
    getServiceDetail()
  }, [])

  useEffect(() => {
    if (data) {
      const offerData = {
        ...data.offer,
      }

      setOffer(offerData)
    }
  }, [data])

  if (loading) return <Spinner />

  const stripeInstance = loadStripeInstance(
    data?.offer?.stripePublicKey,
    locale
  )

  const selectedVariant = offer?.productVariation?.edges.filter(
    (item) => item.node.id === variant
  )?.[0]?.node

  const storeFavoriteCharity =
    offer?.favoriteCharity || offer?.store?.favoriteCharity

  const allowUsersToSelectADifferentCampaign =
    offer?.allowUsersToSelectADifferentCampaign ||
    (offer?.allowUsersToSelectADifferentCampaign === null &&
      offer?.store?.allowUsersToSelectADifferentCampaign)

  const donationAmount = Number(internalOrderValues?.donationAmount || 0) || 0
  const discountAmount = Number(internalOrderValues?.discountAmount || 0) || 0

  const futurePurchaseAmount =
    (Number(
      allowUsersToSelectADifferentCampaign
        ? selectedCharity?.pacMultiplier ||
            userFavoriteCharity?.pacMultiplier ||
            storeFavoriteCharity?.pacMultiplier
        : storeFavoriteCharity?.pacMultiplier
    ) -
      1) *
    donationAmount

  const order = orderData?.createOrder?.order

  const yourDonationText = (
    <FormattedMessage
      id="service::checkoutForm::yourDiscountAmount"
      defaultMessage="Your donation is <blue>{donation}</blue> to reach the total discount available of {totalAmount}"
      values={{
        totalAmount: (
          <Money
            value={internalOrderValues?.discountAmount || 0}
            currency={false}
          />
        ),
        donation: <Money value={internalOrderValues?.donationAmount || 0} />,
        amount: (...chunks) => (
          <>
            {chunks.map((chunk, i) => (
              <span className="inline" key={i}>
                {pacsLogoContent}
                {chunk}
              </span>
            ))}
          </>
        ),
        blue: (...chunks) => (
          <>
            {chunks.map((chunk, i) => (
              <span className="blue" key={i}>
                {chunk}
              </span>
            ))}
          </>
        ),
      }}
    />
  )

  const rewardText = (
    <FormattedMessage
      id="service::checkoutForm::yourRewardText"
      defaultMessage="You will be rewarded with <amount>{totalAmount}</amount> for future purchases"
      values={{
        totalAmount: (
          <Money
            value={internalOrderValues?.donationAmount || 0}
            currency={false}
          />
        ),
        amount: (...chunks) => (
          <>
            {chunks.map((chunk, i) => (
              <span className="inline grey" key={i}>
                {pacsLogoContent}
                {chunk}
              </span>
            ))}
          </>
        ),
      }}
      tagName="div"
    />
  )

  const totalAmount = !isAuthenticated
    ? internalOrderValues?.subtotal || 0
    : internalOrderValues?.purchaseTotal || 0
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(
          {
            id: "service::checkoutForm::title",
            defaultMessage: "{name}",
          },
          { name: offer?.name }
        )}
        image={offer?.image?.url}
      />
      <StyledWrapper>
        <StyledBackButton onClick={() => navigate(`/services/${slug}`)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </StyledBackButton>
        <StyledSupportCheckoutForm>
          <StyledFormTitle>
            <FormattedMessage
              id="service::checkoutForm::checkout"
              defaultMessage="Checkout"
            />
          </StyledFormTitle>
          <div className={!isTablet ? "gray-background" : ""}>
            {true ? (
              <>
                <StyledSectionTitle>
                  <FormattedMessage
                    id="service::checkoutForm::purchase"
                    defaultMessage="Purchase"
                  />
                </StyledSectionTitle>
                <StyledSectionContainer>
                  <StyledSection>
                    <StyledSectionItem className="side-by-side">
                      {isTablet && (
                        <div>
                          <img src={offer?.image} alt={`image`} {...imgSize} />
                        </div>
                      )}
                      <StyledSmallDetailedText
                        className={!isTablet ? "mobile" : ""}>
                        {offer?.name ?? selectedVariant?.name}
                      </StyledSmallDetailedText>
                    </StyledSectionItem>
                    <StyledSectionItem className="right">
                      {set ? (
                        <>
                          <StyledProfileSectionTitle>
                            <FormattedMessage
                              id="service::checkoutForm::setYourPrice"
                              defaultMessage="Set your price"
                            />
                          </StyledProfileSectionTitle>
                          <Spacer top={0.5} />
                          <TextInput
                            newDesign
                            className="currency-icon custom-amount"
                            id="paymentAmount"
                            name="paymentAmount"
                            value={customAmount}
                            placeholder=""
                            error={customAmountError} // Display error message
                            touched={true}
                            onValueChange={({ value }) => {
                              const amount = value ? Number(value) : ""
                              setCustomAmount(amount)

                              // Validate if the amount is less than 10
                              if (!amount || amount < 10) {
                                setCustomAmountError(
                                  formatMessage(
                                    {
                                      id: "forms::donationMinAmountError",
                                      defaultMessage:
                                        "The amount must be at least {value}.",
                                    },
                                    {
                                      value: 10,
                                    }
                                  )
                                )
                              } else if (amount && amount > 9999) {
                                setCustomAmountError(
                                  formatMessage({
                                    id: "forms::referralNumberMaxError",
                                    defaultMessage:
                                      "Amount must be less than or equal to 9999.",
                                  })
                                )
                              } else {
                                setCustomAmountError("") // Clear error if valid
                              }

                              createInitialOrder({ quantity, value: amount })
                            }}
                            type="currency"
                            maxLength={8}
                          />
                        </>
                      ) : (
                        <>
                          <StyledPrice>
                            <Money
                              value={
                                (internalOrderValues?.subTotal ||
                                  selectedVariant?.price ||
                                  0) * quantity
                              }
                            />
                          </StyledPrice>
                          <StyledProfileSectionTitle>
                            <FormattedMessage
                              id="service::checkoutForm::price"
                              defaultMessage="Price"
                            />
                          </StyledProfileSectionTitle>
                        </>
                      )}
                    </StyledSectionItem>
                  </StyledSection>
                  <StyledSection className="right">
                    <StyledSectionItem>
                      <StyledProfileSectionTitle>
                        <FormattedMessage
                          id="campaign::donationForm::quantity"
                          defaultMessage="Quantity"
                        />
                        <Spacer top={0.5} />
                        <AmountProvisioner
                          amount={
                            internalOrderValues?.subTotal ??
                            selectedVariant?.price
                          }
                          tier={selectedVariant?.id}
                          onError={() => {
                            setIsQuantityAvailable(false)
                          }}
                          onChange={(val) => {
                            setQuantity(val)
                            createInitialOrder({ quantity: val })
                            setIsQuantityAvailable(true)
                          }}
                          disabled={createNewOrderLoading}
                          isService={true}
                          expectedQuantityParam={productVariation?.stockLeft}
                          isUnlimited={productVariation?.isUnlimitedTickets}
                        />
                      </StyledProfileSectionTitle>
                    </StyledSectionItem>
                  </StyledSection>
                  <StyledSection className="right">
                    <StyledSectionItem>
                      <StyledSmallDetailedText>
                        <Money
                          value={
                            selectedVariant?.pacDiscount ??
                            (offer?.maxPacDiscount || 0)
                          }
                          style="percent"
                        />
                      </StyledSmallDetailedText>
                      <StyledProfileSectionTitle>
                        <FormattedMessage
                          id="service::checkoutForm::pacDiscount2"
                          defaultMessage="Discount"
                        />
                      </StyledProfileSectionTitle>
                    </StyledSectionItem>
                    <StyledSectionItem>
                      <StyledSmallDetailedText>
                        <Money
                          value={internalOrderValues?.discountAmount || 0}
                        />
                      </StyledSmallDetailedText>
                      <StyledProfileSectionTitle>
                        <FormattedMessage
                          id="service::checkoutForm::totalDiscountAvailable"
                          defaultMessage="Total Discount Available"
                        />
                      </StyledProfileSectionTitle>
                    </StyledSectionItem>
                  </StyledSection>
                  <StyledSection className="right">
                    <StyledSectionItem>
                      <StyledPrice className="carrot">
                        <Money value={internalOrderValues?.pacAmount || 0} />
                      </StyledPrice>
                      <StyledProfileSectionTitle>
                        <FormattedMessage
                          id="service::checkoutForm::discountApplied"
                          defaultMessage="PAC Discount Applied"
                        />
                      </StyledProfileSectionTitle>
                    </StyledSectionItem>
                  </StyledSection>
                </StyledSectionContainer>
                <StyledMobileGraySection>
                  <Spacer top={2} />
                  {donationAmount ? (
                    <StyledGeneralText>{yourDonationText}</StyledGeneralText>
                  ) : null}
                  <Spacer top={2} />
                  {donationAmount ? (
                    <>
                      <StyledSectionTitle>
                        <FormattedMessage
                          id="service::checkoutForm::donation"
                          defaultMessage="Donation"
                        />
                      </StyledSectionTitle>
                      <StyledSectionContainer>
                        <StyledSection>
                          <StyledSectionItem className="side-by-side">
                            {isTablet && (
                              <div>
                                <LazyImage
                                  src={getValueForLocale(
                                    allowUsersToSelectADifferentCampaign
                                      ? selectedCharity?.partner ||
                                          userFavoriteCharity?.partner ||
                                          storeFavoriteCharity?.partner
                                      : storeFavoriteCharity?.partner,
                                    "logo",
                                    locale,
                                    defaultLocale
                                  )}
                                  altName={`image`}
                                  {...imgSize}
                                />
                              </div>
                            )}
                            <StyledRowGrid>
                              <StyledHorizontalContainer
                                className={!isTablet ? "column" : ""}>
                                <StyledSmallDetailedText
                                  className={!isTablet ? "mobile" : ""}>
                                  {getValueForLocale(
                                    allowUsersToSelectADifferentCampaign
                                      ? selectedCharity ||
                                          userFavoriteCharity ||
                                          storeFavoriteCharity
                                      : storeFavoriteCharity,
                                    "name",
                                    locale,
                                    defaultLocale
                                  )}
                                </StyledSmallDetailedText>
                                {allowUsersToSelectADifferentCampaign && (
                                  <StyledPickAnother
                                    onClick={() => {
                                      setCharitySelectorModalStatus(true)
                                    }}>
                                    <FormattedMessage
                                      id="service::checkoutForm::pickAnother"
                                      defaultMessage="Pick Another"
                                    />
                                  </StyledPickAnother>
                                )}
                              </StyledHorizontalContainer>
                              {isTablet && (
                                <StyledExplanation>
                                  {rewardText}
                                </StyledExplanation>
                              )}
                            </StyledRowGrid>
                          </StyledSectionItem>

                          <StyledSectionItem className="right">
                            <StyledSmallDetailedText>
                              <Money value={donationAmount} />
                            </StyledSmallDetailedText>
                            <StyledProfileSectionTitle>
                              <FormattedMessage
                                id="service::checkoutForm::donationAmount"
                                defaultMessage="Donation Amount"
                              />
                            </StyledProfileSectionTitle>
                          </StyledSectionItem>
                        </StyledSection>
                        {!isTablet && (
                          <StyledGeneralText>{rewardText}</StyledGeneralText>
                        )}
                      </StyledSectionContainer>
                      <StyledSectionContainer>
                        <StyledSection className="right">
                          <StyledSectionItem className="right">
                            <StyledSmallDetailedText>
                              <Money
                                value={internalOrderValues?.discountPrice}
                              />
                            </StyledSmallDetailedText>
                            <StyledProfileSectionTitle>
                              <FormattedMessage
                                id="service::checkoutForm::netPrice"
                                defaultMessage="net price service"
                              />
                            </StyledProfileSectionTitle>
                          </StyledSectionItem>
                        </StyledSection>
                      </StyledSectionContainer>
                    </>
                  ) : null}
                </StyledMobileGraySection>
                <StyledHorizontalContainer
                  className={`${isTablet ? "right" : ""} ${
                    donationAmount ? "more-margin" : "less-margin"
                  }`}>
                  <StyledTotalText>
                    <FormattedMessage
                      id="service::checkoutForm::total"
                      defaultMessage="Total"
                    />
                    :
                  </StyledTotalText>
                  <StyledPrice>
                    <Money value={totalAmount || 0} />
                  </StyledPrice>
                </StyledHorizontalContainer>
              </>
            ) : (
              <Spinner condensed />
            )}
          </div>
          {offer?.id && stripeInstance ? (
            <>
              <Spacer top={2} />
              <Elements stripe={stripeInstance} options={{ locale }}>
                <ServicePurchaseForm
                  customAmountError={customAmountError}
                  onComplete={handlePurchaseComplete}
                  handleDiscardOrder={handleDiscardOrder}
                  quantity={quantity}
                  isNeedTicketBuyerInfo={offer?.isNeedTicketBuyerInfo}
                  charityId={
                    allowUsersToSelectADifferentCampaign
                      ? selectedCharity?.id ||
                        userFavoriteCharity?.id ||
                        storeFavoriteCharity?.id
                      : storeFavoriteCharity?.id
                  }
                  isInvoiceMandatory={offer?.isInvoiceMandatory}
                  variant={variant}
                  donationAmount={donationAmount}
                  discountAmount={discountAmount}
                  displayCardSection={
                    internalOrderValues
                      ? Number(internalOrderValues?.purchaseTotal || 0) > 0
                      : true
                  }
                  order={order}
                  createInitialOrder={createInitialOrder}
                  slug={slug}
                  internalOrderValues={internalOrderValues}
                  isQuantityAvailable={isQuantityAvailable}
                />
              </Elements>
            </>
          ) : (
            <FormattedMessage
              id="service::checkoutForm::notAvailable"
              defaultMessage="Checkout is not available at the moment. Please try later."
            />
          )}
        </StyledSupportCheckoutForm>
      </StyledWrapper>
      {charitySelectorModalStatus && (
        <CharitySelector
          isOpen={charitySelectorModalStatus}
          onSelect={(charity) => {
            setSelectedCharity(charity)
          }}
          onClose={() => {
            setCharitySelectorModalStatus(false)
          }}
          filters={{ campaignCheckbox: { supporterCampaign: true } }}
        />
      )}
    </>
  )
}

ServiceCheckoutForm.propTypes = {
  slug: PropTypes.string.isRequired,
}

ServiceCheckoutForm.defaultProps = {
  slug: "",
}

export default ServiceCheckoutForm
