import React, { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { faLockAlt } from "@fortawesome/pro-light-svg-icons/faLockAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import { PASSWORD_RESET } from "@tmu/apollo/storefront/mutations/user"
import {
  Button,
  TextInput,
  Checkbox,
  TermsAndConditions,
} from "@tmu/components/common"
import FooterLogoText from "@tmu/components/common/Footer/FooterLogoText"
import { StyledForm } from "@tmu/global/page-addons/dashboard.styles"
import { useToast } from "@tmu/hooks"
import {
  StyledContinueButtonContainer,
  StyledDescription,
  StyledFormContainer,
  StyledIconContainer,
  StyledInputContainer,
  StyledLogoContainer,
  StyledPageContainer2,
  StyledTitle,
} from "@tmu/global/page-addons/reset-password.styles"
import { Field, Formik } from "formik"
import {
  FormattedMessage,
  injectIntl,
  navigate,
  useIntl,
} from "gatsby-plugin-intl"
import * as Yup from "yup"
import {
  firstName,
  displayName,
  email,
  password,
  agreeTerms,
  contactCountry,
  accountTypeRequired,
  communicationLanguage,
  campanyName,
  fiscalCode,
  vat,
  phone,
} from "@tmu/utils/validation"
import theme from "@tmu/global/theme"
import {
  REQUIRED_FIELD_SYMBOL,
  SUPPORTED_LANGUAGES,
  userType,
} from "@tmu/apollo/constants"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { getEmailFromToken } from "@tmu/utils/token"

const SetPassword = () => {
  const { storefrontClient } = useApolloApiClients()
  const { formatMessage } = useIntl()
  const { error, success } = useToast()
  const [isChecked, setIsChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  let token = ""

  if (typeof window !== "undefined") {
    token = new URL(window?.location?.href).searchParams.get("token")
  }

  const initialValues = {
    password: "",
    confirmPassword: "",
    agreeTerms: false,
    email: getEmailFromToken(token),
  }
  const validationSchema = Yup.object().shape({
    password: password({ formatMessage }),
    confirmPassword: Yup.string()
      .required(
        formatMessage({
          id: "forms::passwordRequiredError",
          defaultMessage: "A password is required.",
        })
      )
      .test(
        "password-match",
        formatMessage({
          id: "signup::password::notMatch",
          defaultMessage: "Both passwords need to be the same",
        }),
        function (value) {
          return value === this.resolve(Yup.ref("password"))
        }
      ),
    agreeTerms: agreeTerms({ formatMessage }),
  })

  const [callPasswordReset, { data, errors }] = useMutation(PASSWORD_RESET, {
    client: storefrontClient,
  })

  const setPassword = (password, confirmPassword, terms) => {
    callPasswordReset({
      variables: {
        input: {
          newPassword1: password,
          newPassword2: confirmPassword,
          token,
          isTermsAndPolicyAccepted: terms,
        },
        isPublic: true,
      },
    })
  }

  useEffect(() => {
    if (data?.passwordReset?.success) {
      const passwordSetSuccessfullyText = formatMessage({
        id: "password::set::passwordSetSuccessfully",
        defaultMessage: "Your password is set successfully",
      })

      success(passwordSetSuccessfullyText)
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }

    if (data?.passwordReset?.errors) {
      let errorMessage = ""

      Object.values(data?.passwordReset?.errors).forEach((t) =>
        t.forEach((s) => (errorMessage += "\n" + s.message))
      )

      error(errorMessage)
    }

    if (errors) {
      error(errors.message)
    }
  }, [data, errors])

  const continueText = formatMessage({
    id: "set::password::signUp",
    defaultMessage: "Sign up",
  })

  const passwordsMatchErrorText = formatMessage({
    id: "set::password::passwordMatch",
    defaultMessage: "Passwords should match",
  })

  const handleSubmit = (values, form) => {
    setPassword(values.password, values.confirmPassword)
  }

  const onClick = (values) => {
    setPassword(values.password, values.confirmPassword, values.terms)
  }

  return (
    <>
      <StyledPageContainer2>
        {/* <StyledLogoContainer>
          <FooterLogoText
            width="180"
            height="90"
            flat={false}
            fill={theme?.colors?.blue}
          />
        </StyledLogoContainer> */}
        <StyledTitle>
          <FormattedMessage
            id="set::password::enterNewPassword"
            defaultMessage="Enter new password"
            tagName="h1"></FormattedMessage>
        </StyledTitle>

        <StyledDescription>
          {formatMessage({
            id: "set::password::manageRecurringText",
            defaultMessage:
              "You can manage your recurring donation from your profile",
          })}
        </StyledDescription>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            handleBlur,
            handleReset,
            touched,
            errors,
            setFieldTouched,
            setTouched,
          }) => {
            return (
              <>
                <StyledFormContainer>
                  <StyledForm onSubmit={handleSubmit}>
                    <TextInput
                      newDesign
                      data-testid="input-email"
                      id="email"
                      name="email"
                      type="email"
                      disabled
                      value={values?.email}
                      label={`${formatMessage({
                        id: "signUp::email",
                        defaultMessage: "Email",
                      })}${REQUIRED_FIELD_SYMBOL}`}
                    />
                    <Spacer top={1.5} />
                    <TextInput
                      newDesign
                      data-testid="input-password"
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                      label={`${formatMessage({
                        id: "signUp::password",
                        defaultMessage: "Password",
                      })}${REQUIRED_FIELD_SYMBOL}`}
                      placeholder={formatMessage({
                        id: "signUp::password",
                        defaultMessage: "Password",
                      })}
                      error={errors.password}
                      touched={touched.password}
                      inputIcon={
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          className="input-icon"
                          color={theme.colors.blue}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                      iconRight={0.9375}
                    />
                    <Spacer top={1.5} />
                    <TextInput
                      newDesign
                      data-testid="input-confirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.confirmPassword}
                      label={`${formatMessage({
                        id: "signUp::confirmPassword",
                        defaultMessage: "Confirm Password",
                      })}${REQUIRED_FIELD_SYMBOL}`}
                      placeholder={formatMessage({
                        id: "signUp::confirmPassword",
                        defaultMessage: "Confirm Password",
                      })}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      error={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      inputIcon={
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                          className="input-icon"
                          color={theme.colors.blue}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      }
                      iconRight={0.9375}
                    />
                    <Spacer top={1.5} />

                    <Checkbox
                      className="check"
                      label={formatMessage({
                        id: "merchant::selfRegistrationForm::signUpTMU",
                        defaultMessage:
                          "Sign me up for TrustMeUp exclusive offers and news",
                      })}
                      isChecked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <br />
                    <TermsAndConditions
                      className="check"
                      name="agreeTerms"
                      errors={errors}
                      touched={touched}
                      checked={values.agreeTerms}
                      onChange={handleChange}
                      onClick={() =>
                        setTouched({ ...touched, agreeTerms: true })
                      }
                    />
                  </StyledForm>

                  <StyledContinueButtonContainer>
                    <Button
                      className="submit-button"
                      label="Continue"
                      type="submit"
                      color="red"
                      onClick={() => {
                        onClick(values)
                        handleReset()
                      }}
                      disabled={
                        errors?.password ||
                        !values?.password?.length ||
                        errors?.confirmPassword ||
                        !values?.confirmPassword?.length ||
                        values?.password !== values?.confirmPassword
                      }>
                      {continueText}
                    </Button>
                  </StyledContinueButtonContainer>
                </StyledFormContainer>
              </>
            )
          }}
        </Formik>
      </StyledPageContainer2>
    </>
  )
}

export default injectIntl(SetPassword)
