import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { css } from "@emotion/react"

export const StyledTerminalWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  margin: 0rem auto;
  padding: 0;
  background: ${(p) => p.theme.colors.whiteSmoke};
  max-width: 481px;

  .note {
    textarea {
      height: 8.5rem;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    max-width: 482px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 1rem 1rem 0;
    display: grid;
    grid-template-columns: 455px 1fr;
    grid-gap: 1rem;
    max-width: 850px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    display: grid;
    padding: 1rem 1rem 0;
    grid-template-columns: auto 23.6rem;
    grid-gap: 0.75rem;
    max-width: 932px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    display: grid;
    padding: 1.5rem 1.5rem 0;
    grid-template-columns: auto 24rem;
    grid-gap: 0.5rem;
    max-width: 1200px;
  }
  @media only screen and (min-width: 1920px) {
    display: grid;
    padding: 4rem 4rem 0;
    grid-template-columns: auto 430px;
    grid-gap: 2.2rem;
    max-width: 1860px;
  }
`

export const StyledCampaignsBody = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  border-radius: 4px;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 8px;
  }
`

export const StyledHeading = styled.div`
  padding: 0.75rem 1rem;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 0.75rem;
  svg {
    max-width: 1.625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0;
  }
`
export const StyledHeadingTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  .terminal__reader__connected {
    color: ${(p) => p.theme.colors.black};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.12px;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    display: inline-block;

    span {
      text-transform: lowercase;
      &.connected {
        color: rgba(20, 201, 135, 1);
      }
      &.error {
        color: ${(p) => p.theme.colors.red};
      }
    }
  }
  .terminal__reader__pair {
    color: ${(p) => p.theme.colors.blue};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.12px;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    width: 100%;
    text-align: right;
    cursor: pointer;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: none;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledListingFilters = styled.div`
  display: none;
  hr {
    margin: 0.8125rem 0;
  }
  > p {
    //uppercase header
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    color: ${(p) => p.theme.colors.disabled};
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  }

  > div {
    //checkbox filters
    border: 0.0625rem solid ${(p) => p.theme.colors.darkGrey};
    border-radius: 0.25rem;
    padding: 1rem 0.75rem 0.875rem;
    margin-bottom: 1.5rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${(p) => p.theme.colors.black};

    .filter-checkbox {
      padding: 0;
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 2.25rem;
      > span {
        top: 0;
        display: block;
        position: relative;
        float: right;
        min-width: 1.25rem;
      }
    }
    .last {
      min-height: 1.25rem;
      padding-bottom: 0.5rem;
    }
  }

  .category-filters button {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    padding-left: 0;
    margin: 0;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-size: 0.75rem;
    line-height: 1.125rem;
    height: 2.5rem;
    white-space: pre-wrap;
    text-align: left;
    color: ${(p) => p.theme.colors.disabled};
    i {
      margin-left: 0;
      margin-right: 0.6rem;
      width: 1rem;
    }
    svg {
      margin-left: 0.25rem;
      padding-top: 0.125rem;
      font-size: 1.2rem;
      height: 1.2rem;
    }
  }
  .charities span {
    margin-left: 0.125rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    > p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
    > div {
      //checkbox filters
      font-size: 1.5rem;
      line-height: 2.25rem;
      padding: 1.5rem;

      .filter-checkbox {
        min-height: 2.25rem;
        margin: 1.5rem 0;
        > span {
          min-width: 2rem;
        }
      }
      hr {
        margin: 1.5rem 0;
      }

      .checkmark {
        height: 2rem;
        width: 2rem;
        :after {
          left: 0.55rem;
          top: 0.04rem;
          width: 0.6rem;
          height: 1.125rem;
        }
      }
    }
    .category-filters button {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-size: 1.5rem;
      line-height: 2.25rem;
      height: 4.25rem;
      i {
        margin-left: 0;
        margin-right: 1rem;
        width: 2rem;
      }
      svg {
        padding-top: 0.375rem;
        font-size: 2rem;
        height: 2rem;
      }
    }
  }
`

export const StyledContent = styled.div`
  height: calc(100vh - 132px);
  overflow-y: auto;
  box-sizing: content-box;
  border-radius: 4px;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem 0.75rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 8px;

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
`

export const StyledCampaignTitle = styled.div``
export const StyledCampaignNumber = styled.div``

export const StyledCampaignList = styled.div``

export const CampaignListAction = styled.div`
  width: 100%;
  margin: 2rem 0;
  > div {
    padding: 0;
  }
`

export const NoResults = styled.div`
  display: grid;
  grid-gap: 1rem;

  padding: 0 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: unset;
  }

  h6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`

export const CampaignListButton = styled(Button)``

export const ShowAllButton = styled(Button)`
  margin-bottom: 1rem;
  ${(p) =>
    p.active &&
    css`
      color: ${p.theme.colors.pitchBlack} !important;
    `}
`

export const StyledCategoryItem = styled.div``

export const StyledLink = styled.a``

export const StyledEmptyPlaceholder = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    opacity: 0.25;
  }
`

export const StyledOnlyPacsToggle = styled.div`
  padding-top: 0 !important;
  margin-top: -0.5rem !important;
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  background: none !important;
  &:hover {
    background: none !important;
  }
  &.first,
  &.second {
    padding-bottom: 0;
  }
`

export const StyledFilterBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  .category-dropdown {
    margin-top: 0.75rem;
    border-radius: 4px;
  }

  .common-input {
    border: 1px solid ${(p) => p.theme.colors.concreteGrey};
    border-radius: 4px;
    border: 1px solid #7a7776;
    height: 48px;
    padding: 0;
    padding-left: 0.75rem !important;
    font-size: 1rem;
  }
  .search-wrapper {
    height: auto;
  }
  .search-icon {
    top: 0.8rem;
  }

  .react-select__control {
    height: 48px;
    color: ${(p) => p.theme.colors.black};
    overflow: hidden;
  }

  .react-select__indicators {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  @media only screen and (min-width: 768px) {
    .common-input {
      border: none;
    }
    .category-dropdown {
      border: none;
      .react-select__control {
        border: none;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 412px auto;
    grid-gap: 0.75rem;

    .category-dropdown {
      margin-top: 0;
    }
    .react-select__control {
      height: 48px;
      width: 320px;
    }
  }
  @media only screen and (min-width: 1920px) {
    grid-template-columns: 710px auto;
    grid-gap: 2.2rem;

    .common-input {
      height: 54px;
      font-size: 1rem;
    }
    .react-select__control {
      height: 54px;
      width: 540px;
      font-size: 1rem;
    }
    .search-icon {
      top: 0.5rem;
    }
  }
`
export const StyledRightGroup = styled.div`
  grid-area: right;
  justify-content: center;
  margin: 0 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 13rem"
        : p.isOfferList || !p.filters?.listType
        ? " 13rem 1fr"
        : " 1fr 12.5rem"};
    gap: 1rem;
    justify-content: space-between;
    margin: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 13rem"
        : p.isOfferList || !p.filters?.listType
        ? " 13rem 1fr"
        : " 1fr 6.25rem 12.5rem"};
    gap: 1.5rem;
    justify-content: space-between;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: ${(p) =>
      p.withCampaignTypes === true
        ? "1fr"
        : p.filterType === "charity"
        ? "1fr 20rem"
        : p.isOfferList || !p.filters?.listType
        ? " 20rem 1fr"
        : " 1fr 9.25rem 18.5rem"};
    gap: 3rem;
  }
`
export const StyledLeftGroup = styled.div`
  grid-area: left;
  align-self: center;
  margin: ${(p) => (p.isOfferList ? "0 1rem 1rem" : "1rem 1rem 0")};
  display: flex;
  justify-content: space-between;

  ${(p) =>
    p.isOfferList &&
    css`
      flex-direction: column;
    `}

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
    flex-direction: rıw;
    display: block;
    .mobile-filter {
      display: none;
    }
  }
`

export const StyledSelectorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
export const StyledCategoryFilterWrapper = styled.div`
  width: 100%;
`
export const StoreFilterWrapper = styled.div`
  margin-top: 1rem;
`

export const StyledCaption = styled.div`
  text-transform: uppercase;
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.03em;
  color: ${(p) => p.theme.colors.disabled};
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.25rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    line-height: 2rem;
    font-size: 1.25rem;
  }
`
export const StyledCampaignTypes = styled.div`
  display: flex;
  gap: 2rem;
`

export const StyledHorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  .filter-checkbox {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.5rem;
      margin: 0 0.6875rem 0 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin: 0 2.625rem 0 0;
    }

    > span {
      top: 0.1rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        top: 0.6rem;
      }
    }
  }
`

export const StyledWrapper = styled.div`
  display: block;
`
export const StyledBlocker = styled.div`
  width: 100vw;
  height: 4rem;
  position: absolute;
  z-index: 999;
`

export const StyledDonationSection = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  position: relative;
  height: 100%; //calc(100vh - 1.3rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  h3 {
    color: ${(p) => p.theme.colors.black};
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }

  h5 {
    margin-top: 8px;
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};
    font-size: 1rem;
    line-height: 24px;
  }
  .wrapper {
    position: absolute;
    bottom: 3.2rem;
    font-size: 1rem;
    .input-wrapper {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  .pair {
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    text-decoration: underline;
  }
  .terminal__reader__or {
    text-align: center;
    margin: 0.9rem auto 1rem;
  }
  button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 2rem;
    width: 250px;
    color: ${(p) =>
      p.isPairingOpen ? p.theme.colors.white : p.theme.colors.blue};
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
  @media only screen and (min-width: 768px) and (max-width: 800px) {
    h3 {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 8px;
  }
`

export const StyledCampaignDetail = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  height: ${(props) => (props.isOpen ? "100%" : "0")};
  min-height: calc(100vh - 132px);
  overflow-y: auto;

  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: height 0.5s ease, opacity 0.5s ease;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  .slider,
  .slick-slide,
  .slider img {
    max-width: ${(p) => (p.sliderWidth ? p.sliderWidth : "100%")};
    max-height: ${(p) => (p.sliderHeight ? p.sliderHeight : "450px")};
  }

  .close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    z-index: 2;

    svg {
      path {
        filter: drop-shadow(0px 8px 24px #7a777680);
      }
      width: 48px;
      height: 48px;
      color: ${(p) => p.theme.colors.white};
    }
  }

  .content {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;

    .logo {
      margin-right: 0.75rem;
      img {
        max-width: 100%;
        height: auto;
        max-height: 250px;
      }
    }
    .title {
      margin-bottom: 0.75rem;
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 34px;
      text-align: left;
      color: ${(p) => p.theme.colors.black};
    }
    .description {
      color: ${(p) => p.theme.colors.black};
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .footer {
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 2rem);
    margin: 0 1rem;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    border-top: 1px solid ${(p) => p.theme.colors.gray};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.625rem;
    color: ${(p) => p.theme.colors.black};
    border-radius: 4px;

    .payment-methods {
      display: flex;
      gap: 0.5rem;

      img {
        height: 20px;
      }
      svg {
        height: 16px;
      }
    }

    .service-provider {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-align: right;

      svg {
        color: ${(p) => p.theme.colors.blue};
        height: 16px;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;
    font-size: 0.75rem;
    .footer {
      .payment-methods {
        svg {
          height: 20px;
        }
      }
      .service-provider {
        font-size: 0.875rem;
        svg {
          height: 20px;
        }
      }
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .footer {
      bottom: 4rem;
      height: 54px;
      .payment-methods {
        svg {
          width: 60px;
          height: 30px;
        }
      }
      .service-provider {
        font-size: 1rem;
        svg {
          height: 30px;
        }
      }
    }
    .header-image {
      img {
        max-height: 764px;
      }
    }
  }
`

export const StyledDonationSectionPair = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  position: relative;
  height: calc(100vh - 1.3rem);
  padding: 1.5rem 1rem 0 1rem;
  border-radius: 4px;

  .top {
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0;
    color: ${(p) => p.theme.colors.black};
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
  .input-wrapper {
    margin-bottom: 1.5rem;
  }
  button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 2rem;
    width: 250px;
    color: ${(p) =>
      p.isPairingOpen ? p.theme.colors.white : p.theme.colors.blue};
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .simulate {
    position: absolute;
    bottom: 0;
    left: 16px;
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;
    font-size: 0.625rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 8px;
  }
`

export const StyledDonationSectionTerminalPaired = styled.div`
  background: ${(p) => p.theme.colors.whiteSmoke};
  position: relative;
  height: calc(100vh - 1.3rem);
  border-radius: 4px;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    border-radius: 8px;
  }
`
export const StyledDonationSectionTerminalPairedTop = styled.div`
  background: ${(p) => p.theme.colors.white};
  position: relative;
  height: 80px;
  margin-bottom: 0.75rem;
  border-radius: 4px;
  padding: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 129px;
  gap: 0.5rem;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 114px;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1rem;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
  }
  span {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.25rem;
    letter-spacing: 0.03em;
    text-align: left;
    &.connected {
      color: rgba(20, 201, 135, 1);
    }
    &.error {
      color: ${(p) => p.theme.colors.red};
    }
  }
  button {
    padding: 0;
    margin: 0;
    color: ${(p) => p.theme.colors.blue};
    text-align: center;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
  @media only screen and (min-width: 1920px) {
    p {
      max-width: 177px;
    }
  }
`
export const StyledDonationSectionTerminalPairedOptions = styled.div`
  background: ${(p) => p.theme.colors.white};
  position: relative;
  height: calc(100vh - 113px);
  border-radius: 4px;
  padding: 1rem;
  overflow-y: auto;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
  }
`

export const StyledDonationOptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  button {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    svg {
      height: 1rem;
    }
  }

  .simulate {
    position: absolute;
    bottom: 0;
    right: 16px;
    color: ${(p) => p.theme.colors.blue};
    cursor: pointer;
    font-size: 0.625rem;
  }
  .cancel {
    position: absolute;
    bottom: 1rem;
    left: 16px;
    color: ${(p) => p.theme.colors.red};
    cursor: pointer;
    font-size: 1rem;
  }
`

export const StyledDonationOptions = styled.div`
  margin-top: 1rem;
  word-break: break-word;

  .donation-options h2 {
    font-size: 1.125rem;
    color: #6c757d;
    margin-bottom: 15px;
  }

  .option {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    gap: 1rem;
    background-color: ${(p) => p.theme.colors.whiteSmoke};
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  }

  .option input[type="radio"] {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid ${(p) => p.theme.colors.gray};
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 0 0 4px white inset;
    transition: border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
  }

  .option input[type="radio"]:checked {
    border-color: ${(p) => p.theme.colors.blue};
    background-color: ${(p) => p.theme.colors.blue};
    box-shadow: 0 0 0 4px white inset;
  }
  .option.selected {
    background-color: ${(p) => p.theme.colors.white};
    border: 1px solid ${(p) => p.theme.colors.blue};
  }
  .option:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .option-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .option-content .price {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
  }

  .option-content .description {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    .input-feedback-absolute {
      bottom: -1.05rem;
      font-size: 0.6rem;
    }
  }

  .option.input {
    input {
      margin-bottom: 0;
    }
  }
`

export const StyledDonationSuccessSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h3,
  h3 span {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
  }

  .recurring-donation-text {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-family: Open Sans;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0%;
    text-align: center;
  }
`

export const StyledSuccessPageImage = styled.div`
  p {
    color: ${(p) => p.theme.colors.black};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 1rem;
  }
  .media-box {
    height: auto;
  }
  img {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 1.5rem;
    width: 100%;
  }
`
